import React from "react";
import _ from "lodash";
import * as d3 from "d3-ease";
import {
  Marker,
  FlyToInterpolator,
  NavigationControl,
  MapEvent,
} from "react-map-gl";
import WebMercatorViewport from "viewport-mercator-project";

import { Button } from "../../../Common/Button";
import { Form, SectionTitle, TextInput, InputRow, Select } from "./Common";
import AccountBoundaryLayer from "../../../Maps/layers/accountBoundary";
import ParcelLayer from "../../../Maps/layers/parcels";
import pin from "./pin.svg";
import {
  useHighlightedParcel,
  useHoveredParcel,
  onMapAvailable,
} from "../../../Maps/utils/mapUtils";
import SimpleMap, { ViewportConfig } from "../../../Maps/SimpleMap";
import {
  AccountForPropertyCorrectionFormQuery,
  Parcel,
  useAccountForPropertyCorrectionFormQuery,
} from "../../../../generated/graphql";
import { Search, SearchResultProps } from "../../../Search";

import {
  DataRow,
  Label,
  Value,
  ParcelPaths,
  SearchBox,
  LatLongInputRow,
  Divider,
  LocationWarnings,
  AddressErrors,
  AddressTooltip,
  ZoomControls,
  CenterButton,
} from "./__styles__/PropertyCorrection";

type PropertyForm = {
  id: string;
  parcel: Maybe<{
    id: string;
    address: string;
    parcelId: string;
    lot: string;
    block: string;
    legalDescription: string;
  }>;
  longitude: number;
  latitude: number;
  streetAddress: string;
  city: string;
  state: string;
  zipcode: string;
  ignoreParcel: boolean;
};

const ParcelInfo = ({
  parcel,
}: {
  parcel: NonNullable<PropertyForm["parcel"]>;
}) => (
  <>
    <DataRow>
      <Label>Parcel ID:</Label>
      <Value>{parcel.parcelId}</Value>
    </DataRow>
    <DataRow>
      <Label>Lot Number:</Label>
      <Value>{parcel.lot}</Value>
    </DataRow>
    <DataRow>
      <Label>Block Number:</Label>
      <Value>{parcel.block}</Value>
    </DataRow>
    <DataRow>
      <Label>Legal Description:</Label>
      <Value>{parcel.legalDescription}</Value>
    </DataRow>
    <Divider />
  </>
);

interface Coordinates {
  latitude: number;
  longitude: number;
}

interface MaybeCoordinates {
  latitude?: Maybe<number>;
  longitude?: Maybe<number>;
}

interface AddressTooltip {
  x?: number;
  y?: number;
  lat?: number;
  lng?: number;
  address: string;
}

export interface PropertyCorrectionsProps {
  get: <T extends keyof PropertyForm>(key: T) => PropertyForm[T];
  set: <T extends keyof PropertyForm>(
    key: T
  ) => (value: PropertyForm[T]) => void;
  errors?: Maybe<Array<{ field: string; message: string }>>;
  account: NonNullable<AccountForPropertyCorrectionFormQuery["account"]>;
  setLoadingLocation: React.Dispatch<React.SetStateAction<boolean>>;
}

export function PropertyCorrections({
  get,
  set,
  errors,
  account,
  setLoadingLocation,
}: PropertyCorrectionsProps) {
  const _mapRef = React.useRef<any>();
  const [addressTooltip, setAddressTooltip] =
    React.useState<Maybe<AddressTooltip>>();
  const initialParcels: Array<NonNullable<PropertyForm["parcel"]>> = get(
    "parcel"
  )?.id
    ? [get("parcel")!]
    : [];
  const [parcels, setParcels] = React.useState(initialParcels);
  const [inputLatitude, setInputLatitude] = React.useState(get("latitude"));
  const [inputLongitude, setInputLongitude] = React.useState(get("longitude"));

  const { removeParcelHighlight, getHighlightedParcel, highlightParcelById } =
    useHighlightedParcel();

  const { hoverOverParcel, removeParcelHover } = useHoveredParcel({
    mapRef: _mapRef,
  });

  React.useEffect(() => {
    if (get("parcel")?.id) {
      set("ignoreParcel")(false);
    } else {
      set("ignoreParcel")(true);
    }
  }, [get("parcel")]);

  React.useEffect(() => {
    setInputLatitude(get("latitude"));
    setInputLongitude(get("longitude"));
  }, [get("latitude"), get("longitude")]);

  const setParcel = async (parcel: PropertyForm["parcel"]) => {
    set("parcel")(parcel);

    if (parcel?.id) {
      await onMapAvailable(_mapRef, map => highlightParcelById(map, parcel.id));
    } else {
      await onMapAvailable(_mapRef, removeParcelHighlight);
    }
  };

  const getParcelsAtPoint = async ({
    latitude,
    longitude,
  }: MaybeCoordinates) => {
    const transformMapboxParcel = ({
      properties,
    }: {
      properties: Parcel & { parcelNumber: string };
    }) => {
      return {
        id: properties.id,
        address: properties.address,
        parcelId: properties.parcelNumber,
        lot: properties.lot,
        block: properties.block,
        legalDescription: properties.legalDescription,
      };
    };

    return onMapAvailable(_mapRef, (map: any) => {
      const point = map.project([longitude, latitude]);
      const parcels = map.queryRenderedFeatures(point, {
        layers: ["parcels"],
      });

      return _.chain(parcels).map(transformMapboxParcel).uniqBy("id").value();
    });
  };

  const setParcelPathFromPoint = async ({
    latitude,
    longitude,
  }: MaybeCoordinates) => {
    const parcelsAtPoint = await getParcelsAtPoint({ latitude, longitude });

    if (parcelsAtPoint.length !== 0) {
      if (parcelsAtPoint.length === 1) {
        await setParcel(parcelsAtPoint[0]);
      } else {
        await setParcel(null);
      }
    } else {
      await setParcel(null);
    }

    setParcels(parcelsAtPoint);
    setLoadingLocation(false);
  };

  const _handleMapClick = async (evt: MapEvent) => {
    if (evt.target.className !== "overlays") return;
    setLoadingLocation(true);

    const [longitude, latitude] = evt.lngLat;

    set("latitude")(Number(latitude));
    set("longitude")(Number(longitude));

    await setParcelPathFromPoint({ latitude, longitude });
  };

  const _assignLatitudeLongitude = async ({
    longitude,
    latitude,
  }: Coordinates) => {
    set("latitude")(Number(latitude));
    set("longitude")(Number(longitude));

    const oldLatitude = viewport.latitude;
    const oldLongitude = viewport.longitude;
    const differentDegrees = (a: number, b: number) =>
      Math.abs(a - b) > 0.000001;

    // if we're moving an infinitely small amount, don't bother panning around
    // this prevents jitter if you're searching for an address literally next
    // to the currently highlighted parcel
    if (
      differentDegrees(oldLatitude, latitude) ||
      differentDegrees(oldLongitude, longitude)
    ) {
      centerMap({ latitude, longitude });

      // if you're searching for an address and parcels
      // have not yet been loaded because of your zoom level
      // , we have to wait until the map shows the parcel
      // layer before highlighting those parcels
      const map = _mapRef.current?.getMap();
      map.once("idle", async () => {
        await setParcelPathFromPoint({ latitude, longitude });
      });
    } else {
      await setParcelPathFromPoint({ latitude, longitude });
    }
  };

  const _handleSearchResult = async (data: SearchResultProps) => {
    setLoadingLocation(true);
    const { longitude, latitude } = data.point;
    await _assignLatitudeLongitude({ longitude, latitude });
  };

  const _handleMapHover = async (evt: MapEvent) => {
    await onMapAvailable(_mapRef, () => {
      removeParcelHover();

      const { id, properties } =
        (evt.features || []).find(f => f.layer.id === "parcels") || {};

      if (id) {
        hoverOverParcel(id);

        setAddressTooltip({
          ...evt.offsetCenter,
          lng: evt.lngLat[0],
          lat: evt.lngLat[1],
          address: properties.address,
        });
      } else {
        setAddressTooltip(null);
      }
    });
  };

  const mViewport = new WebMercatorViewport({
    width: window.innerWidth / 2,
    height: 500,
  });

  const [viewport, setViewport] = React.useState<ViewportConfig>({
    ...mViewport.fitBounds(
      account.bounds as [[number, number], [number, number]]
    ),
  });

  const isLatitudeLongitudeValid = ({ latitude, longitude }: Coordinates) => {
    try {
      mViewport.project([longitude, latitude]);
    } catch (e) {
      return false;
    }
    return true;
  };

  const validLatLong = isLatitudeLongitudeValid({
    latitude: get("latitude"),
    longitude: get("longitude"),
  });

  const centerMap = ({ latitude, longitude }: Coordinates) => {
    if (isLatitudeLongitudeValid({ latitude, longitude })) {
      setViewport({
        longitude,
        latitude,
        zoom: 16,
        transitionDuration: 500,
        transitionEasing: d3.easeCubicInOut,
        transitionInterpolator: new FlyToInterpolator(),
      } as ViewportConfig);
    }
  };

  const locationWarnings = !get("parcel")
    ? "A null parcel is dangerous! Please make absolutely sure that the selected property really does not fall within a parcel"
    : "None";

  const loadComplete = async () => {
    if (!_mapRef?.current?.getMap) {
      return;
    }

    if (validLatLong) {
      centerMap({ latitude: get("latitude"), longitude: get("longitude") });
      setInputLatitude(get("latitude"));
      setInputLongitude(get("longitude"));
    }

    // on initial map load, if you have a parcel on the property,
    // we should make sure to highlight that parcel if you zoom in
    // to the point where the parcel layer renders

    await onMapAvailable(_mapRef, (map: any) => {
      map.on("sourcedata", ({ sourceId }: { sourceId: string }) => {
        const parcel = get("parcel");

        if (sourceId === "parcels" && !getHighlightedParcel() && parcel?.id) {
          highlightParcelById(map, parcel.id);
        }
      });
    });
  };

  // nothing that instantiates this component ever puts a non-null value for errors
  const errorsByField = _.groupBy(errors, error => error.field);
  const errorForField = (field: keyof typeof errorsByField) =>
    (errorsByField[field] || []).map(error => error.message).join(",");

  const parcelError =
    parcels.length > 1 && !get("parcel")?.id
      ? "Multiple parcels detected"
      : errorForField("parcel");

  const addressError = errorForField("address");

  const coordinatesError =
    !validLatLong && (inputLatitude || inputLongitude)
      ? "Invalid latitude or longitude"
      : errorForField("coordinates");

  const parcel = get("parcel");

  return (
    <Form>
      {parcel && <ParcelInfo parcel={parcel} />}
      <SectionTitle>Property Address</SectionTitle>

      <TextInput
        name="streetAddress"
        value={get("streetAddress")}
        onChange={set("streetAddress")}
        label="Address"
      />

      <InputRow>
        <TextInput
          name="city"
          value={get("city")}
          onChange={set("city")}
          label="City"
          error={errorForField("city")}
        />

        <TextInput
          name="state"
          value={get("state")}
          onChange={set("state")}
          label="State"
        />

        <TextInput
          name="zipcode"
          value={get("zipcode")}
          onChange={set("zipcode")}
          label="Zipcode"
        />
      </InputRow>

      {addressError && <AddressErrors>{addressError}</AddressErrors>}

      <Divider />

      <SectionTitle>Property Location</SectionTitle>

      <LatLongInputRow>
        <InputRow>
          <TextInput
            name="latitude"
            value={inputLatitude}
            onChange={setInputLatitude}
            onBlur={async ({
              target: { value: input },
            }: React.FocusEvent<HTMLInputElement>) => {
              const latitude = Number(input);
              await _assignLatitudeLongitude({
                latitude,
                longitude: viewport.longitude,
              });
            }}
            label="Latitude"
            error={coordinatesError}
          />

          <TextInput
            name="longitude"
            value={inputLongitude}
            onChange={setInputLongitude}
            onBlur={async ({
              target: { value: input },
            }: React.FocusEvent<HTMLInputElement>) => {
              const longitude = Number(input);
              await _assignLatitudeLongitude({
                longitude,
                latitude: viewport.latitude,
              });
            }}
            label="Longitude"
          />
        </InputRow>
      </LatLongInputRow>

      <ParcelPaths>
        <Select
          label="Address"
          value={get("parcel")?.id}
          name="parcel"
          onChange={(id: string) =>
            setParcel(parcels.find(parcel => parcel.id === id)!)
          }
          disabled={parcels.length <= 1}
          options={parcels.map(parcel => {
            return {
              value: parcel.id,
              label: parcel.address,
            };
          })}
          error={parcelError}
        />
      </ParcelPaths>

      <SimpleMap
        ref={_mapRef}
        {...viewport}
        width="100%"
        height="500px"
        interactiveLayerIds={["parcels"]}
        onClick={_handleMapClick}
        onHover={_handleMapHover}
        setViewport={setViewport}
        getCursor={() => "pointer"}
        baseMapStyle={account.defaultBaseMap.mapboxStyle}
        onLoad={loadComplete}
      >
        <SearchBox>
          <Search
            handleResultClick={_handleSearchResult}
            accountId={account.id}
          />
        </SearchBox>

        {addressTooltip && (
          <AddressTooltip
            css={{ top: addressTooltip.y, left: addressTooltip.x }}
          >
            {addressTooltip.address}
          </AddressTooltip>
        )}

        <AccountBoundaryLayer accountId={account.id} />
        <ParcelLayer accountId={account.id} />
        {validLatLong && (
          <Marker
            latitude={get("latitude")}
            longitude={get("longitude")}
            offsetLeft={-5}
            offsetTop={-10}
          >
            <img src={pin} width={"10px"} height={"10px"} />
          </Marker>
        )}

        {validLatLong && (
          <CenterButton>
            <Button
              size="medium"
              styleVariant="primary"
              onClick={() => {
                centerMap({
                  longitude: get("longitude"),
                  latitude: get("latitude"),
                });
              }}
            >
              Center Map On Coordinates
            </Button>
          </CenterButton>
        )}
        <ZoomControls>
          <NavigationControl showCompass={false} />
        </ZoomControls>
      </SimpleMap>

      <LocationWarnings>Location Warnings: {locationWarnings}</LocationWarnings>
    </Form>
  );
}

export default (
  args: {
    accountId: string;
    setProcessing: React.Dispatch<React.SetStateAction<boolean>>;
  } & Omit<PropertyCorrectionsProps, "account" | "setLoadingLocation">
) => {
  const { accountId } = args;
  const { loading, error, data } = useAccountForPropertyCorrectionFormQuery({
    variables: { id: accountId },
  });

  if (loading || error || !data?.account?.bounds) {
    return <div />;
  }

  return (
    <PropertyCorrections
      account={data.account}
      {...args}
      setLoadingLocation={args.setProcessing}
    />
  );
};
