import React from "react";
import { useForm } from "react-hook-form";
import { useModal } from "react-modal-hook";

import { Grid, Row, Col, Box } from "../Common/__styles__/Layout";
import { Button } from "../Common/Button";
import { track } from "../../utils/tracking";
import {
  CurrentUserSettingsQuery,
  UpdateUserMutationVariables,
  useCurrentUserSettingsQuery,
  UserLoginErrorCode,
  useSendMfaCodeMutation,
  useUpdateUserMutation,
} from "../../generated/graphql";
import { Text } from "../Inputs/react-hook-form";
import Divider from "../Common/Divider";
import Modal from "../Common/Modal";
import { useStatusToasts } from "../../hooks/useStatusToasts";
import { useUpdatePasswordModal } from "./UpdatePasswordModal";
import { SettingsTooltip } from "./Account/Tooltip";
import ResetMFAPhone from "./ResetMFAPhone";
import {
  Header,
  HeaderTitle,
  SectionTitle,
  Section,
  Footer,
} from "./__styles__/Content";
import { ResetPasswordNote } from "./__styles__/User";
import { Redirect } from "react-router";
import { getPath } from "common/routing";

interface UserProps {
  user: NonNullable<CurrentUserSettingsQuery["currentUser"]>;
}

export interface UserSettingsFormProps {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

const UserSettingsForm = ({ user }: UserProps) => {
  const {
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    setError,
  } = useForm<UserSettingsFormProps>({
    defaultValues: {
      firstName: user.firstName || "",
      lastName: user.lastName || "",
      email: user.email || "",
      phoneNumber: user.phoneNumber || "",
    },
  });

  const { addSuccessToast, addErrorToast } = useStatusToasts();
  const [sendCode, { data }] = useSendMfaCodeMutation({
    onCompleted: data => {
      if (
        data.sendMFACode.error?.code === UserLoginErrorCode.INVALID_PHONE_NUMBER
      ) {
        setError("phoneNumber", {
          message: "Please enter a valid phone number",
        });
        return;
      }

      if (
        data.sendMFACode.error?.code ===
        UserLoginErrorCode.PHONE_NUMBER_ALREADY_IN_USE
      ) {
        setError("phoneNumber", {
          message: data.sendMFACode.error.message,
        });
        return;
      }

      show();
    },
    onError: () => {
      addErrorToast(
        `There was an issue verifying your phone number. Please try again. If the problem persists, please email us at support@withforerunner.com`
      );
    },
  });

  const [updateUser, { loading }] = useUpdateUserMutation({
    onCompleted: () => {
      addSuccessToast("Your user settings were successfully updated.");
      hide();
    },
    onError: error => {
      const duplicatePhoneNumberMessage =
        "This phone number is already in use.";
      const message =
        error.message === duplicatePhoneNumberMessage
          ? duplicatePhoneNumberMessage
          : "Something went wrong. Please try again.";
      addErrorToast(message);
    },
  });

  const [show, hide] = useModal(() => {
    return (
      <Modal onRequestClose={hide}>
        <ResetMFAPhone
          maskedPhoneNumber={data?.sendMFACode.phoneNumber!}
          updatePhoneNumber={updateUserSettings}
          userProvidedPhoneNumber={getValues("phoneNumber")}
        />
      </Modal>
    );
  }, [data?.sendMFACode.phoneNumber]);

  const submitForm = async (userProvidedData: UserSettingsFormProps) => {
    if (
      userProvidedData.phoneNumber === user.phoneNumber ||
      !user.account.securityConfiguration.multifactorAuthEnabled
    ) {
      await updateUserSettings(userProvidedData);
      return;
    }
    await sendCode({
      variables: { data: { phoneNumber: userProvidedData.phoneNumber } },
    });
  };

  const updateUserSettings = async (
    userProvidedData: UserSettingsFormProps = getValues()
  ) => {
    const variables: UpdateUserMutationVariables = {
      userId: user.id,
      firstName: userProvidedData.firstName,
      lastName: userProvidedData.lastName,
      email: userProvidedData.email,
      phoneNumber: userProvidedData.phoneNumber,
    };

    track("Updated User Settings");
    await updateUser({ variables });
  };

  return (
    <Section>
      <form>
        <Grid>
          <Row>
            <Col>
              <Text
                label="First name"
                data-testid="first-name"
                error={errors.firstName?.message}
                size="large"
                {...register("firstName", {
                  required: "Required field",
                })}
                required={true}
              />
            </Col>
            <Col>
              <Text
                label="Last name"
                data-testid="last-name"
                error={errors.lastName?.message}
                size="large"
                {...register("lastName", {
                  required: "Required field",
                })}
                required={true}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Text
                label="Email"
                data-testid="email"
                error={errors.email?.message}
                size="large"
                {...register("email", {
                  required: "Required field",
                })}
                required={true}
              />
            </Col>
            <Col>
              <Text
                label="Mobile phone number"
                error={errors.phoneNumber?.message}
                size="large"
                tooltip={
                  user.account.securityConfiguration.multifactorAuthEnabled && (
                    <SettingsTooltip
                      contentText="This phone number will be used for MFA verification."
                      data-testid="phone-number-tooltip"
                    />
                  )
                }
                {...register("phoneNumber", {
                  validate: {
                    required: value => {
                      if (
                        !value &&
                        user.account.securityConfiguration
                          .multifactorAuthEnabled
                      ) {
                        return "Required field";
                      }
                      return true;
                    },
                  },
                })}
                required={
                  user.account.securityConfiguration.multifactorAuthEnabled
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                styleVariant="primary"
                size="medium"
                disabled={loading}
                onClick={handleSubmit(submitForm)}
              >
                Update personal info
              </Button>
            </Col>
          </Row>
        </Grid>
      </form>
    </Section>
  );
};

export default () => {
  const { data, error, loading } = useCurrentUserSettingsQuery();

  const [showUpdatePasswordModal] = useUpdatePasswordModal();

  if (error || loading || !data) return null;

  // See this sentry error: https://forerunner.sentry.io/issues/5863693458/
  // Essentially, if for whatever reason we don't get back a currentUser, just send them to the login screen
  if (!data.currentUser) {
    return (
      <Redirect
        to={{
          pathname: getPath("login"),
          state: { referrer: location.pathname },
        }}
      />
    );
  }

  return (
    <Box>
      <Header>
        <HeaderTitle>Personal info</HeaderTitle>
      </Header>
      <Divider margins={"0px 0px 16px 0px"} />
      <div style={{ maxWidth: 1000 }}>
        <UserSettingsForm user={data.currentUser} />
      </div>
      <Divider margins={"16px 0px"} />
      <Section>
        <SectionTitle>Password settings</SectionTitle>
        <ResetPasswordNote>
          Update your password settings using the button below.
        </ResetPasswordNote>
      </Section>
      <Section>
        <Button
          styleVariant="outlineLight"
          size="medium"
          onClick={() => showUpdatePasswordModal({ user: data.currentUser })}
        >
          Reset password
        </Button>
      </Section>
      <Footer />
    </Box>
  );
};
