import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Button } from "../../../Common/Button";

import {
  ButtonSection,
  Container,
  ContentSection,
  FormSection,
  HeaderSection,
  InputRow,
  PrimaryButtons,
} from "../../../Common/__styles__/Modal";
import { Select, Text, Number } from "../../../Inputs/react-hook-form";
import { RASTER_LAYER_SECTION } from "common/constants";
import { RasterUnitOfMeasurement } from "../../../../generated/graphql";

export type RasterFormData = {
  layerName: string;
  layerSection: RASTER_LAYER_SECTION;
  url: string;
  bandMappingsUrl: string;
  wkid: number;
  bandNumber: number;
  measurementUnit: RasterUnitOfMeasurement;
  measurementName: string;
  username: string;
  password: string;
  tokenGenerationUrl: string;
};

export const RasterForm = ({
  onSave,
  onCancel,
  loading,
}: {
  onSave: (data: RasterFormData) => Promise<void>;
  onCancel: () => void;
  loading: boolean;
}) => {
  const formMethods = useForm<RasterFormData>({});

  const {
    handleSubmit,
    formState: { errors, isValid },
    register,
    control,
  } = formMethods;

  const onSubmit = async (data: RasterFormData) => {
    void onSave(data);
  };

  const measurementUnitOptions = [
    { value: RasterUnitOfMeasurement.FEET, label: "Feet" },
    { value: RasterUnitOfMeasurement.INCHES, label: "Inches" },
    { value: RasterUnitOfMeasurement.METERS, label: "Meters" },
  ];

  return (
    <FormProvider {...formMethods}>
      <Container>
        <HeaderSection>
          <h1>Add Raster</h1>
        </HeaderSection>
        <FormSection>
          <ContentSection>
            <InputRow>
              <Text
                label="Layer name"
                data-testid="layerName"
                size="medium"
                required
                {...register("layerName", {
                  required: "Layer is required",
                })}
                error={errors.layerName?.message}
              />
            </InputRow>
            <InputRow>
              <Select
                label="Layer section"
                name="layerSection"
                placeholder="Select layer section..."
                size="medium"
                control={control}
                rules={{
                  required: "Layer section is required",
                }}
                required
                options={[
                  {
                    label: "Flood",
                    value: RASTER_LAYER_SECTION.FLOOD,
                  },
                  {
                    label: "Land",
                    value: RASTER_LAYER_SECTION.LAND,
                  },
                  {
                    label: "Custom",
                    value: RASTER_LAYER_SECTION.CUSTOM,
                  },
                ]}
                error={errors.layerSection?.message}
              />
            </InputRow>
            <InputRow>
              <Text
                label="Export Image URL"
                data-testid="urlInput"
                size="medium"
                required
                {...register("url", {
                  required: "URL is required",
                })}
                error={errors.url?.message}
              />
            </InputRow>
            <InputRow>
              <Text
                label="Band Mappings URL"
                data-testid="bandMappingsUrlInput"
                size="medium"
                required
                {...register("bandMappingsUrl", {
                  required: "Band mappings URL is required",
                })}
                error={errors.bandMappingsUrl?.message}
              />
            </InputRow>
            <InputRow>
              <Number
                label="WKID"
                data-testid="wkidInput"
                name="wkid"
                size="medium"
                register={register}
                required
                error={errors.wkid?.message}
              />
            </InputRow>
            <InputRow>
              <Number
                label="Band Number"
                data-testid="bandNumberInput"
                name="bandNumber"
                size="medium"
                register={register}
                required
                error={errors.bandNumber?.message}
              />
            </InputRow>
            <InputRow>
              <Text
                label="Measurement name"
                data-testid="measurementNameInput"
                size="medium"
                required
                {...register("measurementName", {
                  required: "Measurement name is required",
                })}
                error={errors.measurementName?.message}
              />
            </InputRow>
            <InputRow>
              <Select
                control={control}
                name={"measurementUnit"}
                label="Measurement Unit"
                options={measurementUnitOptions}
                error={errors.measurementUnit?.message}
                required
                rules={{
                  required: "Measurement unit is required",
                }}
              />
            </InputRow>
            <InputRow>
              <Text
                label="Username"
                size="medium"
                {...register("username")}
                error={errors.username?.message}
              />
            </InputRow>
            <InputRow>
              <Text
                label="Password"
                size="medium"
                {...register("password")}
                error={errors.password?.message}
              />
            </InputRow>
            <InputRow>
              <Text
                label="Token generation URL"
                data-testid="tokenGenerationUrlInput"
                size="medium"
                {...register("tokenGenerationUrl")}
                error={errors.tokenGenerationUrl?.message}
              />
            </InputRow>
          </ContentSection>
          <ButtonSection>
            <PrimaryButtons>
              <Button styleVariant="secondary" size="medium" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                styleVariant="primary"
                disabled={!isValid && !loading}
                size="medium"
                onClick={handleSubmit(onSubmit)}
              >
                Create
              </Button>
            </PrimaryButtons>
          </ButtonSection>
        </FormSection>
      </Container>
    </FormProvider>
  );
};
