import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useModal } from "react-modal-hook";
import { isEqual } from "lodash";
import { QueryDescription } from "common/utils/queryBuilder";

import Modal from "../../../Modal";
import { Button } from "../../../Button";
import {
  SavedView,
  useUpdateSavedViewMutation,
} from "../../../../../generated/graphql";
import { useStatusToasts } from "../../../../../hooks/useStatusToasts";
import { Text } from "../../../../Inputs/react-hook-form";
import { TableContext } from "../../TableContext";
import { track } from "../../../../../utils/tracking";

import {
  ButtonSection,
  Container,
  ContentSection,
  FormSection,
  HeaderSection,
  InputRow,
  PrimaryButtons,
} from "../../../__styles__/Modal";

interface EditSavedViewForm {
  name: string;
}

const EditSavedViewModal = ({
  query,
  hideModal,
  savedView,
}: {
  query?: QueryDescription;
  hideModal: () => void;
  savedView: SavedView;
}) => {
  const { addSuccessToast, addErrorToast } = useStatusToasts();
  const [updateSavedView, { loading }] = useUpdateSavedViewMutation({
    onCompleted: () => {
      hideModal();
      addSuccessToast(`Your table view was successfully edited`);
    },
    refetchQueries: ["GetSavedViews"],
    awaitRefetchQueries: true,
    onError: () => {
      addErrorToast(
        `There was an issue editing your table view. Please try again. If the problem persists, please email us at support@withforerunner.com`
      );
    },
  });

  const {
    handleSubmit,
    formState: { errors, isValid },
    register,
  } = useForm<EditSavedViewForm>();

  const hasQueryChanges = !isEqual(query, savedView.query);

  const onSubmit = async (formData: EditSavedViewForm) => {
    const data = {
      ...formData,
      ...{ query: hasQueryChanges ? query : undefined },
      id: savedView.id,
    };

    track("Edited table view", {
      tableType: savedView.tableType,
      name: data.name,
    });

    await updateSavedView({ variables: { data } });
  };

  return (
    <Container>
      <HeaderSection>
        <h1>Edit view</h1>
      </HeaderSection>
      <FormSection>
        <ContentSection>
          <p>
            Any changes saved to this existing view will be updated for
            everyone. If filters or columns that reference sensitive data are
            present, only users with permissions will be able to see this view.
          </p>
          <InputRow>
            <Text
              {...register("name", { required: true })}
              error={errors.name?.message}
              size="small"
              label="Name"
              required
              defaultValue={savedView.name}
            />
          </InputRow>
        </ContentSection>
        <ButtonSection>
          <PrimaryButtons>
            <Button styleVariant="secondary" onClick={hideModal} size="medium">
              Cancel
            </Button>
            <Button
              size="medium"
              styleVariant="primary"
              onClick={handleSubmit(onSubmit)}
              disabled={!isValid || loading}
            >
              Save view
            </Button>
          </PrimaryButtons>
        </ButtonSection>
      </FormSection>
    </Container>
  );
};

export const useEditSavedViewModal = () => {
  const { currentQuery } = useContext(TableContext);
  const [savedView, setSavedView] = useState<SavedView | null>(null);

  let [showModal, hideModal] = useModal(
    () => (
      <Modal onRequestClose={hideModal}>
        <EditSavedViewModal
          hideModal={() => {
            hideModal();
            setSavedView(null);
          }}
          savedView={savedView!}
          query={currentQuery}
        />
      </Modal>
    ),
    [savedView, currentQuery]
  );

  const showEditSavedViewModal = (savedView: SavedView) => {
    setSavedView(savedView);
    showModal();
  };

  return showEditSavedViewModal;
};
