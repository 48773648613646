import React from "react";
import { Switch, Route } from "react-router-dom";
import { Layout } from "../Common/FullWidthLayout";
import { PermitsTable } from "./SubmissionsTables";

export default () => (
  <Layout title="Permits">
    <Switch>
      <Route path="/submissions" component={PermitsTable} />
    </Switch>
  </Layout>
);
