import React, { useMemo } from "react";
import { GetUserInvitesAndUsersQuery } from "../../generated/graphql";
import { ColumnDef } from "@tanstack/react-table";
import Table from "../Common/Tables/Table";
import { Account } from "../Authorization/types";
import { RoleName } from "common/authorization";

import UserInviteActions from "./UserInviteActions";
import UserActions from "./UserActions";

import { ButtonWrapper, OptionsContainer } from "./__styles__/TeamTable";
import {
  LegacyTableStateFromURL,
  ManualPaginationConfig,
} from "../Common/Tables/hooks";

export enum STATUS_TYPES {
  INVITED = "Invited",
  ACTIVE = "Active",
  SUSPENDED = "Suspended",
}

const ROLES_TO_TEXT: Record<RoleName, string> = {
  admin: "Admin",
  manager: "Manager",
  creator: "Creator",
  member: "Member",
  view_only: "View only",
  guest: "Guest",
} as const;

const LOADING_TEXT = "Loading users";
const NO_DATA_TEXT = "No users found";

type QueryResult = Partial<
  Pick<GetUserInvitesAndUsersQuery["usersAndInvites"], "data">
>;
export type UserOrUserInvite = NonNullable<QueryResult["data"]>[number];

export interface TeamTableProps {
  account: Maybe<Account>;
  previousData?: Array<UserOrUserInvite>;
  currentData: Array<UserOrUserInvite>;
  loading: boolean;
  manualPaginationConfig: ManualPaginationConfig;
  onUpdate: () => {};
  initialTableState: LegacyTableStateFromURL;
  setTableStateInURL: (state: any) => void;
}

export default ({
  account,
  previousData,
  currentData,
  loading,
  manualPaginationConfig,
  onUpdate,
  initialTableState,
  setTableStateInURL,
}: TeamTableProps) => {
  const Name = (row: UserOrUserInvite) => {
    return `${row.firstName} ${row.lastName}`;
  };

  const Status = (row: UserOrUserInvite) => {
    if (row.__typename === "UserInvite") return STATUS_TYPES.INVITED;
    if (row.suspended === true) return STATUS_TYPES.SUSPENDED;
    return STATUS_TYPES.ACTIVE;
  };

  const Pii = (row: UserOrUserInvite) =>
    row.canViewPersonalIdentifiableInformation ? "Can view" : "Cannot view";

  const Role = (row: UserOrUserInvite) => {
    return ROLES_TO_TEXT[row.role.name];
  };

  const ActionButton = (row: UserOrUserInvite) => {
    if (row.__typename === "UserInvite")
      return (
        <OptionsContainer>
          <ButtonWrapper>
            <UserInviteActions
              id={row.id}
              firstName={row.firstName}
              lastName={row.lastName}
              email={row.email}
              onUpdate={onUpdate}
            />
          </ButtonWrapper>
        </OptionsContainer>
      );
    return (
      <OptionsContainer>
        <ButtonWrapper>
          <UserActions user={row} onUpdate={onUpdate} />
        </ButtonWrapper>
      </OptionsContainer>
    );
  };

  let columns = useMemo<Array<ColumnDef<UserOrUserInvite>>>(
    () => [
      {
        id: "name",
        header: "Name",
        accessorFn: Name,
      },
      {
        id: "email",
        header: "Email",
        accessorKey: "email",
      },
      {
        id: "status",
        header: "Status",
        accessorFn: Status,
      },
      {
        id: "role",
        header: "Role",
        accessorFn: Role,
      },
      {
        id: "actions",
        header: "",
        cell: row => ActionButton(row.row.original),
      },
    ],
    []
  );

  if (account?.repetitiveLossEnabled) {
    columns.splice(3, 0, {
      id: "pii",
      header: "PII",
      accessorFn: Pii,
    });
  }

  return (
    <Table
      previousData={previousData}
      currentData={currentData}
      columns={columns}
      tableStyleDetails={{ hasHighlights: true, hasRowActions: true }}
      loadingDetails={{
        loading,
        loadingText: LOADING_TEXT,
        noDataText: NO_DATA_TEXT,
      }}
      initialState={initialTableState}
      manualPaginationConfig={manualPaginationConfig}
      setTableStateInURL={setTableStateInURL}
    />
  );
};
