import "core-js/stable";
import React from "react";
import ReactDOM from "react-dom";
import { AppScaffold } from "./components/AppScaffold";

import { setupEntrypoint } from "./utils/entrypoint";

import "./styles/base.scss";
import { MainRoutes } from "./components/MainRoutes";
import { StagingBanner } from "./components/AdminApp/AccountsApp/StagingBanner";

const { history } = setupEntrypoint();
ReactDOM.render(
  <AppScaffold history={history}>
    <StagingBanner />
    <MainRoutes />
  </AppScaffold>,
  document.getElementById("app")
);
