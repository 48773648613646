import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { Icon } from "../Icons/LucideIcons";
import { Button } from "../Button";

export const RowDragHandleCell = ({ rowId }: { rowId: string }) => {
  const { attributes, listeners } = useSortable({
    id: rowId,
  });
  return (
    <Button styleVariant="ghost" {...attributes} {...listeners}>
      <Icon iconName="grip-vertical" color="borderButtonOutline" size={20} />
    </Button>
  );
};
