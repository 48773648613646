import React from "react";
import { INFO_REQUEST_TYPES } from "common/constants";
import Badge from "../Common/Badge";
import { typeLabels } from "./LogMutationForm";

export default ({ type }: { type: INFO_REQUEST_TYPES }) => {
  const text =
    type === INFO_REQUEST_TYPES.WRITTEN ? "Written" : typeLabels[type];

  return <Badge color={"magenta"} text={text} />;
};
