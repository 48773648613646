import React from "react";

import { SectionTitle, TextInput, InputRow, Note, DateInput } from "./Common";

export default function FullDataExtractionGroup7({ get, set, errors }) {
  return (
    <>
      <SectionTitle>SECTION I</SectionTitle>

      <TextInput
        name="buildingOwnerName"
        value={get("buildingOwnerName")}
        onChange={set("buildingOwnerName")}
        error={errors.buildingOwnerName}
        label="Building Owner's Name"
      />

      <TextInput
        name="streetAddress"
        value={get("streetAddress")}
        onChange={set("streetAddress")}
        error={errors.address}
        label="Address"
      />

      <InputRow>
        <TextInput
          name="city"
          value={get("city")}
          onChange={set("city")}
          error={errors.address}
          label="City"
        />

        <TextInput
          name="state"
          value={get("state")}
          onChange={set("state")}
          error={errors.address}
          label="State"
        />

        <TextInput
          name="zipcode"
          value={get("zipcode")}
          onChange={set("zipcode")}
          error={errors.address}
          label="Zipcode"
        />
      </InputRow>

      <Note>If Zipcode is not present, please leave blank.</Note>

      <TextInput
        name="firmMapNumber"
        value={get("firmMapNumber")}
        onChange={set("firmMapNumber")}
        error={errors.firmMapNumber}
        label="FIA MAP PANEL ON WHICH PROPERTY IS LOCATED"
      />
      <Note>
        Note: If there are two separate numbers, please transcribe the entire
        field as-is.
      </Note>

      <TextInput
        name="floodZone"
        value={get("floodZone")}
        onChange={set("floodZone")}
        error={errors.floodZone}
        label="FIA MAP ZONE IN WHICH PROPERTY IS LOCATED"
      />
      <Note>
        Note: If multiple Flood Zones are present, please enter them separated
        by commas.
      </Note>

      <DateInput
        name="firmPanelEffectiveDate"
        value={get("firmPanelEffectiveDate")}
        onChange={set("firmPanelEffectiveDate")}
        error={errors.firmPanelEffectiveDate}
        label="FIA MAP EFFECTIVE DATE"
      />

      <TextInput
        name="baseFloodElevation"
        value={get("baseFloodElevation")}
        onChange={set("baseFloodElevation")}
        error={errors.baseFloodElevation}
        label="BASE FLOOD ELEVATION AT THE PROPOSED SITE"
      />
      <Note>
        Note: if there is a unit of measurement (example: feet) please do not
        include it. However, do include any N/A values. If multiple elevations
        are present, please enter them separated by commas.
      </Note>

      <SectionTitle>SECTION II - ELEVATION CERTIFICATE</SectionTitle>

      <TextInput
        name="topOfBottomFloor"
        value={get("topOfBottomFloor")}
        onChange={set("topOfBottomFloor")}
        error={errors.topOfBottomFloor}
        label="I certify that the property location described above has a lowest floor at an elevation of"
      />
      <Note>
        If there is a unit of measurement (example: feet) please do not include
        it. However, do include any N/A values.
      </Note>

      <SectionTitle>SECTION III - FLOODPROOFING CERTIFICATION</SectionTitle>

      <TextInput
        name="certifierName"
        value={get("certifierName")}
        onChange={set("certifierName")}
        error={errors.certifierName}
        label="Certifier's Name"
      />

      <TextInput
        name="certifierLicenseNumber"
        value={get("certifierLicenseNumber")}
        onChange={set("certifierLicenseNumber")}
        error={errors.certifierLicenseNumber}
        label="License Number"
      />

      <DateInput
        label="Date"
        name="issuedAt"
        value={get("issuedAt")}
        onChange={set("issuedAt")}
        error={errors.issuedAt}
      />
    </>
  );
}
