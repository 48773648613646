import React, { useContext, useEffect } from "react";
import { tail } from "lodash";
import { ColumnDef } from "@tanstack/react-table";
// eslint-disable-next-line no-restricted-imports
import * as Dropdown from "@radix-ui/react-dropdown-menu";

import { Button } from "../../../Button";
import { Attribute } from "../../types";
import { ACTION_COLUMN_DEF_CONSTANTS } from "../../../ActionCell";
import { useColumnSettingsReducer } from "./useColumnsReducer";
import { AttributeRow, SectionTitleNavigation } from "../utils";
import { TableContext } from "../../TableContext";
import { track } from "../../../../../utils/tracking";
import { hasColumnId } from "./utils";
import { styled } from "../../../../../stitches.config";
import Divider from "../../../Divider";

import {
  DropdownItemStyles,
  DropdownContentStyles,
  SectionTitleLabel,
  DropdownItemList,
} from "../__styles__/TableSettings";

const AppliedColumnsState = <T,>({
  editableColumns,
  dispatch,
  hideColumn,
  columnConfig,
}: {
  editableColumns: ColumnDef<T>[];
  dispatch: ReturnType<typeof useColumnSettingsReducer>[1];
  hideColumn: (id: string) => void;
  columnConfig: Array<Attribute>;
}) => {
  const { name: tableName } = useContext(TableContext);

  const onRemoveColumn = (columnId: string) => {
    hideColumn(columnId);
    track("Removed column", {
      tableName,
      column: columnId,
    });
  };

  return (
    <>
      {editableColumns.length === 0 ? (
        <SectionTitleLabel>No columns selected</SectionTitleLabel>
      ) : (
        <DropdownItemList>
          {editableColumns.map(column => (
            <DropdownItem
              omitHoverStyles
              key={column.id}
              onSelect={event => {
                event.preventDefault();
                onRemoveColumn(column.id!);
              }}
            >
              <AttributeRow
                attribute={{ label: column.header as string }}
                rightIcon={{
                  icon: "x",
                  onClick: () => onRemoveColumn(column.id!),
                }}
                testId={`edit-column-${column.id}`}
              />
            </DropdownItem>
          ))}
        </DropdownItemList>
      )}
      <Divider extension={8} />
      <DropdownItem
        onSelect={event => {
          event.preventDefault();
          dispatch({
            type: "setAttribute",
            data: {
              newAttribute: {
                attributes: columnConfig,
                label: "Category",
              },
            },
          });
        }}
      >
        <AttributeRow attribute={{ label: "Add column", icon: "plus" }} />
      </DropdownItem>
    </>
  );
};

const AddColumnState = <T,>({
  attributeHistory,
  currentColumns,
  addColumn,
  settingsState,
  dispatch,
}: {
  attributeHistory: Array<Attribute>;
  currentColumns: ColumnDef<T>[];
  addColumn: (id: string) => void;
  settingsState: ReturnType<typeof useColumnSettingsReducer>[0];
  dispatch: ReturnType<typeof useColumnSettingsReducer>[1];
}) => {
  const { name } = useContext(TableContext);
  const { currentAttribute } = settingsState;

  const hasAttributes = "attributes" in currentAttribute;

  if (!hasAttributes) {
    return null;
  }

  const onAddColumn = (attribute: Pick<Attribute, "columnId" | "label">) => {
    addColumn(attribute.columnId!);
    track("Added column", {
      tableName: name,
      column: attribute.label,
    });
  };

  const parentAttributeLabel =
    attributeHistory[attributeHistory.length - 1]?.label;

  let menuTitle = parentAttributeLabel ?? "Columns";

  return (
    <>
      <Dropdown.Item
        onSelect={event => {
          event.preventDefault();
          dispatch({ type: "goBack" });
        }}
      >
        <SectionTitleNavigation label={menuTitle} />
      </Dropdown.Item>
      <Divider extension={8} />
      <SectionTitleLabel>Attributes</SectionTitleLabel>
      <DropdownItemList>
        {currentAttribute.attributes.map((attribute, index) => {
          if (hasColumnId(attribute)) {
            const disabled = currentColumns.some(
              column => column.id === attribute.columnId
            );

            return (
              <DropdownItem
                key={index}
                onSelect={event => {
                  event.preventDefault();
                  onAddColumn(attribute);
                }}
                omitHoverStyles={disabled}
                disabled={disabled}
              >
                <AttributeRow attribute={attribute} disabled={disabled} />
              </DropdownItem>
            );
          } else if ("attributes" in attribute) {
            return (
              <DropdownItem
                key={index}
                onSelect={event => {
                  event.preventDefault();
                  dispatch({
                    type: "setAttribute",
                    data: {
                      newAttribute: attribute,
                    },
                  });
                }}
              >
                <AttributeRow
                  attribute={attribute}
                  rightIcon={{ icon: "chevron-right" }}
                />
              </DropdownItem>
            );
          } else {
            return null;
          }
        })}
      </DropdownItemList>
    </>
  );
};

export const ColumnsButton = <T,>({
  currentColumns,
  columnConfig,
  hideColumn,
  addColumn,
  addingNewView,
}: {
  currentColumns: ColumnDef<T>[];
  columnConfig: Array<Attribute>;
  hideColumn: (id: string) => void;
  addColumn: (id: string) => void;
  addingNewView: boolean;
}) => {
  const [settingsState, dispatch] = useColumnSettingsReducer();

  const { history, menuOpen } = settingsState;

  const toggleMenu = () => {
    if (!menuOpen) {
      dispatch({ type: "openMenu" });
    } else {
      dispatch({ type: "closeMenu" });
    }
  };

  const editableColumns = tail(currentColumns).filter(
    column => column.id !== ACTION_COLUMN_DEF_CONSTANTS.id
  );

  useEffect(() => {
    if (addingNewView) {
      dispatch({ type: "openMenu" });
    }
  }, [addingNewView]);

  return (
    <div style={{ userSelect: "none" }}>
      <Button
        onClick={toggleMenu}
        size="small"
        aria-label="Columns"
        leftIconName="settings"
        styleVariant={"secondary"}
      >
        Columns
      </Button>
      <Dropdown.Root open={menuOpen} onOpenChange={toggleMenu}>
        <Dropdown.Trigger asChild>
          <div />
        </Dropdown.Trigger>
        <DropdownContent align="start" sideOffset={4}>
          {history.length === 0 ? (
            <AppliedColumnsState
              editableColumns={editableColumns}
              hideColumn={hideColumn}
              dispatch={dispatch}
              columnConfig={columnConfig}
            />
          ) : (
            <AddColumnState
              attributeHistory={history}
              currentColumns={currentColumns}
              addColumn={addColumn}
              settingsState={settingsState}
              dispatch={dispatch}
            />
          )}
        </DropdownContent>
      </Dropdown.Root>
    </div>
  );
};

const DropdownContent = styled(Dropdown.Content, {
  ...DropdownContentStyles,
});

const DropdownItem = styled(Dropdown.Item, {
  ...DropdownItemStyles,
});
