import React, { useContext } from "react";
import { useFormContext } from "react-hook-form";

import { ParcelImportWizardContext } from "../context";
import {
  PARCEL_IMPORT_CONFIG_WORKFLOW,
  ParcelImportWizardFormData,
} from "../types";
import { Text } from "../../../../../Inputs/react-hook-form";
import { AdditionalFieldLabelTextWrapper as StaticTextWrapper } from "../__styles__/ParcelImportWizard";
import { useFieldError, useSourceFieldDependency } from "./hooks";
import { isEmpty } from "lodash";
import { Label } from "../../../../../Inputs";

const AdditionalFieldNameInput = ({
  fieldIndex,
  fieldPath,
}: {
  fieldIndex: number;
  fieldPath: `additionalFields.${number}`;
}) => {
  const fieldName = `${fieldPath}.label` as const;

  const { parcelModelFields, workflow } = useContext(ParcelImportWizardContext);
  const { getValues, register } = useFormContext<ParcelImportWizardFormData>();
  const fieldError = useFieldError({ fieldName });

  const hasNoSourceValue = useSourceFieldDependency({
    fieldName,
    sourceFieldName: `${fieldPath}.source`,
  });

  const validateFieldName = (value: string) => {
    let nameIsAlreadyUsed = false;
    const { additionalFields } = getValues();

    if (additionalFields && additionalFields.length > 1) {
      nameIsAlreadyUsed = additionalFields.some(({ label }, i) => {
        if (i === fieldIndex) {
          return false;
        }

        return label === value;
      });
    }

    if (!nameIsAlreadyUsed && parcelModelFields.length > 0) {
      nameIsAlreadyUsed = parcelModelFields.includes(value);
    }

    if (nameIsAlreadyUsed) {
      return "This name is already in use.";
    }

    return;
  };

  const fieldLabelValue = getValues(fieldName);

  const hasNonEditableFieldLabelValue =
    !isEmpty(fieldLabelValue) &&
    [
      PARCEL_IMPORT_CONFIG_WORKFLOW.EDIT_SETTINGS,
      PARCEL_IMPORT_CONFIG_WORKFLOW.DISPLAY_SETTINGS,
    ].includes(workflow);

  return hasNoSourceValue ? (
    <StaticTextWrapper>
      <Label
        htmlFor={`additionalFields.${fieldIndex}.source`}
        text="Forerunner field name"
      />
    </StaticTextWrapper>
  ) : hasNonEditableFieldLabelValue ? (
    <StaticTextWrapper hasFieldLabelValue={hasNonEditableFieldLabelValue}>
      <Label
        htmlFor={`additionalFields.${fieldIndex}.source`}
        text={fieldLabelValue}
      />
    </StaticTextWrapper>
  ) : (
    <Text
      {...register(fieldName, {
        required: "This field is required.",
        validate: validateFieldName,
      })}
      disabled={hasNoSourceValue}
      error={fieldError}
      placeholder="Forerunner field name"
      size="small"
    />
  );
};

export default AdditionalFieldNameInput;
