import React, { ComponentProps } from "react";

import { Empty } from "../../__styles__/Content";
import { EmptyState } from "../../../Common/EmptyState";
import RecentImportTasksTable from "../RecentImportTasksTable";
import { ApolloError } from "@apollo/client";

export type FirmImportsProps = {
  error?: Pick<ApolloError, "message">;
} & Pick<
  ComponentProps<typeof RecentImportTasksTable>,
  "recentImportTasks" | "loading" | "manualPaginationConfig"
>;

export default ({
  recentImportTasks,
  error,
  loading,
  manualPaginationConfig,
}: FirmImportsProps) => {
  if (error) return <div>Error loading recent imports.</div>;

  const hasRecentFirmImports = !!recentImportTasks?.length;

  return (
    <>
      {hasRecentFirmImports && (
        <RecentImportTasksTable
          recentImportTasks={recentImportTasks}
          loading={loading}
          manualPaginationConfig={manualPaginationConfig}
        />
      )}
      {!hasRecentFirmImports && (
        <Empty>
          <EmptyState message="Your account has no recent imports" />
        </Empty>
      )}
    </>
  );
};
