import React, { useContext } from "react";
import { Box, FlexRow } from "../../../Common/__styles__/Layout";
import {
  Header,
  HeaderTitle,
  HelperText,
  SectionTitle,
} from "../../__styles__/Content";
import { Button } from "../../../Common/Button";
import SubmissionTypesTable from "./SubmissionTypesTable";
import { useHistory } from "react-router";
import { buildLink } from "common/routing";
import { AuthContext } from "../../../Authorization/AuthContext";
import { RESOURCE_NAME } from "common/authorization";

const Records = () => {
  const history = useHistory();
  const { authorized } = useContext(AuthContext);

  const canAddSubmissionType = authorized({
    resource: RESOURCE_NAME.SUBMISSION_TYPE,
    permission: "create",
  });

  return (
    <Box>
      <Header accountSettings>
        <HeaderTitle accountSettings>Records</HeaderTitle>
      </Header>
      <div style={{ padding: "8px 24px 24px" }}>
        <FlexRow style={{ justifyContent: "space-between" }}>
          <SectionTitle>Submission types</SectionTitle>
          <Button
            styleVariant="outlineLight"
            size="small"
            leftIconName="plus"
            onClick={() => {
              history.push({
                pathname: buildLink("submissionsBuilder"),
                state: {
                  prevLocation: history.location.pathname,
                },
              });
            }}
            disabled={!canAddSubmissionType}
          >
            Add submission type
          </Button>
        </FlexRow>
        <HelperText>
          Add and/or edit submission types for internal use.
        </HelperText>
        <SubmissionTypesTable />
      </div>
    </Box>
  );
};

export default Records;
