import React from "react";
import FieldsList from "./FieldsList";
import ModulesList from "./ModulesList";
import { useFormContext } from "react-hook-form";
import { SubmissionsBuilderFormDataStructure } from "../types";

export const FieldsTab = () => {
  const {
    formState: { defaultValues },
  } = useFormContext<SubmissionsBuilderFormDataStructure>();

  return (
    <>
      <FieldsList />
      {!defaultValues?.inputs?.length && <ModulesList />}
    </>
  );
};
