import React from "react";
import { buildLink } from "common/routing";
import { useHistory } from "react-router";
import { OperationsMenuButton } from "./Button";
import MenuOption from "./MenuOption";

const BulkPropertyActionMenu = () => {
  const history = useHistory();

  return (
    <MenuOption
      title="Bulk property actions"
      helperText="Upload a CSV and dispatch a bulk property operation using the CSV as input data."
      buttons={
        <OperationsMenuButton
          icon="plus"
          text="Create"
          onClick={() =>
            history.push({
              pathname: buildLink("dispatchBulkPropertyCreation"),
            })
          }
        />
      }
    />
  );
};

export default BulkPropertyActionMenu;
