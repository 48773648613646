import React, { useContext } from "react";
import Parcels from "./Parcels";
import { MenuItems, SubTabMenu } from "../SubTabMenu";
import Firms from "./FIRMs";
import { AuthContext } from "../../Authorization/AuthContext";
import { RESOURCE_NAME } from "common/authorization";
import RepetitiveLossData from "./RepetitiveLossImports";
import { Box } from "../../Common/__styles__/Layout";
import { InsufficientPermissions } from "../../Common/InsufficientPermissions";
import DocumentTemplates from "./DocumentTemplates";
import Operations from "./Operations";
import BaseMaps from "./BaseMaps";
import Rasters from "./Rasters";
import { ElevationCertificates } from "./ElevationCertificates";

export interface DataImportsMenuProps {
  canViewParcelsSubTab: boolean;
  canViewFirmsSubTab: boolean;
}

const InsufficientPermissionsWrapper = () => {
  return (
    <Box>
      <InsufficientPermissions />
    </Box>
  );
};

export const DataImports = () => {
  const { authorized } = useContext(AuthContext);

  const canViewParcelsSubTab = authorized({
    resource: RESOURCE_NAME.PARCEL_IMPORT_CONFIGURATION,
    permission: "view",
  });

  const canViewFirmsSubTab = authorized({
    resource: RESOURCE_NAME.FIRM,
    permission: "view_settings",
  });

  const canViewRepetitiveLossImportsSubTab = authorized({
    resource: RESOURCE_NAME.REPETITIVE_LOSS_IMPORT_JOB,
    permission: "list",
  });

  const menuItems: MenuItems = [
    {
      name: "Parcels",
      path: "/parcels",
      component: canViewParcelsSubTab
        ? Parcels
        : InsufficientPermissionsWrapper,
    },
    {
      name: "FIRMs",
      path: "/firms",
      component: canViewFirmsSubTab ? Firms : InsufficientPermissionsWrapper,
    },
    {
      name: "Templates",
      path: "/document-templates",
      component: DocumentTemplates,
    },
    {
      name: "Elevation Certificates",
      path: "/elevation-certificates",
      component: ElevationCertificates,
    },
    {
      name: "Base maps",
      path: "/base-maps",
      component: BaseMaps,
    },
    {
      name: "Rasters",
      path: "/rasters",
      component: Rasters,
    },
    {
      name: "Operations",
      path: "/property-operations",
      component: Operations,
    },
  ];

  if (canViewRepetitiveLossImportsSubTab) {
    menuItems.splice(2, 0, {
      name: "Repetitive loss",
      path: "/repetitive-loss",
      component: RepetitiveLossData,
    });
  }

  return <SubTabMenu menuItems={menuItems} />;
};
