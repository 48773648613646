import React, { useContext } from "react";
import { useHistory } from "react-router";
import { ActionsProps } from "../Inputs/DropdownMenu";
import {
  ADDRESS_PANEL_TAB_NAME,
  buildLink,
  pipLink,
  RECORDS_TAB_NAME,
} from "common/routing";
import { AuthContext } from "../Authorization/AuthContext";
import { RESOURCE_NAME } from "common/authorization";
import { useDeleteModal } from "../Common/DeleteModal";
import { useDeleteInfoRequestLogMutation } from "../../generated/graphql";
import { InformationRequestFromList } from "./queries";

import { TrashIcon } from "./__styles__/ActionButton";
import { ActionCell } from "../Common/ActionCell";
import { useStatusToasts } from "../../hooks/useStatusToasts";

export const ActionButton = ({
  isDetailView,
  infoRequest,
  prevLocation,
  onUpdate,
}: {
  infoRequest: {
    id: string;
    property?: Maybe<{
      fullAddress?: Maybe<string>;
      id: string;
      latitude?: Maybe<number>;
      longitude?: Maybe<number>;
    }>;
  };
  isDetailView: boolean;
  prevLocation?: string;
  onUpdate?: () => void;
}) => {
  const { authorized } = useContext(AuthContext);
  const history = useHistory();
  const { addSuccessToast, addErrorToast } = useStatusToasts();

  const [deleteLog] = useDeleteInfoRequestLogMutation({
    onCompleted: data => {
      if (data.deleteInformationRequest) {
        onUpdate?.();
        addSuccessToast("Log entry was successfully deleted");

        history.push(prevLocation ?? "/map");
      } else {
        addErrorToast(
          "There was an issue deleting this log. Please try again. If the problem persists, please email us at support@withforerunner.com"
        );
      }
    },
    onError: () => {
      hideDeleteLogModal();
      addErrorToast(
        "There was an issue deleting this log. Please try again. If the problem persists, please email us at support@withforerunner.com"
      );
    },
    update: cache => {
      // please see this GH issue as to why we're writing the cache this way:
      // https://github.com/apollographql/apollo-client/issues/6451
      cache.modify({
        fields: {
          informationRequests(
            requests: Array<InformationRequestFromList>,
            { readField }
          ) {
            return requests.filter(
              request => readField("id", request) !== infoRequest.id
            );
          },
        },
      });
    },
  });

  const onDelete = async () => {
    await deleteLog({ variables: { id: infoRequest.id } });
  };

  const [showDeleteLogModal, hideDeleteLogModal] = useDeleteModal({
    width: "narrow",
    headerText: "Delete Log",
    Content: (
      <>
        <TrashIcon />
        <p style={{ textAlign: "center" }}>
          Are you certain that you would like to delete this log?
          <br />
          Warning: this action is irreversible
        </p>
      </>
    ),
    deleteButtonText: "Delete Log",
    onDelete,
  });

  const canDeleteLog = authorized({
    resource: RESOURCE_NAME.INFORMATION_REQUEST,
    permission: "delete",
  });

  const actions: Array<ActionsProps> = [];
  if (!isDetailView) {
    actions.push({
      label: "View",
      to: {
        pathname: buildLink("editLog", {
          id: infoRequest.id,
        }),
        state: {
          prevLocation,
        },
      },
    });
  }
  if (infoRequest.property) {
    actions.push({
      label: "View on map",
      to: pipLink({
        address: infoRequest.property.fullAddress,
        propertyId: infoRequest.property.id,
        lat: infoRequest.property.latitude?.toString() ?? "",
        lng: infoRequest.property.longitude?.toString() ?? "",
        tab: ADDRESS_PANEL_TAB_NAME.RECORDS,
        recordTab: RECORDS_TAB_NAME.LOGS,
      }),
    });
  }
  actions.push({
    label: "Delete",
    variant: "red",
    onClick: () => {
      showDeleteLogModal();
    },
    closeOnClick: false,
    disabled: !canDeleteLog,
  });

  return (
    <ActionCell
      actions={actions}
      buttonText={isDetailView ? "Actions" : undefined}
    />
  );
};
