import React, { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";

import ArcGISParcelImportConfigStage from "./ArcGISParcelImportConfigStage";
import { ParcelImportWizardContext } from "./context";
import { ArcGISImportWizardFormData } from "./types";
import {
  useGetArcGISMappingFieldOptions,
  useParcelImportConfigDefaultValues,
  useParcelImportFormSubmission,
} from "./hooks";
import ParcelImportWizard from "./ParcelImportWizard";
import { useStatusToasts } from "../../../../../hooks/useStatusToasts";
import { useHistory } from "react-router";

const FAILED_URL_REQUEST_MESSAGE =
  "There was a problem with your connection URL. Please try again.";

const ArcGISParcelImportFormManager = () => {
  const history = useHistory();

  const { setFieldOptions, workflow } = useContext(ParcelImportWizardContext);

  const { addSuccessToast, addErrorToast } = useStatusToasts();

  const [fetchDefaultValues] =
    useParcelImportConfigDefaultValues<ArcGISImportWizardFormData>({
      workflow,
    });

  const [fetchMappingFieldOptions, { loading: fieldOptionsAreLoading }] =
    useGetArcGISMappingFieldOptions();

  const [submitForm] = useParcelImportFormSubmission();

  const formMethods = useForm<ArcGISImportWizardFormData>({
    // for cases where we need to retrieve default values from
    // the server, react-hook-form allows "defaultValues" to
    // be an async function that returns a promise. Otherwise
    // everything would be "undefined" as a default value
    defaultValues: fetchDefaultValues,
    // this is set to "onChange" because we want to disable the
    // "Next", or submission button, if the form is invalid
    mode: "onChange",
  });

  // destructuring form methods and form state properties directly
  // acts as a way to "subscribe" to when these properties change
  const {
    getValues,
    formState: {
      isLoading: formIsLoading,
      isValid,
      isSubmitSuccessful,
      isSubmitting,
      dirtyFields,
    },
    handleSubmit,
    setError,
  } = formMethods;

  const loading = formIsLoading || fieldOptionsAreLoading || isSubmitting;
  const enableNextBtn = isValid && !loading && !isSubmitSuccessful;

  const onFirstStageNext = async (changeStage: () => void) => {
    try {
      const url = getValues("url");
      await fetchMappingFieldOptions({
        url,
        onCompleted: ({ availableFields, parcelModelFields }) => {
          setFieldOptions({ availableFields, parcelModelFields });
        },
      });
      changeStage();
    } catch (e) {
      setError("url", { message: FAILED_URL_REQUEST_MESSAGE });
    }
  };

  const onLastStageFinish = async () => {
    await handleSubmit(async formData => {
      await submitForm({
        dirtyFields,
        formData,
        onCompleted: () => {
          history.push("/settings/data-imports/parcels");
          addSuccessToast("Parcel source successfully updated.");
        },
        onError: () => {
          addErrorToast("Parcel source failed to update.");
        },
      });
    })();
  };

  return (
    <FormProvider {...formMethods}>
      <ParcelImportWizard
        firstStageRender={() => <ArcGISParcelImportConfigStage />}
        isStageComplete={() => enableNextBtn}
        loading={loading}
        onFirstStageNext={onFirstStageNext}
        onLastStageFinish={onLastStageFinish}
        skipFirstStage={false}
        subtitle="Import ArcGIS rest service"
      />
    </FormProvider>
  );
};

export default ArcGISParcelImportFormManager;
