import React from "react";
import Banner from "../Common/Banner";
import { Header, Section } from "../Common/__styles__/FullPageFormLayout";

export default ({
  header,
  warnings,
  style,
  children,
}: {
  header?: Maybe<string>;
  warnings: Array<string>;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}) => (
  <>
    {header && (
      <Header>
        <h1>{header}</h1>
      </Header>
    )}
    <Section data-testid="property-warnings">
      {warnings.map(text => {
        const warning = text !== "None";
        return (
          <Banner compact={true} key={text} style={style} warning={warning}>
            {text}
          </Banner>
        );
      })}
      {children}
    </Section>
  </>
);
