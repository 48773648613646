import React from "react";
import {
  Page,
  LayoutTitleRow,
  LayoutTitle,
} from "./__styles__/FullWidthLayout";

export const Layout = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => {
  return (
    <Page>
      <LayoutTitleRow>
        <LayoutTitle>{title}</LayoutTitle>
      </LayoutTitleRow>
      {children}
    </Page>
  );
};
