import React from "react";
import {
  ActionContainer,
  IssueCount,
  QueueButton,
} from "./__styles__/GeocodeRepetitiveLoss";

export const QueueActions = ({
  disabled,
  issueCount,
  onIgnore,
  onSave,
  onSaveAndContinue,
  onSkip,
  loading,
}: {
  disabled: boolean;
  issueCount: number;
  onIgnore: () => void;
  onSave: () => void;
  onSaveAndContinue: () => void;
  onSkip: () => void;
  loading: boolean;
}) => {
  return (
    <ActionContainer>
      <IssueCount>{issueCount} issues remaining</IssueCount>
      <QueueButton
        hideOnSmall
        size="small"
        onClick={onIgnore}
        disabled={loading}
        styleVariant="outlineLight"
      >
        Ignore
      </QueueButton>
      {issueCount > 0 && (
        <QueueButton
          hideOnSmall
          size="small"
          onClick={onSkip}
          disabled={loading}
          styleVariant="outlineLight"
        >
          Skip
        </QueueButton>
      )}
      <QueueButton
        size="small"
        onClick={onSave}
        disabled={disabled || loading}
        styleVariant="primary"
      >
        Save
      </QueueButton>
      {issueCount > 0 && (
        <QueueButton
          size="small"
          onClick={onSaveAndContinue}
          disabled={disabled || loading}
          styleVariant="primary"
        >
          Save, and go to next issue
        </QueueButton>
      )}
    </ActionContainer>
  );
};

export const Actions = ({
  disabled,
  onSave,
  loading,
}: {
  disabled: boolean;
  onSave: () => void;
  loading: boolean;
}) => {
  return (
    <ActionContainer>
      <QueueButton
        size="small"
        onClick={onSave}
        disabled={disabled || loading}
        styleVariant="primary"
      >
        Save
      </QueueButton>
    </ActionContainer>
  );
};
