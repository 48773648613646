import React from "react";
import { Button } from "../Button";
import {
  ErrorStateWrapper,
  Header,
  Message,
  Wrapper,
} from "./__styles__/TimeoutErrorState";

export const TimeoutErrorState = ({ refetch }: { refetch: () => void }) => (
  <Wrapper>
    <ErrorStateWrapper>
      <Header>Issue loading table data</Header>
      <Message>
        Our engineering team has been alerted and is working on it. Please
        adjust your filters or click the button below to retry. If the issue
        persists, contact us at support@withforerunner.com.
      </Message>
      <Button styleVariant="outlineLight" onClick={refetch} size="medium">
        Try again
      </Button>
    </ErrorStateWrapper>
  </Wrapper>
);
