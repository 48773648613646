import React from "react";
import { CommentWrapper, TextWrapper } from "./__styles__/Comment";

export interface CommentProps {
  content: string;
  truncate: boolean;
}

const Comment = ({ content, truncate }: CommentProps) => {
  return (
    <CommentWrapper>
      <TextWrapper truncate={truncate}>{content}</TextWrapper>
    </CommentWrapper>
  );
};

export default Comment;
