import React, { useContext, useEffect } from "react";
import { Map } from "./Map";
import { LayerContextProvider } from "./layers";
import { useMapsForAccountQuery } from "../../generated/graphql";
import { InternalMapContextProvider } from "./InternalMapContextProvider";
import { useHistory, useLocation } from "react-router";
import { omit } from "lodash";
import { AuthContext } from "../Authorization/AuthContext";

export default () => {
  const { loading, error, data } = useMapsForAccountQuery({
    variables: { filterHidden: false, isGuest: false },
  });

  const defaultState = useDefaultState();

  if (loading || error || !data?.account?.bounds) {
    return <div />;
  }

  return (
    <LayerContextProvider account={data.account} defaultState={defaultState}>
      <InternalMapContextProvider>
        <Map />
      </InternalMapContextProvider>
    </LayerContextProvider>
  );
};

const useDefaultState = () => {
  const { account } = useContext(AuthContext);
  const location = useLocation<Maybe<{ savedViewId?: string }>>();
  const history = useHistory();

  const savedViewId = location.state?.savedViewId;

  const defaultDocumentState: Record<string, boolean> =
    account?.accountDocumentTypes.reduce((acc, type, index) => {
      acc[type.id] = savedViewId ? false : index === 0;
      return acc;
    }, {} as Record<string, boolean>) ?? {};

  const defaultState = {
    documents: defaultDocumentState,
    ...(savedViewId && { savedViews: { [savedViewId]: true } }),
  };

  // useEffect runs on a different thread/tick than the initial render, so we can confidently
  // grab a value from location.state before it gets removed in the useEffect
  useEffect(() => {
    if (location.state?.savedViewId) {
      history.replace({ state: omit(location.state, "savedViewId") });
    }
  }, []);

  return defaultState;
};
