import React from "react";
import { useFormContext } from "react-hook-form";
import { Checkbox, Text } from "../../../../../../Inputs/react-hook-form";
import { SubmissionsBuilderFormDataStructure } from "../../types";
import { SelectedSubmissionsBuilderField } from "../../reducer";
import {
  CheckboxWrapper,
  WidgetSettingsContainer,
} from "./__styles__/WidgetSettings";
import { Label } from "../../../../../../Inputs";

const CheckboxWidgetSettings = ({
  fieldPath,
  isModuleInput,
}: Exclude<SelectedSubmissionsBuilderField, null> & {
  isModuleInput: boolean;
}) => {
  const { register } = useFormContext<SubmissionsBuilderFormDataStructure>();

  return (
    <WidgetSettingsContainer>
      <div>
        <Label text={"Field label"} />
        <Text {...register(`${fieldPath}.title`)} />
      </div>
      <CheckboxWrapper>
        <Checkbox
          {...register(`${fieldPath}.required`)}
          id={`${fieldPath}.required`}
          disabled={isModuleInput}
        />
        <Label
          text={"Required field"}
          htmlFor={`${fieldPath}.required`}
          disabled={isModuleInput}
        />
      </CheckboxWrapper>
      <div>
        <Label text={"Tooltip text"} />
        <Text {...register(`${fieldPath}.tooltipText`)} />
      </div>
      <div>
        <Text
          {...register(`${fieldPath}.checkboxText`)}
          label="Checkbox text"
          required
        />
      </div>
    </WidgetSettingsContainer>
  );
};

CheckboxWidgetSettings.displayName = "CheckboxWidgetSettings";

export default CheckboxWidgetSettings;
