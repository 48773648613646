import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useExportLogsMutation } from "../../generated/graphql";
import { track } from "../../utils/tracking";
import { DateRange, Label } from "../Inputs";
import { Button } from "../Common/Button";
import ExportSuccess from "./ExportSuccess";
import {
  ButtonSection,
  Container,
  ContentSection,
  HeaderSection,
  PrimaryButtons,
} from "../Common/__styles__/Modal";

type ExportLogFormStructure = {
  dateRange: {
    startDate: Maybe<Date>;
    endDate: Maybe<Date>;
  };
};

export default ({ closeModal }: { closeModal: () => void }) => {
  const { control, handleSubmit } = useForm<ExportLogFormStructure>({
    defaultValues: { dateRange: { startDate: null, endDate: null } },
  });

  const [exportRequested, setExportRequested] = useState(false);
  const [exportLogs, { loading }] = useExportLogsMutation({
    onCompleted: data => {
      if (data.exportLogs) {
        track("Requested Logs Export");
        setExportRequested(true);
      }
    },
  });

  if (exportRequested) {
    return <ExportSuccess closeModal={closeModal} />;
  }

  return (
    <Container width="narrow" overflows>
      <HeaderSection>
        <h1>Export logs</h1>
        <h2>
          Select the date range for your export. The result will be emailed to
          you once the export is complete.
        </h2>
      </HeaderSection>
      <ContentSection overflows>
        <Label text="Date range" />
        <Controller
          control={control}
          name="dateRange"
          render={({ field }) => (
            <DateRange onChange={field.onChange} value={field.value} />
          )}
        />
      </ContentSection>
      <ButtonSection>
        <PrimaryButtons>
          <Button onClick={closeModal} size="medium" styleVariant="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit(async (data: ExportLogFormStructure) => {
              await exportLogs({ variables: { ...data.dateRange } });
            })}
            styleVariant="primary"
            size="medium"
            disabled={loading}
          >
            Export
          </Button>
        </PrimaryButtons>
      </ButtonSection>
    </Container>
  );
};
