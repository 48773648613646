import React from "react";

import BaseFieldPreview from "./BaseFieldPreview";
import { NumberInput } from "../../../../../../Submissions/Fields";
import { FieldProps } from "@rjsf/utils";

const NumberFieldPreview = (props: FieldProps) => {
  const widgetPreview = <NumberInput {...props} />;
  return (
    <BaseFieldPreview
      widgetPreview={widgetPreview}
      {...props}
    ></BaseFieldPreview>
  );
};

export default NumberFieldPreview;
