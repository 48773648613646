import React, { useContext } from "react";
import { RESOURCE_NAME } from "common/authorization";
import { AuthContext } from "../../../Authorization/AuthContext";
import { FORM_STAGES } from "./CreateOrUpdateForm";
import { useCreateAccountDocumentTemplateMutation } from "../../../../generated/graphql";

import MappingForm from "./MappingForm";

const MappingCreateStep = ({
  setFormStage,
}: {
  setFormStage: (formStage: FORM_STAGES) => void;
}) => {
  const { authorized } = useContext(AuthContext);

  const canCreateDocumentTemplate = authorized({
    resource: RESOURCE_NAME.DOCUMENT_TEMPLATE,
    permission: "create",
  });

  const [createAccountDocumentTemplate, { loading }] =
    useCreateAccountDocumentTemplateMutation();

  return (
    <MappingForm
      subtitle="Create document template"
      backButtonText="Back"
      actionButtonText="Save"
      loadingAction={loading}
      canDoAction={canCreateDocumentTemplate}
      onBack={() => setFormStage(FORM_STAGES.UPLOAD)}
      onSubmit={createAccountDocumentTemplate}
      type="create"
    />
  );
};

export default MappingCreateStep;
