import React from "react";
import { useFormContext } from "react-hook-form";

import { Text } from "../../../../../Inputs/react-hook-form";
import { ParcelImportFieldConfig, ParcelImportWizardFormData } from "../types";
import { useFieldError } from "./hooks";

const ImportFieldSourceText = ({
  disabled,
  fieldConfig,
  fieldName,
}: {
  disabled: boolean;
  fieldConfig: ParcelImportFieldConfig;
  fieldName: `mapping.${string}.source`;
}) => {
  const { register } = useFormContext<ParcelImportWizardFormData>();
  const fieldError = useFieldError({ fieldName });

  return (
    <Text
      style={{ height: "38px" }}
      {...register(fieldName, {
        required: fieldConfig.required && "This field is required.",
      })}
      error={fieldError}
      placeholder="Enter value"
      disabled={disabled}
    />
  );
};

export default ImportFieldSourceText;
