import { QueryDescription } from "common/utils/queryBuilder";
import { createContext } from "react";
import { SavedView } from "../../../generated/graphql";

type TableContextType = {
  name: string;
  currentQuery?: QueryDescription;
  currentView?: SavedView;
  defaultView?: SavedView;
};
export const TableContext = createContext<TableContextType>({
  name: "",
});
