import React from "react";
import DropdownMenu, { ActionsProps } from "../../../Inputs/DropdownMenu";

export interface ActionButtonProps {
  importJobId: string;
  disabled?: boolean;
}

const ActionButton = ({ importJobId, disabled = true }: ActionButtonProps) => {
  const actions: Array<ActionsProps> = [];

  const csvDownloadHref = `/api/repetitive-loss-import/${importJobId}/repetitive-loss-data`;

  actions.push({
    label: "Download original .csv",
    download: true,
    href: csvDownloadHref,
    disabled,
  });

  return <DropdownMenu actions={actions} />;
};

export default ActionButton;
