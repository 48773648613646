import React from "react";
import { useDeleteActivityMutation } from "../../../generated/graphql";
import {
  ButtonSection,
  Container,
  ContentSection,
  HeaderSection,
  PrimaryButtons,
} from "../../Common/__styles__/Modal";
import { Icon } from "../../DocumentUploads/__styles__/DeleteConfirmation";
import { Button } from "../../Common/Button";

type DeleteActivityConfirmationProps = {
  activityId: string;
  closeModal: () => void;
  onUpdate: (deletedId?: string) => void;
};

export const DeleteConfirmation = ({
  activityId,
  closeModal,
  onUpdate,
}: DeleteActivityConfirmationProps) => {
  const [deleteActivity, { loading }] = useDeleteActivityMutation({
    variables: {
      activityId,
    },
    onCompleted: () => {
      onUpdate(activityId);
      closeModal();
    },
  });

  return (
    <Container width={"narrow"}>
      <HeaderSection>
        <h1>Delete activity</h1>
      </HeaderSection>
      <ContentSection style={{ textAlign: "center" }}>
        <Icon />
        <p>
          Are you certain you would like to delete this activity?
          <br />
          Warning: this action is irreversible
        </p>
      </ContentSection>
      <ButtonSection>
        <PrimaryButtons>
          <Button styleVariant="secondary" onClick={closeModal} size="medium">
            Cancel
          </Button>
          <Button
            size="medium"
            styleVariant="alert"
            onClick={() => deleteActivity({ variables: { activityId } })}
            disabled={loading}
          >
            Delete
          </Button>
        </PrimaryButtons>
      </ButtonSection>
    </Container>
  );
};
