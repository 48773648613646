import React, { useContext } from "react";
import Select from "../../../../../Inputs/Select";
import useFilterBuilderState from "../useFilterBuilderState";
import { StringFilter as GQLStringFilterType } from "../../../../../../generated/graphql";
import { SelectFilterWrapper } from "../__styles__/FilterInputs";
import { SISDFilterOptionValue } from "common/utils/rules/types";
import { FilterTarget } from "../types";
import { FilterContext } from "../../FilterContext";

type SelectFilterProps = {
  onFilterInputValuesChange: ReturnType<
    typeof useFilterBuilderState
  >["changeFilterInputsValues"];
  values: Partial<Omit<GQLStringFilterType, "__typename">>;
  filterTarget: Maybe<FilterTarget>;
};

const operatorOptions = [
  { value: true, label: "is" },
  { value: false, label: "is not" },
];

const SelectFilterInputs = ({
  onFilterInputValuesChange,
  values,
  filterTarget,
}: SelectFilterProps) => {
  const filterContext = useContext(FilterContext);
  const selectOptions = filterTarget
    ? filterContext.filterTargetMetadata[filterTarget]?.values ?? []
    : [];

  return (
    <SelectFilterWrapper>
      <Select
        name="filterConditional"
        onChange={value => {
          onFilterInputValuesChange({
            inclusion: value,
          });
        }}
        options={operatorOptions}
        label="Select conditional"
        placeholder="Select conditional"
        size="medium"
        value={values.inclusion}
      />

      <Select<string, SISDFilterOptionValue, true>
        name="filterValues"
        onChange={value => {
          onFilterInputValuesChange({
            values: value,
          });
        }}
        options={selectOptions}
        label="Select value"
        placeholder="Select value"
        size="medium"
        value={values.values}
        isMulti
      />
    </SelectFilterWrapper>
  );
};

export default SelectFilterInputs;
