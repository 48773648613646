import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { useForm } from "react-hook-form";

import { Button } from "../../Common/Button";
import FullPageBackgroundWithLogo from "../../Common/FullPageBackgroundWithLogo";
import { Text } from "../../Inputs/react-hook-form";
import FullPageLoadingScreen from "../../Common/FullPageLoadingScreen";
import { validatePassword } from "common/utils/validate-password";
import {
  useGetResetRequestQuery,
  useResetPasswordMutation,
} from "../../../generated/graphql";

import {
  ButtonRow,
  Container,
  Title,
} from "../../Common/__styles__/FormWithFullPageBackground";
import { validatePasswordsMatch } from "../../../utils/password-match";
import { useStatusToasts } from "../../../hooks/useStatusToasts";

const ExpiredRequestMessage = () => {
  return (
    <Container>
      <Title>This request has expired.</Title>
      <p>
        This password reset request has already expired. Please try resetting
        your password again.
      </p>
    </Container>
  );
};

const SuccessMessage = () => {
  return (
    <Container>
      <Title>Success!</Title>
      <p>
        You have successfully updated your password. You can now sign in using
        your new password.
      </p>
      <ButtonRow>
        <Button
          onClick={() => {
            window.location.href = "/login";
          }}
          size="medium"
          styleVariant="primary"
        >
          Log in
        </Button>
      </ButtonRow>
    </Container>
  );
};

interface FormStructure {
  password: string;
  confirmPassword: string;
}

export const ResetForm = ({
  minimumPasswordLength,
  requestId,
}: URLParams & { minimumPasswordLength: number }) => {
  const { addErrorToast } = useStatusToasts();

  const [completed, setCompleted] = React.useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    setError,
  } = useForm<FormStructure>({});

  const [resetPassword, { loading }] = useResetPasswordMutation({
    onCompleted: () => {
      setCompleted(true);
    },
    onError: error => {
      if (error.graphQLErrors[0]?.extensions?.code === "USER_ERROR") {
        setError("password", { message: error.message }, { shouldFocus: true });
      } else {
        addErrorToast(
          "There was an issue resetting your password. Please try again. If the problem persists, please email us at support@withforerunner.com"
        );
      }
    },
  });

  if (completed) {
    return <SuccessMessage />;
  }

  return (
    <form
      onSubmit={handleSubmit(async userProvidedData => {
        await resetPassword({
          variables: { requestId, password: userProvidedData.password },
        });
      })}
    >
      <Container>
        <Title>Pick a new password</Title>
        <div>
          <Text
            label="New password"
            type="password"
            data-testid="password"
            error={errors.password?.message}
            {...register("password", {
              required: "Required field",
              validate: (password: Maybe<string>) => {
                if (!password) return;
                return validatePassword({ password, minimumPasswordLength });
              },
            })}
          />
          <Text
            label="Confirm new password"
            type="password"
            data-testid="confirm-password"
            error={errors.confirmPassword?.message}
            {...register("confirmPassword", {
              required: "Required field",
              validate: (val: Maybe<string>) => {
                return validatePasswordsMatch(val, getValues("password"));
              },
            })}
          />
        </div>
        <ButtonRow>
          <Button
            styleVariant="primary"
            size="medium"
            onClick={handleSubmit(async userProvidedData => {
              await resetPassword({
                variables: { requestId, password: userProvidedData.password },
              });
            })}
            disabled={loading}
          >
            Save
          </Button>
        </ButtonRow>
      </Container>
    </form>
  );
};

interface URLParams {
  requestId: string;
}

export interface ResetFormProps extends RouteComponentProps<URLParams> {}

export default ({ match }: ResetFormProps) => {
  const requestId = match.params.requestId;

  const { data, loading, error } = useGetResetRequestQuery({
    variables: { requestId },
  });

  if (loading) return <FullPageLoadingScreen />;

  if (error || !data) {
    throw new Error(
      `Could not find password reset request with id: ${requestId}`
    );
  }

  const expired = data.passwordResetRequest === null;

  return (
    <FullPageBackgroundWithLogo>
      {expired ? (
        <ExpiredRequestMessage />
      ) : (
        <ResetForm
          requestId={requestId}
          minimumPasswordLength={
            data.passwordResetRequest!.user.account.securityConfiguration
              .minimumPasswordLength
          }
        />
      )}
    </FullPageBackgroundWithLogo>
  );
};
