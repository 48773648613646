import React from "react";

import { LOGS } from "./queries";
import { mapPropertyToRequest } from "common/services/informationRequestHelpers";
import { track } from "../../utils/tracking";

import LogMutationForm, { LogMutationFormStructure } from "./LogMutationForm";
import { RouteComponentProps, useHistory, useLocation } from "react-router";
import {
  InformationRequestInput,
  InformationRequestStatus,
  useCreateLogMutation,
  useGetPropertyForLogFormQuery,
} from "../../generated/graphql";
import { FetchPolicy } from "@apollo/client";
import { Button } from "../Common/Button";
import { FormProvider, useForm } from "react-hook-form";
import { formatDateString } from "../../utils/dates";
import { useStatusToasts } from "../../hooks/useStatusToasts";

const Container = ({
  defaultValues,
  propertyLoading,
}: {
  defaultValues: Partial<InformationRequestInput>;
  propertyLoading: boolean;
}) => {
  const { addSuccessToast, addErrorToast } = useStatusToasts();
  const history = useHistory();
  const location = useLocation<{ prevLocation: string }>();
  const methods = useForm<LogMutationFormStructure>({ defaultValues });
  const [create, { loading: createLoading }] = useCreateLogMutation({
    refetchQueries: () => [
      {
        query: LOGS,
      },
    ],
    onCompleted: () => {
      track("Created Log");

      addSuccessToast("Created successfully");

      history.push(location.state?.prevLocation ?? "/map");
    },
    onError: () => {
      addErrorToast("Oops! Encountered an error. Please try again");
    },
  });
  const onSubmit = async (data: LogMutationFormStructure) => {
    await create({ variables: { data } });
  };
  const rightContainer = (
    <div>
      <Button
        size="small"
        styleVariant="primary"
        onClick={methods.handleSubmit(onSubmit)}
        loading={createLoading}
      >
        Submit
      </Button>
    </div>
  );

  return (
    <FormProvider {...methods}>
      <LogMutationForm
        isNew
        data={defaultValues}
        propertyLoading={propertyLoading}
        prevLocation={location.state?.prevLocation}
        subtitle="New log"
        rightContainer={rightContainer}
      />
    </FormProvider>
  );
};

export default ({
  fetchPolicy = "cache-first",
  match,
}: {
  fetchPolicy?: FetchPolicy;
} & Pick<RouteComponentProps<{ propertyId: string }>, "match">) => {
  const { propertyId } = match.params;

  const { data: propertyData, loading: propertyLoading } =
    useGetPropertyForLogFormQuery({
      variables: { propertyId },
      fetchPolicy,
    });

  if (propertyLoading) return null;

  const property = propertyData?.getProperty;
  const firms = property?.firms || [];
  const effectiveFIRM = firms.find(firm => firm.type === "effective");

  const data = {
    address: property?.fullAddress ?? "",
    ...mapPropertyToRequest({
      property: property ?? null,
      firm: effectiveFIRM ?? null,
    }),
    submittedAt: formatDateString({
      format: "YYYY-MM-DD",
      dateString: new Date().toString(),
    }),
    status: InformationRequestStatus.OPEN,
  };

  return <Container defaultValues={data} propertyLoading={propertyLoading} />;
};
