import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import { useIdleTimer } from "react-idle-timer";
import {
  MixpanelCompany,
  MixpanelSession,
  CurrentUser,
  CurrentAdmin,
  Account,
  SecurityConfiguration,
} from "./types";
import { AuthContext } from "./AuthContext";
import {
  useCurrentAdminQuery,
  useCurrentUserQuery,
  useGetSecurityConfigurationQuery,
  useTouchSessionMutation,
} from "../../generated/graphql";
import { authorized, buildFeatureFlagChecker } from "common/authorization";
import FullPageLoadingScreen from "../Common/FullPageLoadingScreen";
import { buildLink } from "common/routing";
import {
  MINUTES_TO_MILLISECONDS,
  WEEK_IN_MILLISECONDS,
  YEAR_IN_MILLISECONDS,
} from "common/utils/dates";

export const _trackEntity = (entity?: Maybe<MixpanelSession>) => {
  if (!entity) return;

  const { account, isAdmin, id, email, createdAt, isBeingImpersonated } =
    entity;

  const name = `${entity.firstName} ${entity.lastName}`;
  Sentry.setUser({ id, email, name });

  let company: MixpanelCompany = {};
  if (account) {
    company.id = account.id;
    company.name = account.name;
  }

  const trackingData = {
    name,
    email,
    createdAt,
    isAdmin: isAdmin || false,
    company,
    isPilot: account?.isPilot,
    isBeingImpersonated,
  };

  window.analytics?.identify(id, trackingData);
};

const AuthRoute = <
  T extends { user?: CurrentUser; admin?: CurrentAdmin; account?: Account }
>({
  component: Component,
  ...props
}: T & { component: React.FC<T>; path: string }) => {
  const location = useLocation();
  const [currentUser, setUser] = React.useState<Maybe<CurrentUser>>(null);
  const [currentAdmin, setAdmin] = React.useState<Maybe<CurrentAdmin>>(null);
  const [sessionTimeout, setSessionTimeout] =
    React.useState<number>(YEAR_IN_MILLISECONDS);
  const [securityConfiguration, setSecurityConfiguration] = React.useState<
    SecurityConfiguration | undefined
  >(undefined);

  const { loading, data, error } = useCurrentUserQuery();

  const { loading: loadingAdmin, data: adminData } = useCurrentAdminQuery();

  useGetSecurityConfigurationQuery({
    skip: !currentAdmin && !currentUser,
    onCompleted: data => {
      if (data.account) {
        if (currentUser) {
          const sessionTimeoutMinutes =
            data.account.securityConfiguration.sessionTimeoutMinutes;
          if (sessionTimeoutMinutes) {
            setSessionTimeout(sessionTimeoutMinutes * MINUTES_TO_MILLISECONDS);
          }
        }
        setSecurityConfiguration(data.account.securityConfiguration);
      }
    },
  });

  React.useEffect(() => {
    if (adminData?.currentAdmin) {
      setAdmin(adminData.currentAdmin);
      _trackEntity({ ...adminData.currentAdmin, isAdmin: true });
      setSessionTimeout(WEEK_IN_MILLISECONDS);
    }
  }, [adminData]);

  React.useEffect(() => {
    if (data?.currentUser) {
      setUser(data.currentUser);
      _trackEntity(data.currentUser);
    }
  }, [data]);

  const [touchSessionMutation] = useTouchSessionMutation({
    ignoreResults: true, // We do this to avoid rerendering all of the child components of AuthRoute
  });

  const onIdle = () => {
    window.location.href = buildLink(
      "logout",
      {},
      { logoutReason: "inactivity" }
    );
  };

  const onAction = async () => {
    await touchSessionMutation();
  };

  useIdleTimer({
    onIdle,
    onAction,
    timeout: sessionTimeout,
    throttle: 30000,
  });

  if (loading || loadingAdmin) return <FullPageLoadingScreen />;
  if (error || (!data?.currentUser && !adminData?.currentAdmin)) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { referrer: location.pathname },
        }}
      />
    );
  }

  // there is a split second where the react state hook hasn't yet
  // assigned the currentUser to the state variable *but* the
  // graphql query has finished loading successfully. It would be possible
  // to use data.currentUser, except that sometimes the user is set
  // by something other than the above gql query
  const entity = currentUser || currentAdmin;
  if (!entity) return null;

  const account = currentUser?.account || currentAdmin?.account;
  const featureFlags = account?.featureFlags || [];

  return (
    <AuthContext.Provider
      value={{
        setAdmin,
        user: currentUser ?? null,
        account: account ? { ...account, securityConfiguration } : null,
        admin: currentAdmin ?? null,
        isGuest: false,
        authorized: authDetails => authorized({ entity, ...authDetails }),
        isFeatureEnabled: buildFeatureFlagChecker(featureFlags),
      }}
    >
      <Component
        {...(props as unknown as T)}
        user={currentUser}
        admin={currentAdmin}
        account={account}
      />
    </AuthContext.Provider>
  );
};

export default AuthRoute;
