import React, { useContext } from "react";

import DropdownMenu, { ActionsProps } from "../../../Inputs/DropdownMenu";
import { AuthContext } from "../../../Authorization/AuthContext";
import { RESOURCE_NAME } from "common/authorization";
import { useDeleteParcelImportConfigurationModal } from "./DeleteConfirmationModal";
import {
  ParcelImportConfiguration,
  ParcelImportTaskType,
  useHasParcelImportTaskInProgressQuery,
  useImportParcelDataMutation,
} from "../../../../generated/graphql";
import { useStatusToasts } from "../../../../hooks/useStatusToasts";
import { omit, pick } from "lodash";
import { buildLink } from "common/routing";

export interface ActionButtonProps {
  parcelImportConfiguration: ParcelImportConfiguration;
  disabled?: boolean;
  onUpdate: () => void;
}

const ActionButton = ({
  parcelImportConfiguration,
  disabled = false,
  onUpdate,
}: ActionButtonProps) => {
  const { authorized } = useContext(AuthContext);
  const { addErrorToast, addSuccessToast } = useStatusToasts();

  const { data } = useHasParcelImportTaskInProgressQuery();

  const hasParcelImportTaskInProgress = data?.hasParcelImportTaskInProgress;

  const [showDeleteParcelImportConfigurationModal] =
    useDeleteParcelImportConfigurationModal({
      parcelImportConfiguration,
      onUpdate,
    });

  const [importParcelData] = useImportParcelDataMutation({
    variables: {
      data: {
        ...pick(
          parcelImportConfiguration,
          "name",
          "url",
          "frequency",
          "mapping",
          "importType"
        ),
        fieldNameOverrides: parcelImportConfiguration.fieldNameOverrides.map(
          m => omit(m, "__typename")
        ),
      },
    },
    onCompleted: () => {
      addSuccessToast("Your parcel data import was scheduled successfully.");
    },
    onError: () => {
      addErrorToast("Your parcel data import failed while scheduling.");
    },
    refetchQueries: ["HasParcelImportTaskInProgress"],
  });

  const canEditParcelImportConfiguration =
    authorized({
      resource: RESOURCE_NAME.PARCEL_IMPORT_CONFIGURATION,
      permission: "update",
    }) && !hasParcelImportTaskInProgress;

  const canDeleteParcelImportConfiguration =
    authorized({
      resource: RESOURCE_NAME.PARCEL_IMPORT_CONFIGURATION,
      permission: "delete",
    }) && !hasParcelImportTaskInProgress;

  const actions: Array<ActionsProps> = [];

  if (parcelImportConfiguration.importType === ParcelImportTaskType.ARCGIS) {
    actions.push({
      label: "Edit settings",
      to: {
        pathname: buildLink("parcelImportConfigEdit", {
          importTaskType: ParcelImportTaskType.ARCGIS,
          importConfigurationId: parcelImportConfiguration.id,
        }),
      },
      disabled: !canEditParcelImportConfiguration,
    });

    actions.push({
      label: "Re-import",
      onClick: () => void importParcelData(),
      disabled:
        !canEditParcelImportConfiguration || hasParcelImportTaskInProgress,
    });

    actions.push({
      label: "Delete",
      onClick: () => showDeleteParcelImportConfigurationModal(),
      variant: "red",
      disabled: !canDeleteParcelImportConfiguration,
    });
  } else {
    actions.push({
      label: "Display settings",
      to: {
        pathname: buildLink("parcelImportConfigEdit", {
          importTaskType: ParcelImportTaskType.GPKG,
          importConfigurationId: parcelImportConfiguration.id,
        }),
      },
      disabled: !canEditParcelImportConfiguration,
    });
  }

  return <DropdownMenu disabled={disabled} actions={actions} />;
};

export default ActionButton;
