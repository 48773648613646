import { Button } from "../../../Common/Button";
import { IconNames } from "../../../Common/Icons/LucideIcons";
import React from "react";

export const OperationsMenuButton = ({
  text,
  onClick,
  icon,
}: {
  text: string;
  onClick: () => void;
  icon: IconNames;
}) => (
  <Button
    leftIconName={icon}
    styleVariant="outlineLight"
    size="small"
    onClick={onClick}
  >
    {text}
  </Button>
);

export default OperationsMenuButton;
