import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { useModal } from "react-modal-hook";
import { QueryDescription } from "common/utils/queryBuilder";

import Modal from "../../../Modal";
import { Button } from "../../../Button";
import {
  TableType,
  useCreateSavedViewMutation,
} from "../../../../../generated/graphql";
import { useStatusToasts } from "../../../../../hooks/useStatusToasts";
import { Text } from "../../../../Inputs/react-hook-form";
import { TableContext } from "../../TableContext";
import { track } from "../../../../../utils/tracking";
import { useUpdateSavedView } from "../../utils";

import {
  ButtonSection,
  Container,
  ContentSection,
  FormSection,
  HeaderSection,
  InputRow,
  PrimaryButtons,
} from "../../../__styles__/Modal";
import { AuthContext } from "../../../../Authorization/AuthContext";

interface CreateSavedViewForm {
  name: string;
}

const CreateSavedViewModal = ({
  query,
  tableType,
  hideModal,
  onSave,
}: {
  query?: QueryDescription;
  tableType?: TableType;
  hideModal: () => void;
  onSave: () => void;
}) => {
  const { updateSavedView } = useUpdateSavedView();
  const { addSuccessToast, addErrorToast } = useStatusToasts();
  const { account } = useContext(AuthContext);

  const [createSavedView, { loading }] = useCreateSavedViewMutation({
    onCompleted: data => {
      onSave();
      hideModal();
      updateSavedView(data.createSavedView.id);
      addSuccessToast(`Your table view was successfully created`);
    },
    refetchQueries: ["GetSavedViews"],
    awaitRefetchQueries: true,
    onError: () => {
      addErrorToast(
        `There was an issue creating your table view. Please try again. If the problem persists, please email us at support@withforerunner.com`
      );
    },
    // On create, we need the cache updated so map can display
    // the saved view without a refresh
    update: (cache, { data }) => {
      cache.modify({
        id: cache.identify(account!),
        fields: {
          savedViews(existingSavedViews = []) {
            return [...existingSavedViews, data?.createSavedView];
          },
        },
      });
    },
  });

  const {
    handleSubmit,
    formState: { errors, isValid },
    register,
  } = useForm<CreateSavedViewForm>();

  if (!tableType || !query) {
    return null;
  }

  const onSubmit = async (formData: CreateSavedViewForm) => {
    const data = {
      ...formData,
      tableType,
      query,
    };

    track("Created table view", {
      tableType,
      name: data.name,
    });

    await createSavedView({ variables: { data } });
  };

  return (
    <Container>
      <HeaderSection>
        <h1>Create new view</h1>
      </HeaderSection>
      <FormSection>
        <ContentSection>
          <p>
            Table views are visible to everyone and can only be edited by the
            person that created it. If filters or columns that reference
            sensitive data are present, only users with permissions will be able
            to see this view.
          </p>
          <InputRow>
            <Text
              {...register("name", { required: true })}
              error={errors.name?.message}
              size="small"
              label="Name"
              required
            />
          </InputRow>
        </ContentSection>
        <ButtonSection>
          <PrimaryButtons>
            <Button styleVariant="secondary" onClick={hideModal} size="medium">
              Cancel
            </Button>
            <Button
              size="medium"
              styleVariant="primary"
              onClick={handleSubmit(onSubmit)}
              disabled={!isValid || loading}
            >
              Create view
            </Button>
          </PrimaryButtons>
        </ButtonSection>
      </FormSection>
    </Container>
  );
};

export const useCreateSavedViewModal = ({ onSave }: { onSave: () => void }) => {
  const { currentQuery, currentView } = useContext(TableContext);

  let [showModal, hideModal] = useModal(
    () => (
      <Modal onRequestClose={hideModal}>
        <CreateSavedViewModal
          hideModal={hideModal}
          query={currentQuery}
          tableType={currentView?.tableType}
          onSave={onSave}
        />
      </Modal>
    ),
    [currentQuery, currentView]
  );

  return [showModal, hideModal] as const;
};
