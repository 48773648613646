import React from "react";
import { Link } from "./__styles__/LinkCell";

const LinkCell = ({
  href,
  label = "Link",
  target,
}: {
  href: string;
  label?: string;
  target?: string;
}) => {
  return (
    <Link href={href} target={target} tabIndex={0}>
      {label}
    </Link>
  );
};

export default LinkCell;
