import React, { useContext } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import { makeAdditionalFieldConfig } from "../utils";
import {
  PARCEL_IMPORT_CONFIG_WORKFLOW,
  ParcelImportWizardFormData,
} from "../types";
import { Icon } from "../../../../../Common/Icons/LucideIcons";
import { Button } from "../../../../../Common/Button";
import { AdditionalImportFieldRow } from "../ImportFieldRows";
import { AdditionalFieldsButtonContentWrapper } from "../__styles__/ParcelImportWizard";
import { ParcelImportWizardContext } from "../context";

const AdditionalFieldsSection = () => {
  const { workflow } = useContext(ParcelImportWizardContext);
  const { control } = useFormContext<ParcelImportWizardFormData>();
  const {
    fields: additionalFields,
    append,
    remove,
  } = useFieldArray<ParcelImportWizardFormData>({
    control,
    name: "additionalFields",
  });

  return (
    <>
      {additionalFields.map((field, fieldIndex) => {
        const fieldConfig = makeAdditionalFieldConfig();

        return (
          <AdditionalImportFieldRow
            key={field.id}
            fieldConfig={fieldConfig}
            fieldIndex={fieldIndex}
            removeField={remove}
          />
        );
      })}
      {workflow === PARCEL_IMPORT_CONFIG_WORKFLOW.CREATE && (
        <Button
          styleVariant="secondary"
          onClick={() =>
            append({ label: "", source: "", displayName: "", isPublic: false })
          }
          size="medium"
          style={{ marginTop: "24px" }}
        >
          <AdditionalFieldsButtonContentWrapper>
            <Icon iconName="plus" size={16} color="contentPrimary" />
            <span>Additional field</span>
          </AdditionalFieldsButtonContentWrapper>
        </Button>
      )}
    </>
  );
};

export default AdditionalFieldsSection;
