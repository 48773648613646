import React from "react";
import Banner from "../../Common/Banner";

export const InsufficientPermissionsBanner = () => {
  return (
    <div style={{ margin: "16px 0" }}>
      <Banner>
        You do not have permission to edit these account settings. Please reach
        out to your Team manager to make changes.
      </Banner>
    </div>
  );
};
