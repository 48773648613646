import React, { useContext } from "react";

import { SubmissionsBuilderContextInstance } from "../../context";
import { BuilderFieldWrapper } from "../../__styles__/SubmissionsBuilder";
import { getNameFromId, withStopPropagation } from "../../utils";
import FieldActions from "../FieldActions";
import { FieldProps } from "@rjsf/utils";

const BaseFieldPreview = (
  props: FieldProps & { widgetPreview: JSX.Element }
) => {
  const { fieldActionsPropsByPath, fieldContextsByName, selectedField } =
    useContext(SubmissionsBuilderContextInstance);

  const id = props.idSchema.$id;
  const name = getNameFromId(id);

  const fieldContext = fieldContextsByName[name];

  if (!fieldContext) {
    return null;
  }

  const fieldProps = fieldActionsPropsByPath[fieldContext.fieldPath];

  if (!fieldProps) {
    return null;
  }

  const { selectField } = fieldProps;
  const isSelected = selectedField?.name === props.name;
  return (
    <BuilderFieldWrapper
      onClick={withStopPropagation(selectField)}
      isSelected={isSelected}
    >
      <div style={{ marginBottom: "4px" }}>
        {React.cloneElement(props.widgetPreview, { isSubmissionBuilder: true })}
      </div>
      <FieldActions {...fieldProps} isSelected={isSelected} />
    </BuilderFieldWrapper>
  );
};

export default BaseFieldPreview;
