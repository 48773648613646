import React from "react";

import App from "./App";
import AdminApp from "./AdminApp";
import { User } from "common/authorization/customPolicies";
import { Account, Admin } from "../generated/graphql";

// AppSelector chooses between different App components based on
// the authenticated user's account configuration.
export default function AppSelector({
  ...props
}: {
  user?: Omit<User, "role">;
  admin?: Pick<Admin, "id">;
  account?: Pick<Account, "logoUrl" | "name"> & {
    publicPortal: Pick<Account["publicPortal"], "subdomain">;
    submissionTypes: Pick<Account["submissionTypes"][0], "category">[];
  };
}) {
  const { admin } = props;

  const SelectedApp = admin ? AdminApp : App;

  return <SelectedApp {...props} />;
}
