import React, { useContext } from "react";

import { getFieldConfigsToRender, isCastableField } from "./utils";
import ImportFieldRow from "./ImportFieldRows/ImportFieldRow";
import {
  ImportMappingHeaderColumnFour,
  ImportMappingHeaderColumnOne,
  ImportMappingHeaderColumnThree,
  ImportMappingHeaderColumnTwo,
  ImportMappingHeaderRow,
  ImportMappingStageWrapper,
} from "./__styles__/ParcelImportWizard";
import {
  AdditionalFieldsSection,
  CastableFieldSection,
} from "./ImportFieldSections";
import { ParcelImportWizardContext } from "./context";
import { useFormContext } from "react-hook-form";
import { ParcelImportWizardFormData } from "./types";

const ImportMappingStage = () => {
  const { canEditSourceFields, hasPublicPortal } = useContext(
    ParcelImportWizardContext
  );
  const { getValues } = useFormContext<ParcelImportWizardFormData>();

  const fieldConfigs = getFieldConfigsToRender(
    canEditSourceFields,
    hasPublicPortal,
    getValues("mapping")
  );

  return (
    <ImportMappingStageWrapper>
      <header>
        {canEditSourceFields ? (
          <>
            <h1>Field mapping</h1>
            <p className="subtitle">
              Map the fields in the rest service to Forerunner fields or enter
              freeform text to hard-code values.
            </p>
          </>
        ) : (
          <>
            <h1>Update Mapping Display Options</h1>
            <p className="subtitle">
              Update display options for previously mapped fields.
            </p>
          </>
        )}
      </header>
      <ImportMappingHeaderRow>
        <ImportMappingHeaderColumnOne>
          Forerunner Field Name
        </ImportMappingHeaderColumnOne>
        <ImportMappingHeaderColumnTwo>
          Parcel data source field name
        </ImportMappingHeaderColumnTwo>
        <ImportMappingHeaderColumnThree>
          Field display name
        </ImportMappingHeaderColumnThree>
        {hasPublicPortal && (
          <ImportMappingHeaderColumnFour>
            Show to Public
          </ImportMappingHeaderColumnFour>
        )}
      </ImportMappingHeaderRow>
      <div style={{ marginBottom: "24px" }}>
        {fieldConfigs.map(fieldConfig =>
          isCastableField(fieldConfig) ? (
            <CastableFieldSection
              key={fieldConfig.name}
              fieldConfig={fieldConfig}
            />
          ) : (
            <ImportFieldRow key={fieldConfig.name} fieldConfig={fieldConfig} />
          )
        )}
        <AdditionalFieldsSection />
      </div>
    </ImportMappingStageWrapper>
  );
};

export default ImportMappingStage;
