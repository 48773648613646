import React, { useState } from "react";
import { Link } from "react-router-dom";

import {
  PropertyCorrections,
  PropertyCorrectionsProps,
} from "../../../TranscriptionApp/Forms/PropertyCorrection";
import { Button } from "../../../../Common/Button";
import { FetchResult } from "@apollo/client";
import {
  AdminMergePropertyMutation,
  AdminUpdatePropertyMutation,
  GetPropertyForPropertyEditQuery,
} from "../../../../../generated/graphql";
import {
  Centered,
  MultiplePropertiesContainer,
  MultiplePropertiesList,
  StyledDocuments,
} from "./__styles__/EditProperty";

const Documents = ({
  documents,
}: {
  documents: PropertyEditFormProps["property"]["documentUploads"];
}) => {
  const Document = ({
    document,
    index,
  }: {
    document: PropertyEditFormProps["property"]["documentUploads"][number];
    index: number;
  }) => {
    return (
      <li>
        Document #{index + 1}:{" "}
        <a target="_blank" href={document.url}>
          {document.id}
        </a>
      </li>
    );
  };

  return (
    <StyledDocuments>
      {!documents?.length && <div>This property has no documents</div>}
      <ul>
        {documents.map((document, index) => (
          <Document key={document.id} document={document} index={index} />
        ))}
      </ul>
    </StyledDocuments>
  );
};

const MultipleProperties = ({
  properties,
  mergeProperty,
  processing,
}: {
  mergeProperty: PropertyEditFormProps["mergeProperty"];
  processing: PropertyEditFormProps["processing"];
  properties: NonNullable<
    PropertyEditFormProps["property"]["parcel"]
  >["properties"];
}) => {
  return (
    <MultiplePropertiesContainer>
      <div>
        This property is within a parcel with other properties! Click the merge
        button below to merge the chosen property in to this property.
      </div>
      <MultiplePropertiesList>
        {properties.map(property => {
          return (
            <div key={property.id}>
              <Link to={{ pathname: `/backoffice/properties/${property.id}` }}>
                {`${property.streetAddress}, ${property.city}, ${property.state}, ${property.zipcode} (${property.documentUploads.length} documents)`}{" "}
              </Link>
              <Button
                size="small"
                styleVariant="alert"
                disabled={processing}
                onClick={() => mergeProperty(property.id)}
              >
                ! Merge property !
              </Button>
              <Documents documents={property.documentUploads} />
            </div>
          );
        })}
      </MultiplePropertiesList>
    </MultiplePropertiesContainer>
  );
};

interface PropertyEditFormProps {
  get: PropertyCorrectionsProps["get"];
  set: PropertyCorrectionsProps["set"];
  account: PropertyCorrectionsProps["account"];
  property: NonNullable<GetPropertyForPropertyEditQuery["getProperty"]>;
  processing: boolean;
  saveProperty: () => Promise<FetchResult<AdminUpdatePropertyMutation>>;
  mergeProperty: (
    childId: string
  ) => Promise<FetchResult<AdminMergePropertyMutation>>;
}

export default ({
  set,
  get,
  account,
  property,
  processing,
  saveProperty,
  mergeProperty,
}: PropertyEditFormProps) => {
  const [loadingLocation, setLoadingLocation] = useState(false);
  const allProperties = property.parcel?.properties || [];
  const properties = allProperties.filter(
    otherProperty => otherProperty.id !== property?.id
  );

  const documents = property.documentUploads || [];

  return (
    <div>
      {properties.length > 0 && (
        <Centered>
          <MultipleProperties
            properties={properties}
            mergeProperty={mergeProperty}
            processing={processing}
          />
        </Centered>
      )}
      <Centered data-testid="documents">
        <h3>PROPERTY DOCUMENTS</h3>
        <Documents documents={documents} />
      </Centered>
      <PropertyCorrections
        set={set}
        get={get}
        account={account}
        setLoadingLocation={setLoadingLocation}
      />
      <Centered>
        <Button
          size="medium"
          styleVariant="primary"
          onClick={saveProperty}
          disabled={processing || loadingLocation}
        >
          Save Property
        </Button>
      </Centered>
    </div>
  );
};
