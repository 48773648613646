import { buildLink, pipLink } from "common/routing";
import React from "react";
import { useLocation } from "react-router";
import {
  GetRepetitiveLossesQuery,
  useToggleIssueIgnoredMutation,
} from "../../../../../generated/graphql";
import { ActionsProps } from "../../../../Inputs/DropdownMenu";
import { EmptyActionCell } from "./__styles__/RepetitiveLossTable";
import { ActionCell } from "../../../../Common/ActionCell";

type RepetitiveLossForTable = NonNullable<
  GetRepetitiveLossesQuery["account"]
>["repetitiveLosses"]["data"][number];

export interface ActionButtonProps {
  repetitiveLoss: RepetitiveLossForTable;
  disabled?: boolean;
}

const RepetitiveLossActionCell = ({
  repetitiveLoss,
}: {
  repetitiveLoss: RepetitiveLossForTable;
}): JSX.Element => {
  const actions: Array<ActionsProps> = [];
  const location = useLocation();
  const [toggleIssueIgnored] = useToggleIssueIgnoredMutation({
    refetchQueries: ["getRepetitiveLosses"],
  });

  if (repetitiveLoss.issue) {
    if (!repetitiveLoss.issueIgnored) {
      actions.push({
        label: "Review issue",
        to: {
          pathname: buildLink("geocodeRepetitiveLoss", {
            id: repetitiveLoss.id,
          }),
          state: {
            prevLocation: {
              pathname: location.pathname,
              search: location.search,
            },
          },
        },
      });
    }

    actions.push({
      label: repetitiveLoss.issueIgnored
        ? "Re-add to issue queue"
        : "Ignore issue",
      onClick: async () => {
        await toggleIssueIgnored({
          variables: {
            repetitiveLossId: repetitiveLoss.id,
            getNext: false,
          },
        });
      },
    });
  } else {
    if (repetitiveLoss.property) {
      actions.push({
        label: "View on map",
        to: pipLink({
          address: repetitiveLoss.property.fullAddress ?? "",
          propertyId: repetitiveLoss.property.id,
          lat: repetitiveLoss.property.latitude?.toString() ?? "",
          lng: repetitiveLoss.property.longitude?.toString() ?? "",
        }),
      });

      actions.push({
        label: "Assign to another property",
        to: {
          pathname: buildLink("geocodeRepetitiveLoss", {
            id: repetitiveLoss.id,
          }),
          search: `?forceRegeocode=true`,
          state: {
            prevLocation: {
              pathname: location.pathname,
              search: location.search,
            },
          },
        },
      });
    }
  }

  if (actions.length === 0) {
    return <EmptyActionCell />;
  }

  return <ActionCell actions={actions} />;
};

export default RepetitiveLossActionCell;
