import React from "react";
import { Button } from "../../Common/Button";

const WizardActionButtons = ({
  disablePrimary,
  isPrimaryButtonLoading,
  onBackClick,
  onCancelClick,
  onPrimaryButtonClick,
  primaryButtonText,
  showBackButton,
}: {
  disablePrimary: boolean;
  isPrimaryButtonLoading: boolean;
  onBackClick: () => void;
  onCancelClick: () => void;
  onPrimaryButtonClick: () => void;
  primaryButtonText: string;
  showBackButton: boolean;
}) => {
  return (
    <div style={{ display: "flex", gap: "16px" }}>
      {showBackButton && (
        <Button size="small" styleVariant="ghost" onClick={onBackClick}>
          Back
        </Button>
      )}
      <Button size="small" styleVariant="outlineLight" onClick={onCancelClick}>
        Cancel
      </Button>

      <Button
        size="small"
        styleVariant="primary"
        onClick={onPrimaryButtonClick}
        disabled={disablePrimary}
        loading={isPrimaryButtonLoading}
      >
        {primaryButtonText}
      </Button>
    </div>
  );
};

export default WizardActionButtons;
