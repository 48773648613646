import React, { useContext, useMemo } from "react";
import { NetworkStatus } from "@apollo/client";
import { AuthContext } from "../Authorization/AuthContext";
import { useNewUserInviteModal } from "./NewUserInviteModal";
import { useGetUserInvitesAndUsersQuery } from "../../generated/graphql";

import TeamTable from "./TeamTable";
import { Box } from "../Common/__styles__/Layout";

import { Header, HeaderTitle } from "./__styles__/Content";
import {
  useManualPaginationConfig,
  useLegacyTableStateInURL,
} from "../Common/Tables/hooks";
import { DEFAULT_PAGE_SIZE } from "../Common/Tables/utils";
import { Button } from "../Common/Button";

// TODO: remove startingPage* values from props because they're only used for testing
export const Team = ({
  startingPageIndex = 0,
  startingPageSize = DEFAULT_PAGE_SIZE,
}: {
  startingPageIndex?: number;
  startingPageSize?: number;
}) => {
  const { account } = useContext(AuthContext);

  const [initialTableState, setTableStateInURL] = useLegacyTableStateInURL({
    defaultSortingParams: [{ id: "name", desc: false }],
  });

  const manualPaginationConfig = useManualPaginationConfig({
    ...initialTableState.pagination,
    pageIndex: startingPageIndex,
    pageSize: startingPageSize,
  });

  const variables = {
    page: manualPaginationConfig.pagination.pageIndex + 1,
    pageSize: manualPaginationConfig.pagination.pageSize,
  };

  const {
    data: currentData,
    networkStatus,
    loading,
    error,
    previousData,
    refetch,
  } = useGetUserInvitesAndUsersQuery({ variables });

  const [showNewUserModal, hideNewUserModal] = useNewUserInviteModal({
    onCancel: () => {
      hideNewUserModal();
    },
    onSubmit: async () => {
      await refetch();
      hideNewUserModal();
    },
  });

  const defaultData = useMemo(() => [], []);

  const data =
    networkStatus === NetworkStatus.setVariables ? previousData : currentData;

  if (error) return <div>Error loading team.</div>;

  return (
    <Box>
      <Header>
        <HeaderTitle>Team</HeaderTitle>
        <Button
          leftIconName="plus"
          styleVariant="primary"
          onClick={showNewUserModal}
          size="small"
          data-testid="new-user-button"
        >
          Add User
        </Button>
      </Header>
      <TeamTable
        account={account}
        previousData={previousData?.usersAndInvites.data}
        currentData={data?.usersAndInvites.data ?? defaultData}
        loading={loading}
        manualPaginationConfig={{
          ...manualPaginationConfig,
          pageCount: data?.usersAndInvites.pageInfo.totalPages ?? -1,
        }}
        onUpdate={refetch}
        initialTableState={initialTableState}
        setTableStateInURL={setTableStateInURL}
      />
    </Box>
  );
};

export default Team;
