import React from "react";
import { useHistory } from "react-router";
import { FormProvider, useForm } from "react-hook-form";
import { useDropzone as useReactDropzone } from "react-dropzone";
import { getPath } from "common/routing";
import { MIME_TYPE } from "common/constants";

import FullPageFormLayout from "../../Common/FullPageFormLayout";
import { Button } from "../../Common/Button";
import { useDispatchImportAttioAttendeesDataScriptJobMutation } from "../../../generated/graphql";
import { useStatusToasts } from "../../../hooks/useStatusToasts";
import { Header, Section } from "../../Common/__styles__/FullPageFormLayout";
import { SingleFileUpload } from "../../Inputs/react-hook-form";
import { Error } from "../CreateAccount/__styles__/CreateAccount";
import { useMultipartFileUpload } from "../../../hooks/useMultipartFileUpload";

export type AttioAttendeeImportForm = {
  dataFile: { blob: File };
};

interface Props {
  useDropzone?: (
    args: Pick<NonNullable<Parameters<typeof useReactDropzone>[0]>, "onDrop">
  ) => ReturnType<typeof useReactDropzone>;
}

export const AttioImports = ({ useDropzone }: Props) => {
  const history = useHistory();
  const { addErrorToast, addSuccessToast } = useStatusToasts();

  const formMethods = useForm<AttioAttendeeImportForm>();

  const [uploadSourceFile, { loading: isUploading }] = useMultipartFileUpload({
    onError: () => addErrorToast("Failed to upload input file!"),
  });

  const [
    dispatchImportAttioAttendeesDataScriptJob,
    { loading: isDispatching },
  ] = useDispatchImportAttioAttendeesDataScriptJobMutation({
    onCompleted: () => {
      history.push(getPath("adminAccounts"));
      addSuccessToast(
        "Import submitted successfully! You will recieve an email when the import is completed"
      );
    },
    onError: () => {
      addErrorToast("Failed to import data! Please contact engineering.");
    },
  });

  const onSubmit = async (data: AttioAttendeeImportForm) => {
    uploadSourceFile({
      file: data.dataFile.blob,
      onCompleted: async key => {
        await dispatchImportAttioAttendeesDataScriptJob({
          variables: {
            data: {
              inputFileKey: key,
            },
          },
        });
      },
    });
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = formMethods;

  const rightContainer = (
    <div>
      <Button
        size="small"
        styleVariant="primary"
        onClick={handleSubmit(onSubmit)}
        loading={isDispatching || isUploading}
        disabled={isDispatching || isUploading}
      >
        Import attendee data
      </Button>
    </div>
  );

  return (
    <FormProvider {...formMethods}>
      <FullPageFormLayout
        subtitle="Import attendee data into Attio"
        prevLocation={getPath("adminAccounts")}
        rightContainer={rightContainer}
      >
        <form>
          <Header>
            <h1>Import conference and webinar data into Attio</h1>
            <h2>
              This form will import attendee data into Attio by creating or
              updating people records with appropriate webinar and/or conference
              tags. It can also update job titles, if those are provided.
              <br />
              <br />
              Import CSV guidelines:
              <ul>
                <li>
                  The first row of your CSV should contain the column headers:
                  'email', 'webinar', 'conference', 'jobTitle', 'phoneNumber',
                  'cec', and 'assignment'
                </li>
                <li>
                  The 'email' column is required, the rest of the columns are
                  optional.
                </li>
                <li>
                  If the value in a webinar or conference column does not exist
                  as a select option within Attio, it will be created.
                </li>
              </ul>
              When the script is complete you will receive an email with a link
              to download a CSV containing any errors that occurred during the
              import.
            </h2>
          </Header>
          {errors.dataFile && <Error>{errors.dataFile.message}</Error>}
          <Section>
            <SingleFileUpload
              name="dataFile"
              useDropzone={useDropzone}
              control={control}
              allowedMimeTypes={[MIME_TYPE.CSV]}
              rules={{
                required: "Please upload an attendee data file",
              }}
            />
          </Section>
        </form>
      </FullPageFormLayout>
    </FormProvider>
  );
};

export default AttioImports;
