import React, { useState, useEffect, useContext } from "react";
import { SANITIZED_SUBMISSION_FORMATS } from "common/services/submissionHelpers";

import { Controller, useForm } from "react-hook-form";
import { Select, Checkbox } from "../Inputs/react-hook-form";
import { Label } from "../Inputs";
import { Button } from "../Common/Button";

import { flatMap, flatten, groupBy, map } from "lodash";
import {
  AccountFirMsQuery,
  SubmissionType as GraphQLSubmissionType,
  useAccountFirMsQuery,
  useExportEcsMutation,
  useExportSubmissionsMutation,
} from "../../generated/graphql";
import {
  HeaderSection,
  ButtonSection,
  Container,
  ContentSection,
  PrimaryButtons,
} from "../Common/__styles__/Modal";
import {
  ErrorMessage,
  SuccessMessage,
  Field,
  Note,
} from "./__styles__/ExportDataForm";
import { AuthContext } from "../Authorization/AuthContext";
import { EC_EXPORT_FORMAT, SUBMISSION_EXPORT_FORMAT } from "common/constants";
import { DateRange } from "../Inputs";

type SubmissionType = Pick<GraphQLSubmissionType, "id" | "name" | "category">;

type Option = {
  label: string;
  successMessage: string;
  value: string;
} & (
  | { target: "ec" | "sde"; format: EC_EXPORT_FORMAT }
  | {
      target: "submission";
      format: SUBMISSION_EXPORT_FORMAT;
      submissionTypeId: string;
    }
);

type ExportOptions = Array<{
  label: string;
  options: Array<Option>;
}>;

export const SUBMISSION_EXPORT_FORMAT_EXTENSIONS: Record<
  SUBMISSION_EXPORT_FORMAT,
  `.${string}`
> = {
  [SUBMISSION_EXPORT_FORMAT.CSV]: ".csv",
  [SUBMISSION_EXPORT_FORMAT.SHAPEFILE]: ".shp",
  [SUBMISSION_EXPORT_FORMAT.GEOPACKAGE]: ".gpkg",
};

const buildExportOptions = ({
  submissionTypes,
}: {
  submissionTypes: Array<SubmissionType>;
}): ExportOptions => {
  const ecExportOptions = [
    { value: EC_EXPORT_FORMAT.ZIP, label: "EC Files (.zip)" },
    { value: EC_EXPORT_FORMAT.SHAPEFILE, label: "EC shapefile (.shp)" },
    { value: EC_EXPORT_FORMAT.CSV, label: "EC spreadsheet (.csv)" },
  ];

  const otherExportOptions = [
    {
      value: EC_EXPORT_FORMAT.PERMIT_CSV,
      label: "CRS 310 permit list (.csv)",
      successMessage:
        "Your permit list export is currently processing. Once your data is ready, we'll email you a link to download your file.",
    },
    {
      value: EC_EXPORT_FORMAT.SDE_DATABASE,
      label: "SDE Database (.csv)",
      successMessage:
        "Thanks for requesting an export for your SDE database. Your Account Manager will follow up shortly with your export file.",
      target: "sde",
    },
  ] as const;

  const groupedSubmissions = groupBy(submissionTypes, "category");

  return [
    {
      label: "Elevation Certificates",
      options: ecExportOptions.map(option => ({
        ...option,
        target: "ec",
        successMessage: `Your EC export is currently processing. Once your data is ready, we'll email you a link to download your file.`,
        format: option.value,
      })),
    },
    ...Object.entries(groupedSubmissions).map(([category, submissions]) => ({
      label: category,
      options: flatMap(submissions, submission => {
        return Object.values(SUBMISSION_EXPORT_FORMAT).map(format => ({
          value: `${submission.id}-${format}`,
          label: `${submission.name} (${SUBMISSION_EXPORT_FORMAT_EXTENSIONS[format]})`,
          target: "submission" as const,
          successMessage: `Your ${submission.name} ${SANITIZED_SUBMISSION_FORMATS[format]} export is currently processing. We'll email you a link to download your file.`,
          submissionTypeId: submission.id,
          format,
        }));
      }),
    })),
    {
      label: "Other",
      options: otherExportOptions.map(option => ({
        target: "ec",
        ...option,
        format: option.value,
      })),
    },
  ];
};

type ExportDocumentsFormStructure = {
  selectedOptionValue: Option["value"];
  dateRange: {
    startDate: Maybe<Date>;
    endDate: Maybe<Date>;
  };
  finishedConstructionOnly: boolean;
  firmId: Maybe<string>;
};

const isECExportOption = (
  option: Option
): option is Extract<Option, { target: "ec" | "sde" }> =>
  option.target === "ec" || option.target === "sde";

export default ({
  closeModal,
  firms,
}: {
  closeModal?: () => void;
  firms?: NonNullable<AccountFirMsQuery["account"]>["firms"];
}) => {
  const [error, setError] = useState<Maybe<string>>(null);
  const [successMessage, setSuccessMessage] = useState<Maybe<string>>(null);

  const { account } = useContext(AuthContext);
  const submissionTypes = account?.submissionTypes || [];

  const groupedExportOptions = buildExportOptions({ submissionTypes });
  const flattenedExportOptions = flatten(map(groupedExportOptions, "options"));

  const { data: dataFIRMs, loading: loadingFIRMs } = useAccountFirMsQuery({
    skip: !!firms,
  });

  const { control, handleSubmit, setValue, watch, register } =
    useForm<ExportDocumentsFormStructure>({
      defaultValues: {
        selectedOptionValue: flattenedExportOptions[0]!.value,
        dateRange: { startDate: null, endDate: null },
        finishedConstructionOnly: false,
        firmId: null,
      },
    });

  const findOption = (
    selectedOptionValue: ExportDocumentsFormStructure["selectedOptionValue"]
  ) =>
    flattenedExportOptions.find(
      option => option.value === selectedOptionValue
    )!;

  const selectedOptionValue = watch("selectedOptionValue");
  const [selectedOption, setSelectedOption] = useState<Option>(
    findOption(selectedOptionValue)
  );

  useEffect(() => {
    const selectedOption = findOption(selectedOptionValue);
    setSelectedOption(selectedOption);

    // clear out inputs for those fields that don't apply
    // to the selected export type
    if (selectedOption.target !== "ec") {
      setValue("finishedConstructionOnly", false);
      setValue("firmId", null);
    }
    if (selectedOption.target === "sde") {
      setValue("dateRange", { startDate: null, endDate: null });
    }
  }, [selectedOptionValue]);

  const [exportECs, { loading: loadingECsExport }] = useExportEcsMutation({
    onCompleted: data => {
      if (data.exportECs) {
        setSuccessMessage(selectedOption.successMessage);
      } else {
        setError("No certificates were found matching your selection.");
      }
    },
    onError: () => {
      setError("An error occurred. Please try again.");
    },
  });

  const [exportSubmissions, { loading: loadingSubmissionsExport }] =
    useExportSubmissionsMutation({
      onCompleted: data => {
        if (data.exportSubmissions) {
          setSuccessMessage(selectedOption.successMessage);
        } else {
          setError("No submissions were found matching your selection.");
        }
      },
      onError: () => {
        setError("An error occurred. Please try again.");
      },
    });

  if (loadingFIRMs) {
    return <div>Loading...</div>;
  }

  firms = firms ?? dataFIRMs?.account?.firms;
  if (!firms) {
    return null;
  }

  const sfhaOptions = firms.map(firm => {
    return { value: firm.id, label: `${firm.name} SFHA` };
  });

  if (successMessage) {
    return (
      <Container width="narrow">
        <HeaderSection>
          <h1>Export Data</h1>
        </HeaderSection>
        <ContentSection>
          <SuccessMessage>{successMessage}</SuccessMessage>
        </ContentSection>
        <ButtonSection>
          <PrimaryButtons>
            <Button onClick={closeModal} styleVariant="primary" size="small">
              Close
            </Button>
          </PrimaryButtons>
        </ButtonSection>
      </Container>
    );
  }

  const isECExport = selectedOption.target === "ec";
  const isSDEExport = selectedOption.target === "sde";

  return (
    <Container tabIndex={-1} width={"narrow"} overflows>
      <HeaderSection>
        <h1 id="export-form-heading">Export Data</h1>
      </HeaderSection>
      <ContentSection style={{ lineHeight: 1.15 }} overflows>
        <Field>
          <Select
            label="Export Type"
            control={control}
            name="selectedOptionValue"
            options={groupedExportOptions}
            required={true}
            autoFocus={true}
            onChange={() => {
              setError(null);
            }}
          />
          <Note>
            For exports of file types other than ECs, please reach out to
            support at{" "}
            <a href="mailto:support@withforerunner.com">
              support@withforerunner.com
            </a>
          </Note>
        </Field>
        {isECExport && (
          <>
            <Field>
              <Select
                label="FIRM location"
                control={control}
                name="firmId"
                options={[{ value: null, label: "Anywhere" }, ...sfhaOptions]}
                disabled={!isECExport}
              />
            </Field>
            <Field checkbox={true}>
              <Checkbox
                id="finishedConstructionOnly"
                {...register("finishedConstructionOnly")}
                disabled={!isECExport}
              />
              <Label
                htmlFor="finishedConstructionOnly"
                text="Finished construction only"
                compact={true}
                style={{ fontWeight: 400, marginLeft: 10 }}
              />
            </Field>
          </>
        )}
        {!isSDEExport && (
          <Field>
            <Label text="Date Range" />
            <Controller
              control={control}
              name="dateRange"
              render={({ field }) => (
                <DateRange
                  onChange={field.onChange}
                  value={field.value}
                  disabled={isSDEExport}
                />
              )}
            />
            <Note>
              Note: For Elevation Certificates, the date range is based on the
              certificate's <strong>issue date</strong>. For other types, the
              date range is based on when the data was <strong>created</strong>{" "}
              on the platform.
            </Note>
          </Field>
        )}
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </ContentSection>
      <ButtonSection>
        <PrimaryButtons>
          <Button onClick={closeModal} styleVariant="secondary" size="small">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit(data => {
              const selectedOption = findOption(data.selectedOptionValue);

              if (isECExportOption(selectedOption)) {
                void exportECs({
                  variables: {
                    data: {
                      ...data.dateRange,
                      firmId: data.firmId,
                      format: selectedOption.format,
                      finishedConstructionOnly: data.finishedConstructionOnly,
                    },
                  },
                });
              } else {
                void exportSubmissions({
                  variables: {
                    data: {
                      startDate: data.dateRange.startDate,
                      endDate: data.dateRange.endDate,
                      submissionTypeId: selectedOption.submissionTypeId,
                      format: selectedOption.format,
                    },
                  },
                });
              }
            })}
            styleVariant="primary"
            size="small"
            disabled={loadingECsExport || loadingSubmissionsExport}
          >
            Export
          </Button>
        </PrimaryButtons>
      </ButtonSection>
    </Container>
  );
};
