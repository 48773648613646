import React from "react";
import { Switch, Route } from "react-router-dom";

import List from "./List";
import { Layout } from "../Common/FullWidthLayout";

export default () => {
  return (
    <Layout title="Resident Logs">
      <Switch>
        <Route path="/logs" exact component={List} />
      </Switch>
    </Layout>
  );
};
