import React, { useState } from "react";
import { getPath } from "common/routing";
import { useLocation } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { CreateDocumentTemplateForm } from "./CreateOrUpdateForm";

import { Label } from "../../../Inputs";
import { Button } from "../../../Common/Button";
import { Select } from "../../../Inputs/react-hook-form";
import FullPageFormLayout from "../../../Common/FullPageFormLayout";

import {
  ButtonContainer,
  Container,
  FieldRow,
  FieldTable,
  Header,
  TableHeader,
} from "./__styles__/DocumentTemplates";
import {
  AccountDocumentTemplate,
  CreateAccountDocumentTemplateMutationFn,
  UpdateAccountDocumentTemplateMutationFn,
  useGetAccountDocGenOptionsQuery,
} from "../../../../generated/graphql";
import { useStatusToasts } from "../../../../hooks/useStatusToasts";
import { captureException } from "@sentry/browser";
import { ApolloError } from "@apollo/client";
import {
  MAPPING_TYPE_MISMATCH_ERROR,
  PDF_MAPPING_TYPE,
} from "common/constants";
import {
  compatibleFieldMappings,
  isCompatibleFieldType,
} from "common/utils/documentTemplates";

export interface MappingFormProps {
  subtitle: string;
  backButtonText: string;
  actionButtonText: string;
  loadingAction: boolean;
  canDoAction: boolean;
  documentTemplate?: Pick<AccountDocumentTemplate, "id" | "fieldMappings">;
  type: "update" | "create";
  onBack: () => void;
  onSubmit:
    | CreateAccountDocumentTemplateMutationFn
    | UpdateAccountDocumentTemplateMutationFn;
}

const MappingForm = ({
  subtitle,
  backButtonText,
  actionButtonText,
  loadingAction,
  canDoAction,
  documentTemplate,
  type,
  onBack,
  onSubmit,
}: MappingFormProps) => {
  const {
    control,
    getValues,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useFormContext<CreateDocumentTemplateForm>();
  const location = useLocation<undefined | { prevLocation?: string }>();
  const { data, loading } = useGetAccountDocGenOptionsQuery();

  const docGenOptions = !loading ? data?.account?.docGenOptions ?? [] : [];
  const { addSuccessToast, addErrorToast } = useStatusToasts();
  const [hasUpdatedTemplateSuccessfully, setHasCreatedTemplateSuccessfully] =
    useState(false);

  const onError = (error: Error) => {
    captureException(error);
    addErrorToast(
      "There was a problem updating your template. Please try again or contact support dev"
    );
  };

  const validateMapping = (
    selectedValue: Maybe<string>,
    mappingIndex: number
  ) => {
    const selectedOption = mappingOptions.find(
      option => option.value === selectedValue
    );
    const currentMapping = getValues(`mapping.${mappingIndex}`);

    if (
      !isCompatibleFieldType(selectedOption!.type, currentMapping.pdfFieldType)
    ) {
      return (
        MAPPING_TYPE_MISMATCH_ERROR +
        ` Please select a field of type: ${compatibleFieldMappings[
          selectedOption!.type
        ].join(", ")}`
      );
    }
    return;
  };

  const submit = async (data: CreateDocumentTemplateForm) => {
    const onSubmitParams = {
      onCompleted: () => {
        const verb = type === "update" ? "updated" : "saved";
        addSuccessToast(`Your template was successfully ${verb}`);
        setHasCreatedTemplateSuccessfully(true);
        setTimeout(() => {
          const currentUrl = window.location.href;
          const newUrl = currentUrl.replace(/\/[^/]+$/, "");
          window.location.href = newUrl;
        }, 1500);
      },
      onError: (error: ApolloError) => onError(error),
    };

    if (type === "update") {
      await (onSubmit as UpdateAccountDocumentTemplateMutationFn)({
        variables: {
          id: documentTemplate!.id,
          fieldMappings: data.mapping,
        },
        ...onSubmitParams,
      });
    } else {
      await (onSubmit as CreateAccountDocumentTemplateMutationFn)({
        variables: {
          data: {
            name: data.name,
            filename: data.s3Key,
            fieldMappings: data.mapping,
            accountDocumentTypeId: data.associatedDocumentTypeId,
          },
        },
        ...onSubmitParams,
      });
    }
  };

  const rightContainer = (
    <ButtonContainer>
      <Button styleVariant="secondary" size="medium" onClick={onBack}>
        {backButtonText}
      </Button>
      <Button
        styleVariant="primary"
        size="medium"
        disabled={
          !canDoAction ||
          loadingAction ||
          hasUpdatedTemplateSuccessfully ||
          !isValid
        }
        loading={loadingAction}
        onClick={handleSubmit(submit)}
      >
        {actionButtonText}
      </Button>
    </ButtonContainer>
  );

  const prevLocation =
    location.state?.prevLocation ?? getPath("documentTemplates");

  const mappingOptions = [
    ...docGenOptions,
    // Because blank needs to be last, appending it manually to the end of the list
    {
      label: "(empty)",
      value: "blank",
      type: PDF_MAPPING_TYPE.ANY,
    },
  ];

  return (
    <FullPageFormLayout
      centered={true}
      overflows={true}
      width={"wide"}
      subtitle={subtitle}
      prevLocation={prevLocation}
      rightContainer={rightContainer}
    >
      <Container>
        <Header>
          <p>Field mapping</p>
          <p>
            Please map the smart fields in your PDF file to the fields in
            Forerunner. For more information on document template field mapping,
            please see our help article.
          </p>
        </Header>
        <FieldTable>
          <TableHeader>
            <FieldRow>
              <th>PDF smart field name</th>
              <th>
                <Label
                  required
                  text="Forerunner field name"
                  style={{ marginBottom: "0" }}
                />
              </th>
            </FieldRow>
          </TableHeader>
          <tbody>
            {getValues("mapping").map((f, index) => (
              <FieldRow key={f.label}>
                <td>
                  <Label
                    text={f.label}
                    required={false}
                    style={{ fontWeight: "normal" }}
                  />
                </td>
                <td>
                  <Select
                    name={`mapping.${index}.value`}
                    size="medium"
                    control={control}
                    rules={{
                      required: "This field is required",
                      validate: value => {
                        if (!loading) {
                          return validateMapping(value, index);
                        }
                        return;
                      },
                    }}
                    required
                    options={mappingOptions.filter(option => {
                      const currentMapping = getValues(`mapping.${index}`);
                      return isCompatibleFieldType(
                        option.type,
                        currentMapping.pdfFieldType
                      );
                    })}
                    error={errors.mapping?.[index]?.value?.message}
                    onChange={value => {
                      const option = mappingOptions.find(
                        option => option.value === value
                      );
                      if (option) {
                        setValue(
                          `mapping.${index}.forerunnerFieldType`,
                          option.type
                        );
                      }
                    }}
                  />
                </td>
              </FieldRow>
            ))}
          </tbody>
        </FieldTable>
      </Container>
    </FullPageFormLayout>
  );
};

export default MappingForm;
