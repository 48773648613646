import React from "react";
import Icon, { Icons } from "./Icons";
import NoticeBoard from "./NoticeBoard";

import "./__styles__/InsufficientPermissions.scss";

export const InsufficientPermissions = () => (
  <div styleName="container">
    <div styleName="notice">
      <NoticeBoard
        icon={<Icon icon={Icons.WARNING} />}
        heading="Oh no!"
        note="You don't currently have access to this page. Please view a different page, request access from your account's Manager, or contact us at support@withforerunner.com"
      />
    </div>
  </div>
);

export const FullScreenPermissionsError = () => {
  return (
    <div styleName="full-screen">
      <InsufficientPermissions />
    </div>
  );
};
