import React, { ComponentProps } from "react";
import { UserRoleName } from "../../generated/graphql";
import {
  PermissionRow,
  Option,
  LabelContainer,
  Description,
  BorderBox,
  PiiHeader,
} from "./__styles__/UpdateUserPermissionsModal";

type Option = {
  text: string;
  value: string;
  description: string;
};
const UserRoleOptions: Array<Omit<Option, "value"> & { value: UserRoleName }> =
  [
    {
      text: "Manager",
      value: UserRoleName.MANAGER,
      description:
        "This role has full product functionality and can also invite/modify users.",
    },
    {
      text: "Member",
      value: UserRoleName.MEMBER,
      description:
        "This role has full product functionality but does not have the ability to invite/modify users.",
    },
    {
      text: "Creator",
      value: UserRoleName.CREATOR,
      description:
        "This role has full product functionality but does not have the ability to invite/modify users and cannot delete data.",
    },
    {
      text: "View only",
      value: UserRoleName.VIEW_ONLY,
      description:
        "This role has limited product functionality and does not have the ability to invite/modify users.",
    },
  ];

const PiiPermissionsOptions: Array<Option> = [
  {
    text: "Cannot view",
    value: "false",
    description:
      "This role does not have the ability to view Personally Identifiable Information such as Repetitive Loss data.",
  },
  {
    text: "Can view",
    value: "true",
    description:
      "This role does have the ability to view Personally Identifiable Information such as Repetitive Loss data.",
  },
];

const OptionRow: React.FC<
  Option & {
    defaultValue?: string;
    onChange: (newValue: string) => void;
    hideBorder: boolean;
    name: string;
  }
> = ({
  text,
  description,
  value,
  defaultValue,
  onChange,
  hideBorder,
  name,
}) => {
  const rowId = `id-${value}`;

  return (
    <PermissionRow>
      <Option>
        <input
          id={rowId}
          type="radio"
          onChange={evt => onChange(evt.target.value)}
          value={value}
          defaultChecked={defaultValue === value}
          name={name}
        />
      </Option>
      <BorderBox hideBorder={hideBorder}>
        <LabelContainer>
          <label htmlFor={rowId}>{text}</label>
        </LabelContainer>
        <Description>{description}</Description>
      </BorderBox>
    </PermissionRow>
  );
};

interface UserRoleSelect {
  onChange: ComponentProps<typeof OptionRow>["onChange"];
  value: UserRoleName;
}

export const UserRoleSelect = ({ onChange, value }: UserRoleSelect) => (
  <>
    {UserRoleOptions.map((option, index) => (
      <OptionRow
        {...option}
        key={option.value}
        defaultValue={value}
        onChange={onChange}
        hideBorder={index === 0}
        name="roleName"
      />
    ))}
  </>
);

interface PiiPermissionsSelect {
  onChange: (newValue: boolean) => void;
  value: boolean;
}
export const PiiPermissionsSelect = ({
  onChange,
  value,
}: PiiPermissionsSelect) => {
  const defaultValue = value ? "true" : "false";
  const handleChange = (newValue: string) => {
    const booleanValue = newValue === "true";
    onChange(booleanValue);
  };
  return (
    <React.Fragment>
      <PiiHeader>Personally Identifiable Information</PiiHeader>
      {PiiPermissionsOptions.map((option, index) => (
        <OptionRow
          {...option}
          key={option.value}
          defaultValue={defaultValue}
          onChange={handleChange}
          hideBorder={index === 0}
          name="canViewPersonalIdentifiableInformation"
        />
      ))}
    </React.Fragment>
  );
};
