import React from "react";
import { Button, ButtonProps } from "./Button";

export default ({
  onClick,
  disabled,
  styleVariant = "secondary",
}: {
  onClick: () => void;
  disabled?: boolean;
  styleVariant?: ButtonProps["styleVariant"];
}) => {
  return (
    <Button
      onClick={onClick}
      size="small"
      styleVariant={styleVariant}
      disabled={disabled}
      aria-label="Export"
      leftIconName="download"
    >
      Export
    </Button>
  );
};
