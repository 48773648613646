import React from "react";

import { CustomCheckbox } from "../../../../../../Submissions/Widgets";

import BaseWidgetPreview from "./BaseWidgetPreview";
import { WidgetProps } from "@rjsf/utils";

const CheckboxWidgetPreview = (props: WidgetProps) => {
  const widgetPreview = <CustomCheckbox {...props} />;
  return (
    <BaseWidgetPreview
      widgetPreview={widgetPreview}
      {...props}
    ></BaseWidgetPreview>
  );
};

export default CheckboxWidgetPreview;
