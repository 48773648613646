import React from "react";
import { isNil } from "lodash";
import { Control, FieldValues, useFormContext } from "react-hook-form";

import { Button } from "../../../../../Common/Button";
import { Select, Datepicker } from "../../../../../Inputs/react-hook-form";

import {
  ButtonSection,
  Container,
  ContentSection,
  FormSection,
  HeaderSection,
  InputRow,
  PrimaryButtons,
  SecondaryButtons,
} from "../../../../../Common/__styles__/Modal";

export interface RevalidateEcsFormStageProps {
  disabled?: boolean;
  loading: boolean;
  onBack?: () => void;
  onCancel: () => void;
  onImport: () => void;
}

export type RevalidateEcsStageType = {
  revalidateEcs: boolean;
  issueDate?: Maybe<Date>;
};

export const RevalidateEcsStage = ({
  loading,
  onBack,
  onCancel,
  onImport,
}: RevalidateEcsFormStageProps) => {
  const { getValues, control, trigger, formState } =
    useFormContext<RevalidateEcsStageType>();

  return (
    <Container overflows width="narrow">
      <HeaderSection>
        <h1>Start import</h1>
      </HeaderSection>
      <FormSection overflows>
        <ContentSection overflows>
          <InputRow>
            <Select
              control={control as unknown as Control<FieldValues, any>}
              name="revalidateEcs"
              rules={{
                required: isNil(getValues("revalidateEcs"))
                  ? "Required field"
                  : undefined,
              }}
              label="Do EC errors related to FIRM data need to be recomputed?"
              description="If errors are recomputed, all existing ignored errors will be removed."
              onChange={_ => trigger("issueDate")}
              required={true}
              options={[
                { value: false, label: "No" },
                { value: true, label: "Yes" },
              ]}
            />
          </InputRow>
          <InputRow>
            <Datepicker
              control={control as unknown as Control<FieldValues, any>}
              name={"issueDate"}
              rules={{
                validate: _ =>
                  getValues("revalidateEcs") === true && !getValues("issueDate")
                    ? "Required field"
                    : undefined,
              }}
              onChange={_ => trigger("issueDate")}
              label={"If so, provide the date from which we should recompute."}
              required={!!getValues("revalidateEcs")}
            />
          </InputRow>
        </ContentSection>
      </FormSection>
      <ButtonSection>
        {onBack && (
          <SecondaryButtons>
            <Button
              styleVariant="ghost"
              onClick={onBack}
              size="medium"
              disabled={loading}
            >
              Back
            </Button>
          </SecondaryButtons>
        )}
        <PrimaryButtons>
          <Button styleVariant="secondary" onClick={onCancel} size="medium">
            Cancel
          </Button>
          <Button
            styleVariant="primary"
            onClick={onImport}
            size="medium"
            disabled={!formState.isValid}
            loading={loading}
          >
            Import
          </Button>
        </PrimaryButtons>
      </ButtonSection>
    </Container>
  );
};

export default RevalidateEcsStage;
