import React from "react";
import { Header, MenuOptionContainer } from "./__styles__/Operations";
import { spacing } from "../../../../stitches.config";
import AccountBoundaryMenu from "./AccountBoundaryMenu";
import { Title } from "../../../Common/Typography";
import { Box } from "../../../Common/__styles__/Layout";
import Divider from "../../../Common/Divider";
import BulkPropertyActionMenu from "./BulkPropertyActionMenu";

const Operations = () => {
  return (
    <Box>
      <Header>
        <Title size="small" type="semiBold">
          Operations
        </Title>
      </Header>
      <MenuOptionContainer>
        <BulkPropertyActionMenu />
        <Divider margins={`${spacing.l.toString()} 0`} />
        <AccountBoundaryMenu />
      </MenuOptionContainer>
    </Box>
  );
};

export default Operations;
