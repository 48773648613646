import React from "react";
import { Button } from "../../../Common/Button";
import { buildLink } from "common/routing";

import FullPageFormLayout from "../../../Common/FullPageFormLayout";

import {
  ButtonContainer,
  Container,
  Header,
} from "./__styles__/DocumentTemplates";

const MappingStepPlaceholder = () => {
  const rightContainer = (
    <ButtonContainer>
      <Button styleVariant="secondary" size="medium">
        Cancel
      </Button>
      <Button styleVariant="primary" size="medium">
        Save
      </Button>
    </ButtonContainer>
  );

  return (
    <FullPageFormLayout
      centered={true}
      subtitle="Update document template"
      prevLocation={buildLink("documentTemplates")}
      rightContainer={rightContainer}
    >
      <Container>
        <Header>
          <p>Field mapping</p>
          <p>
            Please map the smart fields in your PDF file to the fields in
            Forerunner. For more information on document template field mapping,
            please see our help article.
          </p>
        </Header>
      </Container>
    </FullPageFormLayout>
  );
};

export default MappingStepPlaceholder;
