import useDrivePicker from "react-google-drive-picker";
import {
  useBulkCertificateUploadMutation,
  useRevalidateElevationCertificatesMutation,
} from "../../../../generated/graphql";
import { Button } from "../../../Common/Button";
import Divider from "../../../Common/Divider";
import { Box, FlexColumn } from "../../../Common/__styles__/Layout";
import {
  Header,
  HeaderTitle,
  HelperText,
  Section,
  SectionTitle,
} from "../../__styles__/Content";
import { useRevalidateEcsModal } from "./revalidateEcsModal";
import React, { useEffect, useRef } from "react";
import { useStatusToasts } from "../../../../hooks/useStatusToasts";
import { authResult } from "react-google-drive-picker/dist/typeDefs";

export const ElevationCertificates = () => {
  const [openPicker, authResponse] = useDrivePicker();

  const [runEcRevalidation] = useRevalidateElevationCertificatesMutation();
  const { addErrorToast, addSuccessToast } = useStatusToasts();

  const [bulkCertificateUpload] = useBulkCertificateUploadMutation({
    onCompleted: () => {
      addSuccessToast("Bulk upload initiated");
    },
    onError: () => {
      addErrorToast("Failed to initiate bulk upload");
    },
  });

  const authRef = useRef<authResult | undefined>();
  useEffect(() => {
    authRef.current = authResponse;
  }, [authResponse]);

  const handleOpenPicker = () => {
    openPicker({
      clientId: window.env.GOOGLE_OAUTH_CLIENT_ID,
      developerKey: window.env.GOOGLE_DRIVE_API_KEY,
      viewId: "FOLDERS",
      setSelectFolderEnabled: true,
      setIncludeFolders: true,
      supportDrives: true,
      multiselect: true,
      callbackFunction: data => {
        if (data.action === "picked") {
          void bulkCertificateUpload({
            variables: {
              data: {
                folderId: data.docs[0]!.id,
                folderName: data.docs[0]!.name,
                oauthToken: {
                  accessToken: authRef.current?.access_token!,
                  tokenType: authRef.current?.token_type!,
                  scope: authRef.current?.scope!,
                },
              },
            },
          });
        }
      },
    });
  };

  const [showRevalidateEcs] = useRevalidateEcsModal({
    onSubmit: runEcRevalidation,
  });

  return (
    <Box>
      <Header style={{ padding: "24px" }}>
        <HeaderTitle style={{ margin: 0 }}>Elevation Certificates</HeaderTitle>
      </Header>
      <Section
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <FlexColumn>
          <SectionTitle>Bulk EC upload</SectionTitle>
          <HelperText>
            Upload and process ECs from a Google Drive folder.
          </HelperText>
        </FlexColumn>

        <Button
          leftIconName="plus"
          styleVariant="outlineLight"
          size="small"
          onClick={handleOpenPicker}
        >
          Upload
        </Button>
      </Section>
      <Divider />
      <Section
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <FlexColumn>
          <SectionTitle>EC revalidation</SectionTitle>
          <HelperText>
            Recompute errors on selected elevation certificates in an account.
          </HelperText>
        </FlexColumn>

        <Button
          leftIconName="plus"
          styleVariant="outlineLight"
          size="small"
          onClick={showRevalidateEcs}
        >
          Revalidate
        </Button>
      </Section>
    </Box>
  );
};
