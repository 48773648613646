import React, { useMemo } from "react";
import { Property } from "@stitches/react/types/css";
import { Icon } from "./Icons/LucideIcons";
import {
  SegmentContentWrapper,
  FilterSegmentWrapper,
  MultiLogicFilterWrapper,
  FilterSegmentRow,
  tooltipClassName,
} from "./__styles__/MultiLogicFilter";
import { v4 as uuidv4 } from "uuid";
import ReactTooltip from "react-tooltip";

interface SegmentContent {
  delimiter: string;
  strings: string[];
}

interface BaseFilterSegmentProps<ContextType> {
  stretch?: boolean;
  minWidth?: Property.MinWidth;
  context?: ContextType;
}

interface AttributeFilterSegmentProps<ContextType>
  extends BaseFilterSegmentProps<ContextType> {
  type: "string";
  content: string;
}

interface NestedAttributeFilterSegmentProps<ContextType>
  extends BaseFilterSegmentProps<ContextType> {
  type: "attributePath";
  content: SegmentContent;
}

interface OperatorFilterSegmentProps<ContextType>
  extends BaseFilterSegmentProps<ContextType> {
  type: "operator";
  content: string;
}

interface StringArrayFilterSegmentProps<ContextType>
  extends BaseFilterSegmentProps<ContextType> {
  type: "stringArray";
  content: string[];
}

export type FilterSegmentProps<ContextType> =
  | AttributeFilterSegmentProps<ContextType>
  | NestedAttributeFilterSegmentProps<ContextType>
  | OperatorFilterSegmentProps<ContextType>
  | StringArrayFilterSegmentProps<ContextType>;

const FilterSegment = <ContextType,>({
  type,
  content,
  stretch,
  minWidth,
}: FilterSegmentProps<ContextType>) => {
  const id = useMemo(() => uuidv4(), []);

  let renderedContent: JSX.Element | JSX.Element[];
  let tooltipText: string;

  if (type === "attributePath") {
    tooltipText = content.strings.join(` ${content.delimiter} `);

    renderedContent = content.strings.reduce((acc, piece, index) => {
      if (index > 0) {
        acc.push(
          <SegmentContentWrapper key={`delimiter-${index}`} delimiter>
            {content.delimiter}
          </SegmentContentWrapper>
        );
      }

      acc.push(
        <SegmentContentWrapper key={`piece-${index}`}>
          {piece}
        </SegmentContentWrapper>
      );

      return acc;
    }, [] as JSX.Element[]);
  } else if (type === "stringArray") {
    const joinedContent = content.join(", ");
    tooltipText = joinedContent;

    renderedContent = (
      <SegmentContentWrapper>{joinedContent}</SegmentContentWrapper>
    );
  } else {
    tooltipText = content;
    renderedContent = <SegmentContentWrapper>{content}</SegmentContentWrapper>;
  }

  return (
    <FilterSegmentWrapper
      css={{ minWidth }}
      stretch={stretch}
      segmentContentType={type}
      hasMinWidth={!!minWidth}
      data-tip
      data-for={id}
    >
      {renderedContent}
      {type !== "operator" && (
        <ReactTooltip className={tooltipClassName} id={id} place="top">
          {tooltipText}
        </ReactTooltip>
      )}
    </FilterSegmentWrapper>
  );
};

export type MultiLogicFilterData<ContextType> =
  FilterSegmentProps<ContextType>[][];

export interface MultiLogicFilterProps<ContextType> {
  filters: MultiLogicFilterData<ContextType>;
  includeAndPrefix?: boolean;
  onFilterRemove: (
    index: number,
    filterData: FilterSegmentProps<ContextType>[]
  ) => void;
  segmentGap?: Property.Gap;
}

const MultiLogicFilter = <ContextType,>({
  filters,
  includeAndPrefix,
  onFilterRemove,
  segmentGap,
}: MultiLogicFilterProps<ContextType>) => {
  const removeBtnId = useMemo(() => uuidv4(), []);

  return filters.length === 0 ? null : (
    <MultiLogicFilterWrapper>
      {filters.map((filterRow, filterIndex) => (
        <FilterSegmentRow key={filterIndex}>
          {includeAndPrefix && filterIndex > 0 && (
            <FilterSegmentWrapper
              css={{ gap: segmentGap }}
              segmentContentType="operator"
            >
              <SegmentContentWrapper>AND</SegmentContentWrapper>
            </FilterSegmentWrapper>
          )}
          {filterRow.map((filterSegmentProps, segmentIndex) => (
            <FilterSegment key={segmentIndex} {...filterSegmentProps} />
          ))}
          <FilterSegmentWrapper
            onClick={() => onFilterRemove(filterIndex, filterRow)}
            segmentContentType="button"
            role="button"
            aria-label="Remove filter"
            data-tip
            data-for={removeBtnId}
          >
            <Icon iconName={"x"} color="contentSecondary" size={16} />
            <ReactTooltip
              className={tooltipClassName}
              id={removeBtnId}
              place="top"
            >
              Remove
            </ReactTooltip>
          </FilterSegmentWrapper>
        </FilterSegmentRow>
      ))}
    </MultiLogicFilterWrapper>
  );
};

export default MultiLogicFilter;
