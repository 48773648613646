import React, { useState } from "react";
import {
  ButtonSection,
  Container,
  ContentSection,
  FormSection,
  HeaderSection,
  PrimaryButtons,
} from "../../../../Common/__styles__/Modal";
import { Button } from "../../../../Common/Button";
import { useModal } from "react-modal-hook";
import {
  ParcelImportConfiguration,
  ParcelImportTaskType,
} from "../../../../../generated/graphql";
import Modal from "../../../../Common/Modal";
import { buildLink } from "common/routing";
import { useHistory } from "react-router";

export const useConfigurationOverridesModal = ({
  parcelImportConfiguration,
}: {
  parcelImportConfiguration?: Maybe<ParcelImportConfiguration>;
}) => {
  const history = useHistory();

  const [importTaskTypeArg, setImportTaskTypeArg] =
    useState<Maybe<ParcelImportTaskType>>(null);

  const [showConfigOverridesModal, hideConfigOverridesModal] = useModal(
    () => (
      <Modal onRequestClose={hideConfigOverridesModal}>
        <ConfigurationOverridesModal
          importTaskType={importTaskTypeArg!}
          onCancel={hideConfigOverridesModal}
        />
      </Modal>
    ),
    [importTaskTypeArg]
  );

  const conditionallyShowModal = (importTaskType: ParcelImportTaskType) => {
    if (!parcelImportConfiguration) {
      history.push(
        buildLink("parcelImportConfigCreate", {
          importTaskType,
        })
      );
      return;
    }

    setImportTaskTypeArg(importTaskType);
    showConfigOverridesModal();
  };

  return [conditionallyShowModal, hideConfigOverridesModal] as const;
};

const ConfigurationOverridesModal = ({
  importTaskType,
  onCancel,
}: {
  importTaskType: ParcelImportTaskType;
  onCancel: () => void;
}) => {
  const history = useHistory();

  return (
    <Container>
      <HeaderSection>
        <h1>Update parcel data source</h1>
      </HeaderSection>
      <FormSection>
        <ContentSection>
          <p>
            Your account already has a parcel data source setup. Are you sure
            you want to override <b>{name}</b> and setup a new parcel data
            source?
          </p>
        </ContentSection>
      </FormSection>
      <ButtonSection>
        <PrimaryButtons>
          <Button styleVariant="secondary" onClick={onCancel} size="medium">
            Cancel
          </Button>
          <Button
            styleVariant="alert"
            size="medium"
            onClick={() => {
              history.push(
                buildLink("parcelImportConfigCreate", {
                  importTaskType: importTaskType,
                })
              );
            }}
          >
            I understand, and want to update the parcel source
          </Button>
        </PrimaryButtons>
      </ButtonSection>
    </Container>
  );
};
