import React from "react";

import { Button } from "../../Common/Button";

interface SharePropertyInfoButtonProps {
  onClick: () => void;
  disabled: boolean;
}
const SharePropertyInfoButton = ({
  onClick,
  disabled,
}: SharePropertyInfoButtonProps) => (
  <Button
    onClick={onClick}
    size="small"
    styleVariant="outlineDark"
    disabled={disabled}
    leftIconName="mail"
  >
    Share public profile
  </Button>
);

export default SharePropertyInfoButton;
