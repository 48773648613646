import React from "react";
import { RouteComponentProps } from "react-router";
import { useGetDocumentUploadIdQuery } from "../../../generated/graphql";
import DetailView from "../DetailView";

export default ({
  match,
}: Pick<RouteComponentProps<{ uuid: string }>, "match">) => {
  const { data, error } = useGetDocumentUploadIdQuery({
    variables: { id: match.params.uuid },
  });

  if (!data?.certificate || error) return null;

  const documentUploadId = data.certificate.documentUpload.id;

  return <DetailView documentUploadId={documentUploadId} />;
};
