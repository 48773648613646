import React from "react";
import { useFormContext } from "react-hook-form";
import { Label } from "../../../../../../Inputs";
import { Text } from "../../../../../../Inputs/react-hook-form";
import {
  InputFieldPath,
  SubmissionsBuilderFormDataStructure,
} from "../../types";
import { WidgetSettingsContainer } from "./__styles__/WidgetSettings";
import { FieldSettingConfig } from "./shared";

const TextareaWidgetSettings = ({
  fieldPath,
  isModuleInput,
}: {
  fieldPath: InputFieldPath;
  isModuleInput: boolean;
}) => {
  const { register } = useFormContext<SubmissionsBuilderFormDataStructure>();

  return (
    <WidgetSettingsContainer>
      <FieldSettingConfig fieldPath={fieldPath} isModuleInput={isModuleInput} />
      <div>
        <Label text={"Placeholder text"} />
        <Text {...register(`${fieldPath}.placeholder`)} />
      </div>
    </WidgetSettingsContainer>
  );
};

TextareaWidgetSettings.displayName = "TextareaWidgetSettings";

export default TextareaWidgetSettings;
