import React, { useContext } from "react";
import Table from "../../../Common/Tables/Table";
import {
  SubmissionIntakeSource,
  SubmissionType,
  useGetSubmissionTypesQuery,
} from "../../../../generated/graphql";
import { ColumnDef } from "@tanstack/react-table";
import DropdownMenu, { ActionsProps } from "../../../Inputs/DropdownMenu";
import { AuthContext } from "../../../Authorization/AuthContext";
import { startCase } from "lodash";
import { RESOURCE_NAME } from "common/authorization";
import { useHistory } from "react-router";
import { buildLink } from "common/routing";

type SubmissionTypesTableProps = Pick<
  SubmissionType,
  "id" | "name" | "intakeSource"
>;

const SubmissionTypesTable = () => {
  const history = useHistory();
  const { account, authorized } = useContext(AuthContext);
  const {
    data: currentData,
    error,
    loading,
  } = useGetSubmissionTypesQuery({
    variables: { accountId: account!.id },
    // Need to do cache-and-network because refetch queries wasn't working for submission type create
    fetchPolicy: "cache-and-network",
  });

  const canEditSubmissionType = authorized({
    resource: RESOURCE_NAME.SUBMISSION_TYPE,
    permission: "update",
  });

  if (error) {
    return <div />;
  }

  const renderActionButton = ({
    existingSubmissionType,
  }: {
    existingSubmissionType: SubmissionTypesTableProps;
  }) => {
    const actions: Array<ActionsProps> = [
      {
        label: "Edit submission type",
        disabled:
          !canEditSubmissionType ||
          existingSubmissionType.intakeSource ===
            SubmissionIntakeSource.EXTERNAL,
        onClick: () => {
          history.push({
            pathname: buildLink("submissionsBuilder", {
              submissionTypeId: existingSubmissionType.id,
            }),
            state: {
              prevLocation: history.location.pathname,
            },
          });
        },
      },
    ];

    return <DropdownMenu actions={actions} isTableAction />;
  };

  const columns: Array<ColumnDef<SubmissionTypesTableProps>> = [
    {
      id: "name",
      header: "Submission type",
      accessorKey: "name",
      enableSorting: false,
    },
    {
      id: "intakeSource",
      header: "Display setting",
      accessorKey: "intakeSource",
      enableSorting: false,
      cell: ({ row }) => startCase(row.original.intakeSource),
    },
    {
      id: "actions",
      size: 75,
      maxSize: 75,
      enableSorting: false,
      cell: row =>
        renderActionButton({
          existingSubmissionType: row.row.original,
        }),
    },
  ];

  return (
    <Table<SubmissionTypesTableProps>
      columns={columns}
      currentData={currentData?.account?.submissionTypes ?? []}
      loadingDetails={{
        loading,
        loadingText: "Loading Submission Types...",
        noDataText: "No Submission Types Found",
      }}
      tableStyleDetails={{
        hasRowActions: true,
        isCompact: true,
        hasFlushedSides: true,
      }}
      excludePaginationNav
    />
  );
};

export default SubmissionTypesTable;
