import React from "react";
import { CustomSelect } from "../../../../../../Submissions/Widgets";
import BaseWidgetPreview from "./BaseWidgetPreview";
import { WidgetProps } from "@rjsf/utils";
import { isEmpty } from "lodash";

const SelectWidgetPreview = (props: WidgetProps) => {
  const filteredEnumOptions =
    props.options.enumOptions?.filter(
      option => !isEmpty(option.label) || !isEmpty(option.value)
    ) ?? [];

  const widgetPreview = (
    <CustomSelect
      {...props}
      options={{ ...props.options, enumOptions: filteredEnumOptions }}
    />
  );
  return <BaseWidgetPreview widgetPreview={widgetPreview} {...props} />;
};

export default SelectWidgetPreview;
