import React from "react";
import { useModal } from "react-modal-hook";
import { gql } from "@apollo/client";

import Modal from "../../Common/Modal";
import { Select, Checkbox, Text } from "../../Inputs/react-hook-form";
import { Label, Text as NonHookText } from "../../Inputs";
import { Button } from "../../Common/Button";

import { AuthContext } from "../../Authorization/AuthContext";
import {
  InformationRequestContactType,
  useSharePropertyMutation,
} from "../../../generated/graphql";
import { track } from "../../../utils/tracking";
import { buildResidentPropertyURL } from "common/utils/url";

import "./__styles__/SharePropertyInfoModal.scss";
import {
  ButtonSection,
  Container,
  ContentSection,
  FormSection,
  HeaderSection,
  PrimaryButtons,
  SecondaryButtons,
  InputRow,
} from "../../Common/__styles__/Modal";
import { useForm } from "react-hook-form";
import { isValidEmail } from "common/utils/strings";
import { SuccessView } from "../../Common/ShareItem/SuccessView";
import { CONTACT_TYPE_OPTIONS } from "../../Common/ShareItem/utils";

import { useStatusToasts } from "../../../hooks/useStatusToasts";
type SharePropertyLog = { id: string };

export const SHARE_PROPERTY = gql`
  mutation ShareProperty($data: PropertyShareInput!) {
    shareProperty(data: $data) {
      log {
        id
      }
    }
  }
`;

interface SharePropertyModalHook {
  onSubmit: () => void;
  onCancel: () => void;
  property: any;
}
export const useSharePropertyInfoModal = ({
  onSubmit,
  onCancel,
  property,
}: SharePropertyModalHook) => {
  const [showSharePropertyModal, hideSharePropertyModal] = useModal(
    () => (
      <Modal
        onRequestClose={() => {
          onCancel();
        }}
      >
        <SharePropertyInfoForm
          onSubmit={onSubmit}
          onCancel={onCancel}
          property={property}
        />
      </Modal>
    ),
    [onSubmit, property]
  );

  return [showSharePropertyModal, hideSharePropertyModal] as const;
};

type SharePropertyFormStructure = {
  property: string;
  recipientFirstName: string;
  recipientLastName: string;
  recipientEmail: string;
  recipientType: InformationRequestContactType;
  copyYourself: boolean;
  createLog: boolean;
};

export type SharePropertyInfoFormProps = {
  clipboard?: Pick<typeof navigator.clipboard, "writeText">;
  urlOpener?: (path: string, mode: string) => void;
} & SharePropertyModalHook;
const SharePropertyInfoForm = ({
  onCancel,
  property,
  urlOpener = window.open,
  clipboard = navigator.clipboard,
}: SharePropertyInfoFormProps) => {
  const { account, user, admin } = React.useContext(AuthContext);
  const { addSuccessToast, addErrorToast } = useStatusToasts();
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [residentLog, setResidentLog] =
    React.useState<Maybe<SharePropertyLog>>(null);

  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    register,
  } = useForm<SharePropertyFormStructure>({
    defaultValues: {
      recipientType: InformationRequestContactType.PROPERTY_OWNER,
      createLog: true,
      copyYourself: true,
    },
  });

  const [shareProperty, { loading }] = useSharePropertyMutation({
    onCompleted: response => {
      let log: Maybe<SharePropertyLog> = null;
      if (response?.shareProperty?.log?.id) {
        log = { id: response.shareProperty.log.id };
      }

      setResidentLog(log);
      setShowSuccess(true);
    },
    onError: () => {
      addErrorToast(
        `There was an error sharing this property. Please try again. If the problem persists, please email us at support@withforerunner.com`
      );
    },
  });

  const onSubmit = async (formData: SharePropertyFormStructure) => {
    if (loading) return;

    const data = {
      ...formData,
      propertyId: property.id,
    };

    track("Emailed Link", {
      streetAddress: property.streetAddress,
      city: property.city,
      logCreated: data.createLog,
      userCCd: data.copyYourself,
    });
    await shareProperty({ variables: { data } });
  };

  if (showSuccess) {
    return (
      <SuccessView
        headerText="Share property info"
        recipientEmail={getValues("recipientEmail")}
        residentLogId={residentLog?.id}
        onClose={onCancel}
      />
    );
  }

  const propertyURL = buildResidentPropertyURL({
    subdomain: account!.publicPortal.subdomain,
    protocol: window.location.protocol,
    appDomain: `${window.env.APPLICATION_DOMAIN}`,
    propertyId: property.id,
  });

  return (
    <Container overflows>
      <HeaderSection>
        <h1>Share property info</h1>
        <h2>Send a public property profile link to a recipient</h2>
      </HeaderSection>
      <FormSection overflows>
        <ContentSection overflows>
          <p>
            The recipient will receive an email with a link to a public profile
            of the property. This public link will not allow the recipient to
            edit or delete any information.
          </p>
          <InputRow>
            <NonHookText
              name="property"
              label="Property"
              value={property?.fullAddress || "No address for property"}
              disabled={true}
              size="small"
            />
          </InputRow>
          <InputRow>
            <Text
              {...register("recipientFirstName", {
                required: "Recipient's first name is required",
              })}
              error={errors.recipientFirstName?.message}
              size="small"
              label="Recipient first name"
              required
            />
            <Text
              {...register("recipientLastName", {
                required: "Recipient's last name is required",
              })}
              error={errors.recipientLastName?.message}
              size="small"
              label="Recipient last name"
              required
            />
          </InputRow>
          <InputRow style={{ lineHeight: "1" }}>
            <Text
              {...register("recipientEmail", {
                validate: val => {
                  if (!val) {
                    return "Required field";
                  }
                  if (!isValidEmail(val)) {
                    return "Email is invalid";
                  }
                  return;
                },
              })}
              error={errors.recipientEmail?.message}
              size="small"
              label="Recipient email"
              required
            />
            <Select
              control={control}
              name="recipientType"
              label="Recipient type"
              required
              options={CONTACT_TYPE_OPTIONS}
            />
          </InputRow>
          <fieldset styleName="fieldset">
            <Label text="Additional actions" />
            <div styleName="checkbox-row">
              <Checkbox {...register("copyYourself")} id="copyYourself" />
              <Label
                text={`Copy yourself on email (${user?.email ?? admin?.email})`}
                htmlFor="copyYourself"
                styleName="checkbox-label"
                data-testid="copy-yourself-label"
              />
            </div>

            <div styleName="checkbox-row">
              <Checkbox {...register("createLog")} id="createLog" />
              <Label
                text="Create new resident log"
                htmlFor="createLog"
                styleName="checkbox-label"
              />
            </div>
          </fieldset>
        </ContentSection>
        <ButtonSection>
          <SecondaryButtons>
            <Button
              onClick={() => {
                track("Clicked view property button", {
                  streetAddress: property.streetAddress,
                  city: property.city,
                });

                urlOpener(propertyURL, "_blank");
              }}
              size="medium"
              styleVariant="ghost"
              leftIconName="eye"
              type="button"
            >
              View public profile
            </Button>
            <Button
              onClick={async () => {
                track("Copied Link", {
                  streetAddress: property.streetAddress,
                  city: property.city,
                });

                await clipboard.writeText(propertyURL);
                addSuccessToast("Link copied to clipboard");
              }}
              size="medium"
              styleVariant="ghost"
              leftIconName="link"
              type="button"
            >
              Copy url link
            </Button>
          </SecondaryButtons>
          <PrimaryButtons>
            <Button
              onClick={handleSubmit(onSubmit)}
              disabled={loading}
              size="medium"
              styleVariant="primary"
              type="button"
            >
              Send
            </Button>
          </PrimaryButtons>
        </ButtonSection>
      </FormSection>
    </Container>
  );
};

export default SharePropertyInfoForm;
