import React from "react";
import { DropdownMenu } from "../../../Inputs";
import { ActionsProps } from "../../../Inputs/DropdownMenu";
import { AccountBaseMapSettings } from "./__queries__/getAccountBaseMaps";
import {
  useDeleteBaseMapMutation,
  useUpdateDefaultBaseMapMutation,
} from "../../../../generated/graphql";
import { useStatusToasts } from "../../../../hooks/useStatusToasts";
import { useEditBaseMapModal } from "./EditBaseMapModal";
import { SATELLITE_BASE_MAP, SATELLITE_BASE_MAP_URL } from "common/constants";

export const ActionButton = ({
  baseMap,
  refetchQueries = ["GetAccountBaseMaps"],
}: {
  baseMap: AccountBaseMapSettings;
  refetchQueries?: Array<string>;
}) => {
  const [deleteBaseMap] = useDeleteBaseMapMutation();
  const [updateDefaultBaseMap, { loading: changingDefault }] =
    useUpdateDefaultBaseMapMutation();
  const [showEditBaseMapModal] = useEditBaseMapModal({ baseMap });
  const { addSuccessToast, addErrorToast } = useStatusToasts();

  const onDelete = async () => {
    await deleteBaseMap({
      variables: {
        id: baseMap.id,
      },
      onCompleted: () => {
        addSuccessToast(`${baseMap.name} deleted successfully`);
      },
      onError: error => {
        addErrorToast(error.message);
      },
      refetchQueries,
    });
  };

  const makeDefault = async () => {
    await updateDefaultBaseMap({
      variables: {
        id: baseMap.id === SATELLITE_BASE_MAP_URL ? null : baseMap.id,
      },
      onCompleted: () => {
        addSuccessToast(`${baseMap.name} is now the default base map`);
      },
      onError: error => {
        addErrorToast(error.message);
      },
    });
  };

  const actions: Array<ActionsProps> = [
    {
      label: "Edit",
      disabled: !baseMap.editable,
      onClick: showEditBaseMapModal,
    },
    {
      label: "Make default",
      disabled:
        (baseMap.id !== SATELLITE_BASE_MAP.id && !baseMap.editable) ||
        changingDefault,
      onClick: () => {
        void makeDefault();
      },
    },
    {
      label: "Delete",
      variant: "red",
      disabled: !baseMap.editable,
      onClick: () => {
        void onDelete();
      },
    },
  ];

  return <DropdownMenu actions={actions} />;
};
