import React from "react";

import { DamageDegree } from "../../../../../../Submissions/Widgets";

import BaseWidgetPreview from "./BaseWidgetPreview";
import { WidgetProps } from "@rjsf/utils";

const DamageDegreeWidgetPreview = (props: WidgetProps) => {
  const widgetPreview = <DamageDegree {...props} />;

  return (
    <BaseWidgetPreview
      widgetPreview={widgetPreview}
      {...props}
    ></BaseWidgetPreview>
  );
};

export default DamageDegreeWidgetPreview;
