import React, { useCallback } from "react";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router";

import {
  useCreatePropertyMutation,
  useGetParcelQuery,
} from "../../generated/graphql";
import PropertyForm, { ActionProps, FormData } from "./PropertyForm";
import { track } from "../../utils/tracking";
import Icon, { ICON_COLORS, Icons } from "../Common/Icons";
import CreatePropertyWarnings from "./CreatePropertyWarnings";
import { Button } from "../Common/Button";
import { formatCoordinates } from "common/utils/coordinates";
import { useStatusToasts } from "../../hooks/useStatusToasts";

const CreateProperty = () => {
  const location = useLocation<undefined | { prevLocation?: string }>();
  const history = useHistory();
  const { addSuccessToast, addErrorToast } = useStatusToasts();

  const params = queryString.parse(location.search);
  const { parcelId, latitude, longitude, zoom } = params;
  const { data, loading, error } = useGetParcelQuery({
    variables: { parcelId: parcelId as string },
    skip: !parcelId,
  });

  const [createProperty, { error: createError, loading: loadingCreate }] =
    useCreatePropertyMutation({
      onCompleted: data => {
        const property = data.createProperty;

        const propertyLocation =
          property.streetAddress ?? formatCoordinates(property);

        addSuccessToast(`Property created at ${propertyLocation}`);

        const query = {
          address: property.standardizedAddress,
          lat: property.latitude,
          lng: property.longitude,
          propertyId: property.id,
          parcelId: property.geocacheParcelId,
        };

        track("Property Created", { address: property.standardizedAddress });
        history.replace({
          pathname: "/map",
          search: `?${queryString.stringify(query)}`,
        });
      },
      onError: error => {
        const errorMessage = error.graphQLErrors
          .map(error => error.message)
          .join("\n");

        addErrorToast(errorMessage);
      },
    });

  const onSave = useCallback(async (data: FormData) => {
    await createProperty({ variables: { data } });
  }, []);

  if (loading)
    return (
      <div style={{ textAlign: "center" }}>
        <Icon icon={Icons.LOADING} color={ICON_COLORS.LIGHT_GREY} />
      </div>
    );
  if (error) return <em>Problem loading create form.</em>;

  const startingPoint =
    latitude && longitude
      ? { latitude: Number(latitude), longitude: Number(longitude) }
      : null;

  const actions = (props: ActionProps): JSX.Element => {
    return (
      <Button
        size="small"
        styleVariant="primary"
        onClick={() => props.validateInput(onSave)}
        disabled={props.disabled || loadingCreate}
      >
        Save
      </Button>
    );
  };

  const parsedZoom = parseInt(zoom as string);
  const zoomParam = isNaN(parsedZoom) ? null : parsedZoom;

  return (
    <PropertyForm
      actions={actions}
      error={createError?.message ?? null}
      parcel={data?.parcel ?? null}
      point={startingPoint}
      property={null}
      zoom={zoomParam}
      headerText={"Create new property"}
      title={"Property address"}
      subtitle={
        "Provide a few details on the property's address and select a location on the map."
      }
      warningsComponent={CreatePropertyWarnings}
      canSubmitProperty={false}
      editingProperty={false}
    />
  );
};

export default CreateProperty;
