import React, { useCallback, useEffect } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import LoginPage from "./LoginPage";
import { USER_LOGIN } from "./__queries__";

const UserLoginPage = ({ location }: RouteComponentProps) => {
  let history = useHistory();
  const handleKeyPress = useCallback(event => {
    if (event.key === "Escape") {
      history.push("/admin/login");
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <LoginPage
      loginMutation={USER_LOGIN}
      landingPage="/"
      event="Logged in"
      initialTitle="Log In"
      passwordResetPath="/password-reset"
      location={location}
    />
  );
};

export default UserLoginPage;
