import React from "react";
import { useFormContext } from "react-hook-form";
import { Text } from "../Inputs/react-hook-form";
import { Row } from "../Common/__styles__/Layout";
import { SecondaryAction } from "./__styles__/LoginPage";
import {
  UserLoginErrorCode,
  useSendMfaCodeMutation,
  useVerifyMfaCodeMutation,
} from "../../generated/graphql";
import { ErrorMessage } from "./ErrorMessage";
import {
  ButtonRow,
  Title,
} from "../Common/__styles__/FormWithFullPageBackground";
import { Button } from "../Common/Button";
import { LoginFormStructure } from "./LoginPage";
import { useStatusToasts } from "../../hooks/useStatusToasts";
import { MFAFlowAction } from "common-client/utils/useMFAFlow";

export type ProvideCodeProps = {
  allowAnotherNumber: boolean;
  allowResetRequest?: boolean;
  dispatch: React.Dispatch<MFAFlowAction>;
  error?: Maybe<{ message: string; code?: UserLoginErrorCode }>;
  maskedPhoneNumber: string;
  onSuccess: () => void;
};

export const ProvideCode = ({
  allowAnotherNumber,
  allowResetRequest = false,
  dispatch,
  error,
  maskedPhoneNumber,
  onSuccess,
}: ProvideCodeProps) => {
  const { addErrorToast, addSuccessToast } = useStatusToasts();
  const { register, handleSubmit, setValue, watch } = useFormContext();
  const [isSendCodeThrottled, setIsSendCodeThrottled] = React.useState(false);
  const [retryTimeout, setRetryTimeout] = React.useState(0);

  React.useEffect(() => {
    setIsSendCodeThrottled(true);
    const timeoutId = setTimeout(() => {
      setIsSendCodeThrottled(false);
      setRetryTimeout(0);
    }, retryTimeout * 1000);
    return () => clearTimeout(timeoutId);
  }, [retryTimeout]);

  const [sendCode] = useSendMfaCodeMutation({
    onCompleted: data => {
      if (!data.sendMFACode.error) {
        addSuccessToast("Authentication code has been re-sent.");
      }
      if (data.sendMFACode.retryTimeout) {
        setRetryTimeout(data.sendMFACode.retryTimeout);
      }
      setValue("code", "");
      dispatch({ type: "sendCode", data: data.sendMFACode });
    },
    onError: () => {
      addErrorToast(
        `There was an issue sending the code. Please try again. If the problem persists, please email us at support@withforerunner.com`
      );
    },
  });

  const [verifyCode] = useVerifyMfaCodeMutation({
    onCompleted: data => {
      if (data.verifyMFACode.ok) {
        onSuccess();
      } else {
        dispatch({ type: "verifyCode", data: data.verifyMFACode });
      }
    },
    onError: () => {
      addErrorToast(
        `There was an issue verifying the code. Please try again. If the problem persists, please email us at support@withforerunner.com`
      );
    },
  });

  const handleLogin = async (loginData: LoginFormStructure) => {
    await verifyCode({
      variables: {
        data: {
          email: loginData.email,
          password: loginData.password,
          phoneNumber: loginData.phoneNumber,
          code: loginData.code!,
        },
      },
    });
  };

  const handleResend = async (loginData: LoginFormStructure) => {
    if (retryTimeout === 0) {
      setIsSendCodeThrottled(true);
      await sendCode({
        variables: {
          data: {
            email: loginData.email,
            password: loginData.password,
            phoneNumber: loginData.phoneNumber!,
            isRetry: true,
          },
        },
      });
    }
  };

  const useAnotherPhoneNumber = () => {
    setValue("code", "");
    dispatch({ type: "anotherNumber" });
  };

  const requestReset = () => {
    dispatch({ type: "initiateResetRequest" });
  };

  const code = watch("code");
  const disabled = !code;

  return (
    <form onSubmit={handleSubmit(handleLogin)}>
      <Title>Enter authentication code</Title>
      {error && <ErrorMessage error={error} />}
      <div>
        <Row>
          Please enter the authentication code we sent to your mobile phone
          number ending in {maskedPhoneNumber}.
        </Row>
        <Row>
          <Text {...register("code")} placeholder="******" autoComplete="off" />
        </Row>
        <SecondaryAction
          onClick={handleSubmit(handleResend)}
          disabled={isSendCodeThrottled}
        >
          Resend authentication code
        </SecondaryAction>
        {allowAnotherNumber && (
          <SecondaryAction topSpacing onClick={useAnotherPhoneNumber}>
            Use another phone number
          </SecondaryAction>
        )}
        {allowResetRequest && (
          <SecondaryAction topSpacing onClick={requestReset}>
            Locked out? Request to reset your Multi-Factor Authentication
          </SecondaryAction>
        )}
      </div>
      <ButtonRow>
        <Button
          onClick={handleSubmit(handleLogin)}
          disabled={disabled}
          size="medium"
          styleVariant="primary"
        >
          Submit
        </Button>
      </ButtonRow>
    </form>
  );
};
