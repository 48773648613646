import React, { useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router";

import { useSimpleForm } from "../../../../Common/simpleForm";
import CertificateEditForm from "./CertificateEditForm";
import {
  useGetCertificateForAdminEditQuery,
  useUpdateCertificateSettingsMutation,
} from "../../../../../generated/graphql";
import { GET_CERTIFICATE_FOR_ADMIN_EDIT } from "./__queries__";

import { AppContainer } from "./__styles__/EditCertificate";
import { useStatusToasts } from "../../../../../hooks/useStatusToasts";

export interface AdminSettingsData {
  formYear: Maybe<string>;
  breakawayWalls: Maybe<string>;
}

export type Props = RouteComponentProps<{ certificateId: string }>;

export const EditCertificate = ({ match }: Props) => {
  const certificateId = match.params.certificateId;
  const { addSuccessToast, addErrorToast } = useStatusToasts();

  const [certificateData, getCertificateData, setCertificateData] =
    useSimpleForm<AdminSettingsData>();
  const [geocodeData, getGeocodeData, setGeocodeData] =
    useSimpleForm<Record<string, any>>();

  const { data, loading, error } = useGetCertificateForAdminEditQuery({
    variables: { id: certificateId },
  });

  const refetchQueries = [
    { query: GET_CERTIFICATE_FOR_ADMIN_EDIT, variables: { id: certificateId } },
  ];

  const [saveCertificate, { loading: processingUpdate }] =
    useUpdateCertificateSettingsMutation({
      refetchQueries,
      onError: () => {
        addErrorToast(`There was an issue updating the certificate.`);
      },
      onCompleted: () => {
        addSuccessToast(`Certificate updated!`);
      },
    });

  useEffect(() => {
    if (!data?.certificate) return;

    const formYear =
      data.certificate.formYear ??
      data.certificate.certificateUpload.formExpirationYear;
    setCertificateData("formYear")(formYear?.toString() ?? "");
    setCertificateData("breakawayWalls")(
      data.certificate.breakawayWalls?.toString() ?? null
    );

    const property = data.certificate.property;
    for (const [key, val] of Object.entries(property)) {
      setGeocodeData(key)(val);
    }
  }, [data || {}]);

  if (loading || error) {
    return <div />;
  }

  if (!data || !data.certificate) {
    return (
      <AppContainer>
        Certificate could not be found! This probably means the certificate was
        deleted. If you think you're seeing this page in error, please ping an
        engineer.
      </AppContainer>
    );
  }

  const account = data.certificate.property.account;

  return (
    <AppContainer>
      <CertificateEditForm
        setGeocodeData={setGeocodeData}
        getGeocodeData={getGeocodeData}
        setCertificateData={setCertificateData}
        getCertificateData={getCertificateData}
        account={account}
        certificate={data.certificate}
        processing={processingUpdate}
        saveCertificate={() =>
          saveCertificate({
            variables: {
              data: {
                id: certificateId,
                geocodeData,
                formYear: parseInt(certificateData?.formYear ?? ""),
                breakawayWalls:
                  certificateData.breakawayWalls === "blank"
                    ? null
                    : certificateData.breakawayWalls === "true",
              },
            },
          })
        }
      />
    </AppContainer>
  );
};
export default withRouter(EditCertificate);
