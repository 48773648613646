import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { RouteComponentProps, useLocation } from "react-router-dom";
import queryString from "query-string";
import { AdminLoginDocument, UserLoginDocument } from "../../generated/graphql";
import { track } from "../../utils/tracking";
import { getPreviousLocation } from "../../utils/session";
import { ProvideCredentials } from "./ProvideCredentials";
import { ProvidePhoneNumber } from "./ProvidePhoneNumber";
import { ProvideCode } from "./ProvideCode";
import FullPageBackgroundWithLogo from "../Common/FullPageBackgroundWithLogo";
import { Container } from "../Common/__styles__/FormWithFullPageBackground";
import { LoginForm } from "./__styles__/LoginPage";
import {
  MFAReducer,
  SCREEN_DISPLAY,
  useMFAFlow,
} from "common-client/utils/useMFAFlow";
import { RequestMFAResetConfirmation } from "./RequestMFAResetConfirmation";
import { SelectRequestUser } from "./SelectRequestUser";

export interface LoginPageProps {
  loginMutation: typeof AdminLoginDocument | typeof UserLoginDocument;
  passwordResetPath?: string;
  initialTitle: string;
  landingPage: string;
  event: string;
  location: RouteComponentProps["location"];
}

export type LoginFormStructure = {
  email?: Maybe<string>;
  password?: Maybe<string>;
  phoneNumber?: Maybe<string>;
  code?: Maybe<string>;
  requestedUserId?: Maybe<string>;
};

enum LOGOUT_REASON {
  INACTIVITY = "inactivity",
}

const getLogoutReasonMessage = ({
  logoutReason,
}: {
  logoutReason: Maybe<LOGOUT_REASON>;
}) => {
  switch (logoutReason) {
    case LOGOUT_REASON.INACTIVITY: {
      return {
        message:
          "You have been logged out due to inactivity. Please log back in to keep using Forerunner.",
      };
    }
    default: {
      return null;
    }
  }
};

const LoginPage = (props: LoginPageProps) => {
  const location = useLocation();
  const { loginMutation, passwordResetPath, initialTitle, landingPage, event } =
    props;
  const useFormProps = useForm<LoginFormStructure>();

  const onLoginSuccess = () => {
    track(event);
    window.location = getPreviousLocation(props, window, landingPage);
  };

  const [state, dispatch] = useMFAFlow({
    defaultValue: {
      currentScreen: SCREEN_DISPLAY.PROVIDE_CREDENTIALS,
      error: null,
    },
    useReducer: React.useReducer as MFAReducer,
  });

  const logoutReason = queryString.parse(location.search)
    .logoutReason as Maybe<LOGOUT_REASON>;

  const error = state.error || getLogoutReasonMessage({ logoutReason });

  return (
    <FullPageBackgroundWithLogo>
      <Container>
        <FormProvider {...useFormProps}>
          <LoginForm>
            {state.currentScreen === SCREEN_DISPLAY.PROVIDE_CREDENTIALS && (
              <ProvideCredentials
                dispatch={dispatch}
                error={error}
                loginMutation={loginMutation}
                onSuccess={onLoginSuccess}
                passwordResetPath={passwordResetPath}
                title={initialTitle}
              />
            )}
            {state.currentScreen === SCREEN_DISPLAY.PROVIDE_PHONE && (
              <ProvidePhoneNumber dispatch={dispatch} error={error} />
            )}
            {state.currentScreen === SCREEN_DISPLAY.PROVIDE_CODE && (
              <ProvideCode
                allowAnotherNumber={!state.phoneNumberVerified!}
                allowResetRequest={state.phoneNumberVerified!}
                dispatch={dispatch}
                error={error}
                maskedPhoneNumber={state.phoneNumber!}
                onSuccess={onLoginSuccess}
              />
            )}
            {state.currentScreen === SCREEN_DISPLAY.REQUEST_RESET && (
              <SelectRequestUser dispatch={dispatch} error={error} />
            )}
            {state.currentScreen === SCREEN_DISPLAY.CONFIRM_RESET && (
              <RequestMFAResetConfirmation />
            )}
          </LoginForm>
        </FormProvider>
      </Container>
    </FullPageBackgroundWithLogo>
  );
};

export default LoginPage;
