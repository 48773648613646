import { gql } from "@apollo/client";
import { LogsQuery } from "../../../generated/graphql";

export const LOGS = gql`
  query Logs {
    informationRequests {
      id
      submittedAt
      address
      type
      firmPanel
      coastalOrCBRS
      pastFlood
      erosion
      insuranceInfoGiven
      status
      property {
        id
        city
        state
        fullAddress
        latitude
        longitude
      }
    }
  }
`;

export type InformationRequestsForList = LogsQuery["informationRequests"];
export type InformationRequestFromList =
  LogsQuery["informationRequests"][number];

export const DELETE_LOG = gql`
  mutation deleteInfoRequestLog($id: ID!) {
    deleteInformationRequest(id: $id)
  }
`;

export const CREATE_LOG = gql`
  mutation createLog($data: InformationRequestInput!) {
    createInformationRequest(data: $data) {
      id
    }
  }
`;

export const PROPERTY_FOR_LOG = gql`
  query getPropertyForLogForm($propertyId: ID) {
    getProperty(id: $propertyId) {
      id
      fullAddress
      firms {
        floodzone
        stringStaticBFE
        stringDepth
        panelNumber
        type
        coastalA
        cbrs
      }
    }
  }
`;

export const LOG_DETAILS_FRAGMENT = gql`
  fragment LogDetailsFragment on InformationRequest {
    id
    name
    email
    phone
    address
    requestedHelpWith
    internalNotes
    submittedAt
    type
    firmPanel
    zone
    stringBfe
    stringBfeDepth
    insuranceInfoGiven
    siteVisit
    propertyProtectionAdvice
    financialAdvice
    coastalOrCBRS
    pastFlood
    erosion
    status
    propertyId
    viaPublicSite
    sharedViaEmail
  }
`;

export const LOG = gql`
  query Log($id: String!) {
    informationRequest(id: $id) {
      ...LogDetailsFragment
    }

    ${LOG_DETAILS_FRAGMENT}
  }
`;

export const UPDATE_LOG = gql`
  mutation UpdateLog($id: String!, $data: InformationRequestInput!) {
    updateInformationRequest(id: $id, data: $data) {
      ...LogDetailsFragment
    }

    ${LOG_DETAILS_FRAGMENT}
  }
`;

export const EXPORT_LOGS = gql`
  mutation ExportLogs($startDate: Date, $endDate: Date) {
    exportLogs(startDate: $startDate, endDate: $endDate)
  }
`;
