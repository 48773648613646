import React from "react";
import { useForm } from "react-hook-form";

import { Button } from "../../Common/Button";
import { Text } from "../../Inputs/react-hook-form";

import { Section } from "../__styles__/Content";
import { PHONE_REGEX, isValidEmail } from "common/utils/strings";

interface FormStructure {
  name?: Maybe<string>;
  email?: Maybe<string>;
  phoneNumber?: Maybe<string>;
  title?: Maybe<string>;
}

export const ContactInformationForm = ({
  contactInformation,
  canUpdateAccountInformation,
  onUpdate,
}: {
  contactInformation: FormStructure;
  canUpdateAccountInformation: boolean;
  onUpdate: ({ variables }: { variables: { data: FormStructure } }) => void;
}) => {
  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors },
  } = useForm<FormStructure>({
    defaultValues: contactInformation,
  });

  const validateTitleMessage =
    "If title is provided you must provide either a name, phone, or email";

  return (
    <form
      onSubmit={handleSubmit(data => {
        onUpdate({
          variables: {
            data: {
              name: data.name,
              email: data.email,
              phoneNumber: data.phoneNumber,
              title: data.title,
            },
          },
        });
      })}
    >
      <Section>
        <h2>Contact representative information</h2>
        <p>
          This contact information will appear at the top of your public
          website’s Get Help page for residents to reach out directly.
        </p>
      </Section>
      <Section grid>
        <Text label="Name" error={errors.name?.message} {...register("name")} />
        <Text
          label="Title"
          error={errors.title?.message}
          {...register("title", {
            validate: (val: Maybe<string | undefined>) => {
              if (
                val &&
                !getValues("name") &&
                !getValues("email") &&
                !getValues("phoneNumber")
              ) {
                return validateTitleMessage;
              }
              return;
            },
          })}
        />
        <Text
          label="Phone"
          error={errors.phoneNumber?.message}
          {...register("phoneNumber", {
            validate: (val: Maybe<string | undefined>) => {
              if (val && !PHONE_REGEX.test(val)) {
                return "Phone number is invalid. Please use the following format: 123-456-7890";
              }
              return;
            },
          })}
        />
        <Text
          label="Email"
          error={errors.email?.message}
          {...register("email", {
            validate: (val: Maybe<string | undefined>) => {
              if (val && !isValidEmail(val)) {
                return "Email is invalid";
              }
              return;
            },
          })}
        />
      </Section>
      <Section>
        <Button
          styleVariant="primary"
          size="medium"
          type="submit"
          disabled={!canUpdateAccountInformation}
        >
          Update public website settings
        </Button>
      </Section>
    </form>
  );
};
