import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useStatusToasts } from "../../hooks/useStatusToasts";

import FullPageFormLayout from "../Common/FullPageFormLayout";
import { Select } from "../Inputs";
import { Text } from "../Inputs/react-hook-form";

import { AuthContext } from "../Authorization/AuthContext";
import {
  CleanUpAddressQuery,
  useCleanUpAddressLazyQuery,
  useGetParcelFromPropertyLazyQuery,
  useGetParcelsFromPointLazyQuery,
  useGetParcelsFromPointQuery,
} from "../../generated/graphql";
import useSelectAddressModal from "./SelectAddressModal";
import { Point, Property as LayeredMapProperty } from "../Maps/LayeredMap";
import Map, { ParcelOption } from "./PropertyFormMap";

import {
  Container,
  LeftPane,
  RenderedCoordinates,
  RightPane,
} from "./__styles__/PropertyForm";
import {
  Header,
  Section,
  SectionDivider,
} from "../Common/__styles__/FullPageFormLayout";
import { SearchResultProps } from "../Search";
import { capitalizeAddress } from "common/utils/strings";
import { formatCoordinates } from "common/utils/coordinates";
import {
  CreateWarningProps,
  determineHasPartialAddress,
} from "common-client/utils/createAndEditPropertyWarnings";
import { useForm } from "react-hook-form";
import { pick } from "lodash";

export type Parcel = { id: string; parcelNumber?: Maybe<string> };

export type FormData = {
  address?: string;
  longitude: number;
  latitude: number;
  geocacheParcelId: Maybe<string>;
};

type PropertyFormStructure = {
  streetAddress?: Maybe<string>;
  city?: Maybe<string>;
  state?: Maybe<string>;
  zipcode?: Maybe<string>;
};

export type ActionProps = {
  validateInput: (func: (data: FormData) => void) => void;
  disabled: boolean;
};

const PropertyForm = ({
  headerText,
  title,
  subtitle,
  property,
  zoom,
  point: startingPoint,
  parcel: startingParcel,
  error: propertyActionError,
  warningsComponent,
  canSubmitProperty,
  editingProperty,
  actions,
}: {
  headerText: string;
  title: string;
  subtitle: string;
  property: Maybe<PropertyFormStructure & { id?: string }>;
  zoom: Maybe<number>;
  point: Maybe<Point>;
  parcel: Maybe<Parcel>;
  error: Maybe<string>;
  warningsComponent: (props: CreateWarningProps) => JSX.Element;
  canSubmitProperty: boolean;
  editingProperty: boolean;
  actions: (props: ActionProps) => JSX.Element;
}) => {
  const location = useLocation<undefined | { prevLocation?: string }>();
  const prevLocation = location.state?.prevLocation ?? "/map";
  const { account } = useContext(AuthContext);
  const { addErrorToast } = useStatusToasts();

  const [error, setError] = useState<Maybe<string>>(null);
  const [parcels, setParcels] = useState<Array<Parcel>>(
    startingParcel ? [startingParcel] : []
  );
  const [parcel, setParcel] = useState<Maybe<Parcel>>(startingParcel ?? null);
  const [point, setPoint] = useState<Maybe<Point>>(startingPoint ?? null);
  const [address, setAddress] =
    useState<Maybe<PropertyFormStructure>>(property);
  const [propertyId, setPropertyId] = useState<Maybe<string>>(
    property?.id ?? null
  );

  useGetParcelsFromPointQuery({
    fetchPolicy: "network-only",
    variables: { ...startingPoint! },
    skip: !startingPoint || !!startingParcel,
    onCompleted: data => {
      const pointParcels = data.parcels.filter<ParcelOption>(
        (parcel): parcel is ParcelOption => {
          return !!parcel.parcelNumber;
        }
      );
      setParcels(pointParcels);
    },
  });

  const [hasPartialAddress, setHasPartialAddress] = useState<boolean>(
    property ? determineHasPartialAddress(property) : false
  );

  useEffect(() => {
    if (error) {
      setError(null);
    }
  }, [point]);

  useEffect(() => {
    setError(propertyActionError);
  }, [propertyActionError]);

  useEffect(() => {
    setParcel(parcels[0] ?? null);
  }, [parcels]);

  useEffect(() => {
    setValue("streetAddress", address?.streetAddress);
    setValue("city", address?.city);
    setValue("state", address?.state);
    setValue("zipcode", address?.zipcode);
  }, [address]);

  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm<PropertyFormStructure>({
    defaultValues: pick(property, [
      "streetAddress",
      "city",
      "state",
      "zipcode",
    ]),
  });

  const [showSelectAddressModal, hideSelectAddressModal] =
    useSelectAddressModal();

  useEffect(() => {
    const { unsubscribe } = watch(allValues => {
      setError(null);

      setHasPartialAddress(determineHasPartialAddress(allValues));
    });
    return () => unsubscribe();
  }, [watch]);
  const addressValues = watch();

  const returnFormData = (address?: string) => {
    return {
      address,
      ...point!,
      geocacheParcelId: parcel?.id ?? null,
    };
  };

  const [cleanUpAddress, { loading: cleaningUpAddress }] =
    useCleanUpAddressLazyQuery({
      // if we cache this, it means you can't
      // clean up the same address twice and
      // expect to get the onCompleted handler called
      fetchPolicy: "no-cache",
    });

  const [getAllParcelsFromPoint] = useGetParcelsFromPointLazyQuery();
  const [getParcelFromProperty] = useGetParcelFromPropertyLazyQuery();

  const handleSearchResult = async (data: SearchResultProps) => {
    let {
      streetAddress,
      city,
      state,
      zip: zipcode,
      geocacheParcelId,
      propertyId,
      point,
    } = data;
    streetAddress = streetAddress && capitalizeAddress(streetAddress);
    city = city && capitalizeAddress(city);
    state = state && capitalizeAddress(state);

    let parcel: Maybe<Parcel>;
    let parcels: Array<Parcel>;
    if (propertyId) {
      const { data: propertyParcel } = await getParcelFromProperty({
        variables: {
          propertyId,
        },
      });
      parcel = propertyParcel?.parcel ?? null;
      parcels = parcel ? [parcel] : [];
    } else {
      const { data: parcelsData } = await getAllParcelsFromPoint({
        variables: { ...point },
      });

      parcels = parcelsData?.parcels ?? [];
      parcel =
        (geocacheParcelId &&
          parcels.find(parcel => parcel.id === geocacheParcelId)) ||
        parcels[0] ||
        null;
    }
    setParcels(parcels);
    setParcel(parcel);
    setAddress({ streetAddress, city, state, zipcode });
    setPoint(point);
    setPropertyId(propertyId ?? null);
  };

  const onPropertyClick = (data?: LayeredMapProperty) => {
    if (data) {
      setAddress({
        streetAddress: data.address ?? null,
        city: data.city,
        state: data.state,
        zipcode: data.zipcode,
      });
      setPropertyId(data.id);
    } else if (propertyId) {
      setPropertyId(null);
      setAddress(null);
    }
  };

  const validateInput = (propertyAction: (data: FormData) => void) => {
    return handleSubmit(async (variables: PropertyFormStructure) => {
      let data: CleanUpAddressQuery | undefined;
      if (
        variables.streetAddress &&
        variables.city &&
        variables.state &&
        variables.zipcode
      ) {
        data = (
          await cleanUpAddress({
            variables: {
              streetAddress: variables.streetAddress,
              city: variables.city,
              state: variables.state,
              zipcode: variables.zipcode,
            },
          })
        ).data;
      }

      const { standardizedAddress, suggestedAddress, fullAddress } =
        data?.cleanUpAddress ?? {};

      if (!standardizedAddress && fullAddress) {
        let errorMessage = "Invalid address.";
        errorMessage += suggestedAddress
          ? ` Did you mean ${suggestedAddress}?`
          : "";

        addErrorToast(errorMessage);

        setError(errorMessage);
      } else if (
        (fullAddress &&
          (!suggestedAddress || suggestedAddress === fullAddress)) ||
        !fullAddress
      ) {
        hideSelectAddressModal();
        propertyAction(returnFormData(fullAddress));
      } else if (fullAddress && suggestedAddress) {
        showSelectAddressModal({
          suggestedAddress,
          fullAddress,
          onSelect: (address: string) => {
            hideSelectAddressModal();
            propertyAction(returnFormData(address));
          },
        });
      }
    })();
  };

  const disableInputs = !editingProperty && !!propertyId;

  const disableSubmit =
    !point ||
    cleaningUpAddress ||
    (!canSubmitProperty && !!propertyId) ||
    hasPartialAddress;

  const parcelOptions = parcels.map(parcel => {
    return {
      value: parcel.id,
      label: parcel.parcelNumber ?? parcel.id,
      ...parcel,
    };
  });

  return (
    <FullPageFormLayout
      subtitle={headerText}
      prevLocation={prevLocation}
      rightContainer={actions({ validateInput, disabled: disableSubmit })}
      centered={false}
      width={"auto"}
    >
      <Container>
        <LeftPane fixed>
          <form>
            <Header>
              <h1>{title}</h1>
              <h2>{subtitle}</h2>
            </Header>
            <Section>
              <Text
                label="Street Address"
                size="small"
                error={errors.streetAddress?.message}
                hasErrorWithoutText={hasPartialAddress}
                disabled={disableInputs}
                {...register("streetAddress")}
              />
              <Section grid>
                <Text
                  label="City"
                  size="small"
                  error={errors.city?.message}
                  hasErrorWithoutText={hasPartialAddress}
                  disabled={disableInputs}
                  {...register("city")}
                />
                <Text
                  label="State"
                  size="small"
                  error={errors.state?.message}
                  hasErrorWithoutText={hasPartialAddress}
                  disabled={disableInputs}
                  {...register("state")}
                />
                <Text
                  label="Zip"
                  size="small"
                  error={errors.zipcode?.message}
                  hasErrorWithoutText={hasPartialAddress}
                  disabled={disableInputs}
                  {...register("zipcode")}
                />
              </Section>
              <Select
                label="Parcel ID"
                value={parcel?.id ?? null}
                name="parcel"
                onChange={parcelId => {
                  const selectedParcel = parcels.find(
                    parcel => parcel.id === parcelId
                  );

                  setParcel(selectedParcel ?? null);
                }}
                options={parcelOptions}
                disabled={disableInputs || parcels.length < 2}
              />
              {point && (
                <RenderedCoordinates>
                  <p>Property Coordinates</p>
                  <p>{formatCoordinates({ ...point })}</p>
                </RenderedCoordinates>
              )}
            </Section>
            <SectionDivider />
            {warningsComponent({
              point,
              overlap: parcels.length > 1,
              geocacheParcelId: parcel?.id ?? null,
              error,
              formAddress: addressValues,
              selectedProperty: !!propertyId,
            })}
          </form>
        </LeftPane>
        <RightPane>
          <Map
            setProperty={editingProperty ? null : onPropertyClick}
            handleSearchResult={
              editingProperty ? undefined : handleSearchResult
            }
            addressOnly={true}
            account={account!}
            setParcels={setParcels}
            marker={point}
            setMarker={setPoint}
            latitude={Number(startingPoint?.latitude)}
            longitude={Number(startingPoint?.longitude)}
            zoom={Number(zoom)}
            parcel={parcel}
          />
        </RightPane>
      </Container>
    </FullPageFormLayout>
  );
};

export default PropertyForm;
