import React, { useEffect } from "react";
import { withRouter } from "react-router";
import { pick } from "lodash";

import { useSimpleForm } from "../../../../Common/simpleForm";
import PropertyEditForm from "./PropertyEditForm";
import {
  Property,
  useAdminMergePropertyMutation,
  useAdminUpdatePropertyMutation,
  useGetPropertyForPropertyEditQuery,
  GetPropertyForPropertyEditDocument,
} from "../../../../../generated/graphql";
import { AppContainer } from "./__styles__/EditProperty";
import { useStatusToasts } from "../../../../../hooks/useStatusToasts";

type InputType = Pick<
  Property,
  "id" | "city" | "streetAddress" | "zipcode" | "state" | "parcel"
> & { ignoreParcel: boolean; latitude: number; longitude: number };

const sanitizeInput = (input: InputType) => {
  const sanitizedInput = pick(input, [
    "id",
    "latitude",
    "longitude",
    "city",
    "streetAddress",
    "zipcode",
    "ignoreParcel",
    "state",
  ]);

  return {
    ...sanitizedInput,
    geocacheParcelId: input.parcel?.id,
  };
};

export const EditProperty = ({
  match,
}: {
  match: { params: { propertyId: string } };
}) => {
  const propertyId = match.params.propertyId;
  const { addSuccessToast, addErrorToast } = useStatusToasts();
  const [input, get, set] = useSimpleForm();

  const { data, loading, error } = useGetPropertyForPropertyEditQuery({
    variables: { id: propertyId },
  });

  const refetchQueries = [
    {
      query: GetPropertyForPropertyEditDocument,
      variables: { id: propertyId },
    },
  ];

  const onError = (gerund: string) => (error: Error) => {
    addErrorToast(`There was an issue ${gerund} the property: ${error}`);
  };

  const onCompleted = (pastParticiple: string) => () => {
    addSuccessToast(`Property ${pastParticiple}!`);
  };

  const [saveProperty, { loading: processingUpdate }] =
    useAdminUpdatePropertyMutation({
      refetchQueries,
      onError: onError("saving"),
      onCompleted: onCompleted("saved"),
    });

  const [mergeProperty, { loading: processingMerge }] =
    useAdminMergePropertyMutation({
      refetchQueries,
      onError: onError("merging"),
      onCompleted: onCompleted("merged"),
    });

  useEffect(() => {
    const property = data?.getProperty || {};

    for (const [key, val] of Object.entries(property)) {
      set(key)(val);
    }
  }, [data || {}]);

  if (loading || error || !data?.getProperty) {
    return <div />;
  }

  const account = data.getProperty.account;

  const property = data.getProperty;

  return (
    <AppContainer>
      {property && (
        <PropertyEditForm
          set={set}
          get={get}
          property={data!.getProperty}
          account={account}
          processing={processingMerge || processingUpdate}
          saveProperty={() =>
            saveProperty({
              variables: { data: sanitizeInput(input as InputType) },
            })
          }
          mergeProperty={(childId: string) =>
            mergeProperty({
              variables: {
                data: { parentId: propertyId, childIds: [childId] },
              },
            })
          }
        />
      )}
    </AppContainer>
  );
};
export default withRouter(EditProperty);
