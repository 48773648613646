import React from "react";

import BaseFieldPreview from "./BaseFieldPreview";
import { PropertyMarketValueInput } from "../../../../../../Submissions/Fields";
import { FieldProps } from "@rjsf/utils";

const PropertyMarketValueFieldPreview = (props: FieldProps) => {
  const widgetPreview = <PropertyMarketValueInput {...props} />;

  return (
    <BaseFieldPreview
      widgetPreview={widgetPreview}
      {...props}
    ></BaseFieldPreview>
  );
};

export default PropertyMarketValueFieldPreview;
