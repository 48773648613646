import { hot } from "react-hot-loader/root";
import React from "react";
import { Switch, Route } from "react-router-dom";

import UserInvite from "./UserInvite";
import AuthRoute from "./Authorization/AuthRoute";
import PasswordReset from "./Authentication/PasswordReset";
import AppSelector from "./AppSelector";
import { getPath } from "common/routing";
import AdminLoginPage from "./Authentication/AdminLoginPage";
import UserLoginPage from "./Authentication/UserLoginPage";

// we have to export this in isolation so that hot reloading works
export const MainRoutes = hot(() => (
  <Switch>
    <Route path={getPath("login")} component={UserLoginPage} />
    <Route path={getPath("adminLogin")} component={AdminLoginPage} />
    <Route path={getPath("acceptInvite")} component={UserInvite} />
    <Route path={getPath("passwordReset")} component={PasswordReset} />
    <AuthRoute path={getPath("root")} component={AppSelector} />
  </Switch>
));
