import React, { useCallback, useContext, useEffect } from "react";
import { map } from "lodash";
import { useHistory } from "react-router-dom";
import queryString from "query-string";

import Overview from "./PropertyOverview";
import DocumentUploads from "./DocumentUploads";
import Activities from "./Activity";
import Header from "./Header";
import TabGroup from "../Common/TabGroup";
import useNavigationTab from "../../hooks/useNavigationTabs";
import { useGetOrCreatePropertyQuery } from "../../generated/graphql";
import { hasCoordinates } from "./utils";

import {
  Container,
  Section,
  TabContainer,
  Wrapper,
} from "./__styles__/AddressPanel";
import { Records } from "./Records";
import { ADDRESS_PANEL_TAB_NAME } from "common/routing";
import { LayerContext } from "../Maps/layers";
import { RESOURCE_NAME } from "common/authorization";
import { AuthContext } from "../Authorization/AuthContext";
import { HeaderParcel } from "./Header/types";
import { AddressPanelContext } from "./AddressPanelContext";

export type AddressPanelTabProps = {
  address?: Maybe<string>;
  latitude: number;
  longitude: number;
};

const TabBody = (
  props: AddressPanelTabProps & { tab: ADDRESS_PANEL_TAB_NAME }
) => {
  const { tab, ...childProps } = props;

  return (
    <>
      {tab === ADDRESS_PANEL_TAB_NAME.OVERVIEW && <Overview {...childProps} />}
      {tab === ADDRESS_PANEL_TAB_NAME.ACTIVITY && (
        <Wrapper>
          <Section>
            <Activities {...childProps} />
          </Section>
        </Wrapper>
      )}
      {tab === ADDRESS_PANEL_TAB_NAME.RECORDS && <Records {...childProps} />}

      {tab === ADDRESS_PANEL_TAB_NAME.DOCUMENT_UPLOADS && (
        <Wrapper>
          <Section>
            <DocumentUploads {...childProps} />
          </Section>
        </Wrapper>
      )}
    </>
  );
};

export interface AddressPanelProps {
  address?: Maybe<string>;
  onClose: () => void;
  longitude: number;
  latitude: number;
  propertyId?: Maybe<string>;
  parcelId?: Maybe<string>;
  parcelNumber?: Maybe<string>;
  hasPropertyIds?: Maybe<boolean>;
}

type QueryParams = {
  address?: Maybe<string>;
  lng?: Maybe<number>;
  lat?: Maybe<number>;
  tab?: string;
  propertyId?: string;
  parcelNumber?: Maybe<string>;
  parcelId?: Maybe<string>;
  hasPropertyIds?: Maybe<string>;
};

const AddressPanel = ({
  address,
  onClose,
  longitude,
  latitude,
  propertyId,
  parcelId,
  parcelNumber,
  hasPropertyIds,
}: AddressPanelProps) => {
  const history = useHistory();
  const { updateMap } = useContext(LayerContext);
  const { authorized } = useContext(AuthContext);

  const tabs = [
    { value: ADDRESS_PANEL_TAB_NAME.OVERVIEW, label: "Overview" },
    { value: ADDRESS_PANEL_TAB_NAME.ACTIVITY, label: "Activity" },
    { value: ADDRESS_PANEL_TAB_NAME.RECORDS, label: "Records" },
    { value: ADDRESS_PANEL_TAB_NAME.DOCUMENT_UPLOADS, label: "Files" },
  ];

  const defaultTab = tabs[0]!;
  const [tab, setTab] = useNavigationTab({
    defaultTab: defaultTab.value,
    allowedTabs: map(tabs, "value"),
  });

  const pushHistory = (pathname: string, query: QueryParams) => {
    history.push({
      pathname,
      search: `${queryString.stringify(query)}`,
    });
  };

  const canViewRepetitiveLoss = authorized({
    resource: RESOURCE_NAME.REPETITIVE_LOSS,
    permission: "view",
  });

  const { loading, data } = useGetOrCreatePropertyQuery({
    fetchPolicy: "network-only",
    variables: {
      address,
      latitude,
      longitude,
      parcelId: propertyId ? null : parcelId,
      propertyId,
      includeRepetitiveLoss: canViewRepetitiveLoss,
    },
    onCompleted: ({ getProperty, parcel }) => {
      if (!propertyId && getProperty) {
        if (!hasPropertyIds) {
          updateMap();
        }
        const { id, fullAddress, latitude, longitude } = getProperty;
        const { id: parcelId, parcelNumber } = parcel || {};

        propertyId = id;

        pushHistory("/map", {
          address: fullAddress,
          lng: longitude,
          lat: latitude,
          tab: ADDRESS_PANEL_TAB_NAME.OVERVIEW,
          propertyId: id,
          parcelNumber: parcelNumber,
          parcelId: parcelId,
        });
      }
    },
  });

  useEffect(() => {
    // when you scroll the address panel, the scroll event
    // is propagating to the background map, which is
    // not what we want, so we stop propagation here
    const swallowEvent: EventListener = (evt: Event) => {
      evt.stopPropagation();
    };

    window.addEventListener("scroll", swallowEvent, true);

    return () => window.removeEventListener("scroll", swallowEvent);
  });

  const parcel: HeaderParcel = data?.parcel || {
    parcelNumber,
    id: parcelId,
  };

  const onPropertySelect = useCallback(
    ({
      id,
      fullAddress,
      latitude,
      longitude,
    }: {
      id: string;
      fullAddress?: Maybe<string>;
      latitude?: Maybe<number>;
      longitude?: Maybe<number>;
    }) => {
      pushHistory("/map", {
        address: fullAddress,
        lng: longitude,
        lat: latitude,
        tab: ADDRESS_PANEL_TAB_NAME.OVERVIEW,
        propertyId: id,
        parcelNumber: parcel.parcelNumber,
        parcelId: parcel.id,
      });
    },
    [parcel]
  );

  const properties = [
    ...(data?.parcel?.properties || []),
    data?.getProperty,
  ].filter(hasCoordinates);

  const property = data?.getProperty;

  return (
    <Container>
      <Header
        property={hasCoordinates(property) ? property : null}
        properties={properties}
        loading={loading}
        parcel={parcel}
        latitude={latitude}
        longitude={longitude}
        onClose={onClose}
        selectProperty={onPropertySelect}
      />
      <TabContainer>
        <TabGroup currentTab={tab} setTab={setTab} tabs={tabs} />
      </TabContainer>
      <AddressPanelContext.Provider value={{ loading, property }}>
        <TabBody
          tab={tab}
          address={address}
          latitude={latitude}
          longitude={longitude}
        />
      </AddressPanelContext.Provider>
    </Container>
  );
};

export default React.memo(AddressPanel);
