import React from "react";
import { useParams } from "react-router";
import { buildLink } from "common/routing";
import FullPageBackgroundWithLogo from "../Common/FullPageBackgroundWithLogo";
import { Button } from "../Common/Button";
import {
  DashboardLink,
  DownloadButton,
  ExportsContainer,
  ExportsImage,
  ExportsSubtitle,
  ExportsTitle,
} from "./__styles__/DownloadExport";

const DownloadExport = () => {
  const { objectKey } = useParams<{ objectKey: string }>();
  const downloadLink = `/api/files/exports/${objectKey}`;

  return (
    <FullPageBackgroundWithLogo>
      <ExportsContainer>
        <ExportsImage />
        <ExportsTitle>Download your export</ExportsTitle>
        <ExportsSubtitle>
          Click the button below to download your export.
        </ExportsSubtitle>
        <DownloadButton href={downloadLink} download={true}>
          <Button size="medium" styleVariant="primary">
            Download Export
          </Button>
        </DownloadButton>
        <DashboardLink href={buildLink("map")}>
          Go back to Forerunner Dashboard
        </DashboardLink>
      </ExportsContainer>
    </FullPageBackgroundWithLogo>
  );
};

export default DownloadExport;
