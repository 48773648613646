import React from "react";
import { useModal } from "react-modal-hook";
import Modal from "../../../Common/Modal";
import { BaseMapForm, RequiredFormData } from "./Form";
import { useCreateBaseMapMutation } from "../../../../generated/graphql";
import { useStatusToasts } from "../../../../hooks/useStatusToasts";

export const useAddBaseMapModal = () => {
  const [showAddBaseMapModal, hideAddBaseMapModal] = useModal(
    () => (
      <Modal
        onRequestClose={() => {
          hideAddBaseMapModal();
        }}
      >
        <AddBaseMapModal
          onSave={hideAddBaseMapModal}
          onCancel={hideAddBaseMapModal}
        />
      </Modal>
    ),
    []
  );
  return [showAddBaseMapModal, hideAddBaseMapModal] as const;
};

export const AddBaseMapModal = ({
  onSave,
  onCancel,
  refetchQueries = ["GetAccountBaseMaps"],
}: {
  onSave: () => void;
  onCancel: () => void;
  refetchQueries?: string[];
}) => {
  const [createBaseMap, { loading }] = useCreateBaseMapMutation();
  const { addSuccessToast, addErrorToast } = useStatusToasts();

  const onCreate = async (data: RequiredFormData) => {
    await createBaseMap({
      variables: {
        data,
      },
      refetchQueries,
      onError: error => {
        addErrorToast(error.message);
      },
      onCompleted: () => {
        addSuccessToast("Base map successfully created!");
      },
    });

    onSave();
  };

  return (
    <BaseMapForm
      onCancel={onCancel}
      onSave={onCreate}
      submitText="Create"
      loading={loading}
    />
  );
};
