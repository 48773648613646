import React, { useContext } from "react";
import { FilterDescription, TABLE_NAMES } from "common/utils/queryBuilder";
import { NetworkStatus } from "@apollo/client";
import { ColumnDef } from "@tanstack/table-core/build/lib/types";
import { useLocation } from "react-router";
import { SUBMISSION_CATEGORY } from "common/constants";

import {
  GetQuerySubmissionsQueryVariables,
  TableType,
  useGetQuerySubmissionsLazyQuery,
  useGetSavedViewsQuery,
  useGetSubmissionTableConfigQuery,
} from "../../generated/graphql";
import { AuthContext } from "../Authorization/AuthContext";
import {
  useManualPaginationConfig,
  useQueryDescription,
} from "../Common/Tables/hooks";
import { FullWidthTable } from "../Common/FullWidthTable/FullWidthTable";
import { QuerySubmissionTableResult } from "./__queries__/table";
import {
  buildLocalTableInfo,
  useLocalTableDisplayConfig,
} from "../../hooks/useTableDisplayConfig";
import {
  MinimalColumnDef,
  fieldId,
  initializeColumns,
  mergeFieldGroups,
  sanityCheckLocalColumnOrder,
} from "../Common/FullWidthTable/utils";
import { Attribute } from "../Common/FullWidthTable/types";
import { ACTION_COLUMN_DEF_CONSTANTS } from "../Common/ActionCell";
import ActionButton from "./ActionButton";
import ExportDataButton from "../Exports/ExportDataButton";
import { FILES_TABLE_CUSTOM_CELLS } from "../DocumentUploads/FilesTable";
import { buildColumnDefinitions } from "../Common/Tables/utils";
import LinkCell from "../Common/LinkCell";
import { buildLink } from "common/routing";

const SUBMISSIONS_CATEGORY_MAP = {
  [SUBMISSION_CATEGORY.INSPECTIONS]: {
    tableName: TABLE_NAMES.INSPECTIONS as const,
    tableContentName: "inspections",
    tableType: TableType.INSPECTIONS,
  },
  [SUBMISSION_CATEGORY.PERMITTING]: {
    tableName: TABLE_NAMES.PERMITS as const,
    tableContentName: "permits",
    tableType: TableType.PERMITS,
  },
};

const SubmissionsTable = ({
  category,
}: {
  category: SUBMISSION_CATEGORY.INSPECTIONS | SUBMISSION_CATEGORY.PERMITTING;
}) => {
  const { account } = useContext(AuthContext);
  const { tableContentName, tableName, tableType } =
    SUBMISSIONS_CATEGORY_MAP[category];

  const location = useLocation();

  const { user, admin } = useContext(AuthContext);

  const { data: savedViewsResponse, loading: loadingSavedViews } =
    useGetSavedViewsQuery({
      variables: { table: tableType },
    });

  const { data: columnConfigResponse, loading: loadingColumnConfig } =
    useGetSubmissionTableConfigQuery({
      variables: {
        category,
      },
      fetchPolicy: "network-only",
    });

  const [
    querySubmissions,
    { previousData, data: currentData, networkStatus, loading, error, refetch },
  ] = useGetQuerySubmissionsLazyQuery({
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  const actionsColumn: MinimalColumnDef<typeof requiredFields> = {
    ...ACTION_COLUMN_DEF_CONSTANTS,
    cell: ({ row }) => {
      const submission = {
        hasSummary: row.original[`${tableName}.hasSummary`],
        id: row.original[`${tableName}.id`],
        status: row.original["CertificateUploads.status"],
        property: {
          id: row.original["Properties.id"],
          streetAddress: row.original["Properties.streetAddress"],
          longitude: row.original["Properties.longitude"],
          latitude: row.original["Properties.latitude"],
        },
        submissionTypeVersion: {
          submissionType: {
            category: row.original[`${tableName}.submissionTypeCategory`],
            name: row.original[`${tableName}.submissionTypeName`],
          },
        },
        relatedSubmissions: [],
        category,
      };

      return <ActionButton submission={submission} onUpdate={refetch!} />;
    },
  };

  const {
    initialTableState,
    queryDescription,
    updateQueryDescription,
    currentView,
  } = useQueryDescription({
    defaultSort: [{ id: `${tableName}.createdAt`, desc: true }],
    savedViews: savedViewsResponse?.account?.savedViews,
  });

  const generatedColumnDefinitions = buildColumnDefinitions({
    columnConfig: columnConfigResponse?.getSubmissionTableConfig.data || [],
    cellRenderers: {
      ...FILES_TABLE_CUSTOM_CELLS,
      [`${tableName}.adminLink`]: {
        cell: ({ row }) => {
          const linkProps = row.original[`${tableName}.adminLink`];

          if (!linkProps) {
            return null;
          }

          const url = buildLink(
            "editSubmission",
            { submissionId: linkProps.submissionId },
            {},
            { admin: true, accountId: account!.id }
          );

          return <LinkCell href={url} target={"_blank"} label={"Link"} />;
        },
      },
    },
  });

  const { id: tableId } = buildLocalTableInfo({
    entityId: user?.id ?? admin?.id,
    pathname: location.pathname,
  });

  const { getLocalTableState, setLocalColumnOrder } =
    useLocalTableDisplayConfig({
      tableId,
      defaultValue: {
        columnOrder: [
          ...queryDescription.fields.map(field => fieldId(field)),
          ACTION_COLUMN_DEF_CONSTANTS.id,
        ],
        columnSizing: {},
        sorting: [],
      },
    });

  const manualPaginationConfig = useManualPaginationConfig({
    ...initialTableState.pagination,
    currentTotalPages: currentData?.querySubmissions.pageInfo.totalPages,
    previousTotalPages: previousData?.querySubmissions.pageInfo.totalPages,
  });

  const tanstackColumnDefinitions: Array<
    ColumnDef<QuerySubmissionTableResult>
  > = [...generatedColumnDefinitions];

  const data =
    networkStatus === NetworkStatus.setVariables ? previousData : currentData;

  if (
    loadingColumnConfig ||
    !columnConfigResponse?.getSubmissionTableConfig.data ||
    loadingSavedViews ||
    !currentView
  ) {
    return null;
  }

  const loadingDetails = {
    loading,
    loadingText: `Loading ${tableContentName}`,
    noDataText: `No ${tableContentName} found`,
  };

  const requiredFields = [
    { table: tableName, name: "id" },
    { table: TABLE_NAMES.PROPERTIES, name: "id" },
    { table: TABLE_NAMES.PROPERTIES, name: "latitude" },
    { table: TABLE_NAMES.PROPERTIES, name: "longitude" },
    { table: tableName, name: "hasSummary" },
    { table: TABLE_NAMES.PROPERTIES, name: "streetAddress" },
    { table: tableName, name: "submissionTypeCategory" },
    { table: tableName, name: "submissionTypeName" },
  ];

  if (tableName === TABLE_NAMES.PERMITS) {
    requiredFields.push({
      table: TABLE_NAMES.CERTIFICATE_UPLOADS,
      name: "status",
    });
  }

  const search = ({ page }: { page: number }) => {
    const variables: GetQuerySubmissionsQueryVariables = {
      description: {
        table: tableName,
        fields: mergeFieldGroups(queryDescription.fields, requiredFields),

        orderBy: queryDescription.orderBy,
        filters: queryDescription.filters,
      },
      page,
    };

    void querySubmissions({
      variables,
    });
  };

  const localTableConfig = getLocalTableState();

  const { columnOrder: localColumnOrder } = localTableConfig;

  const updatedColumnOrder = sanityCheckLocalColumnOrder({
    localColumnOrder,
    defaultColumnIds: queryDescription.fields.map(field => fieldId(field)),
  });

  if (updatedColumnOrder) {
    setLocalColumnOrder(updatedColumnOrder);
  }

  const initialColumns = initializeColumns({
    initialTableState,
    tanstackColumnDefinitions,
    initialQueryDescription: queryDescription,
    actionsColumn,
  });

  const timeoutError = error?.graphQLErrors.find(
    e => e.extensions.code === "TIMEOUT_ERROR"
  );

  const tableActions = <ExportDataButton />;

  return (
    <FullWidthTable<QuerySubmissionTableResult, Array<FilterDescription>>
      actions={tableActions}
      columns={initialColumns}
      previousData={previousData?.querySubmissions.data}
      currentData={data?.querySubmissions.data ?? []}
      loadingDetails={loadingDetails}
      tableStyleDetails={{ hasHighlights: true, hasRowActions: true }}
      manualPaginationConfig={{
        ...manualPaginationConfig,
        pageCount: data?.querySubmissions.pageInfo.totalPages ?? 1,
      }}
      initialState={initialTableState}
      filterable={{
        newFilterConfiguration: columnConfigResponse.getSubmissionTableConfig
          .data as Array<Attribute>,
        search,
      }}
      columnSettingProps={{
        columnConfiguration: columnConfigResponse.getSubmissionTableConfig.data,
        columnDefinitions: tanstackColumnDefinitions,
      }}
      timeoutError={timeoutError}
      queryDescription={queryDescription}
      updateQueryDescription={updateQueryDescription}
      savedViews={savedViewsResponse?.account?.savedViews}
      currentView={currentView}
    />
  );
};

export const InspectionsTable = () => {
  return <SubmissionsTable category={SUBMISSION_CATEGORY.INSPECTIONS} />;
};

export const PermitsTable = () => {
  return <SubmissionsTable category={SUBMISSION_CATEGORY.PERMITTING} />;
};
