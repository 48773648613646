import React from "react";

import FullPageFormLayout from "../../Common/FullPageFormLayout";
import { StageConfig, useWizardStageTracker } from "./hooks";
import WizardActionButtons from "./WizardActionButtons";
import { useHistory } from "react-router";

export interface FullPageWizardLayoutProps<T> {
  initialStage: T;
  loading: boolean;
  onPrimaryButtonClick: () => void | Promise<void>;
  previousLocation: string;
  stageConfigs: StageConfig<T>[];
  subtitle: string;
}

const FullPageWizardLayout = <T,>({
  initialStage,
  loading,
  previousLocation,
  stageConfigs,
  subtitle,
}: FullPageWizardLayoutProps<T>) => {
  const history = useHistory();

  const [
    { getCurrentStage, isFirstStage, isLastStage, isStageComplete },
    { moveToNextStage, moveToPreviousStage, finishWizard },
  ] = useWizardStageTracker({
    initialStage,
    stageConfigs,
  });

  const { primaryButtonText, render } = getCurrentStage();

  return (
    <FullPageFormLayout
      prevLocation={previousLocation}
      subtitle={subtitle}
      width="auto"
      rightContainer={
        <WizardActionButtons
          disablePrimary={!isStageComplete()}
          isPrimaryButtonLoading={loading}
          onBackClick={moveToPreviousStage}
          onCancelClick={() => history.push(previousLocation)}
          onPrimaryButtonClick={isLastStage() ? finishWizard : moveToNextStage}
          primaryButtonText={primaryButtonText}
          showBackButton={!isFirstStage()}
        />
      }
    >
      {render()}
    </FullPageFormLayout>
  );
};

export default FullPageWizardLayout;
export * from "./hooks";
