import React, { useState } from "react";
import { get, useFormContext } from "react-hook-form";
import { Label } from "../../../../../../Inputs";
import {
  SubmissionsBuilderFormDataStructure,
  InputFieldPath,
} from "../../types";
import { HelpText, WidgetSettingsContainer } from "./__styles__/WidgetSettings";
import LabelValueOptionsInput from "./LabelValueOptionsInput";
import { Button } from "../../../../../../Common/Button";
import { FieldSettingConfig } from "./shared";
import { FlexRow } from "../../../../../../Common/__styles__/Layout";
import { Text } from "../../../../../../Inputs/react-hook-form";
import { setNumberValueAs } from "../../../../../../../utils/react-hook-form";

const NumberWithUnitsFieldSettings = ({
  fieldPath,
  isModuleInput,
}: {
  fieldPath: InputFieldPath;
  isModuleInput: boolean;
}) => {
  const {
    formState: { errors },
    register,
    setValue,
    watch,
  } = useFormContext<SubmissionsBuilderFormDataStructure>();

  const options = watch(`${fieldPath}.enum`);
  const optionsAsObjects = options?.map(
    (option: string | { label: string; value: string }) => {
      if (typeof option === "string") {
        return {
          label: option,
          value: option,
        };
      }
      return option;
    }
  );
  const [dropdownOptions, setDropdownOptions] = useState<
    { label: string; value: string }[]
  >(optionsAsObjects?.length ? optionsAsObjects : [{ label: "", value: "" }]);

  const setDropdownOption = (index: number, label: string, value: string) => {
    const updatedOptions = [...dropdownOptions];
    updatedOptions[index] = { label, value };
    setDropdownOptions(updatedOptions);
    setValue(`${fieldPath}.enum`, updatedOptions, { shouldValidate: true });
  };

  const addDropdownOption = () => {
    const updatedOptions = [...dropdownOptions, { label: "", value: "" }];
    setDropdownOptions(updatedOptions);
    setValue(`${fieldPath}.enum`, updatedOptions, { shouldValidate: true });
  };

  const removeDropdownOption = (index: number) => {
    dropdownOptions.splice(index, 1);
    // creating a shallow copy of the array since splice modifies in place
    const updatedOptions = [...dropdownOptions];
    setDropdownOptions(updatedOptions);
    setValue(`${fieldPath}.enum`, updatedOptions, { shouldValidate: true });
  };

  return (
    <WidgetSettingsContainer>
      <FieldSettingConfig fieldPath={fieldPath} isModuleInput={isModuleInput} />
      <FlexRow style={{ gap: "16px" }}>
        <Text
          {...register(`${fieldPath}.minimum`, {
            setValueAs: setNumberValueAs,
            deps: [`${fieldPath}.maximum`],
          })}
          label="Number minimum"
          type="number"
          error={get(errors, `${fieldPath}.minimum.message`, "")}
        />
        <Text
          {...register(`${fieldPath}.maximum`, {
            setValueAs: setNumberValueAs,
            deps: [`${fieldPath}.minimum`],
          })}
          label="Number maximum"
          type="number"
          error={get(errors, `${fieldPath}.maximum.message`, "")}
        />
      </FlexRow>
      <div>
        <div>
          <Label
            style={{ marginBottom: "4px" }}
            required
            text={"Dropdown options"}
          />
          <HelpText style={{ marginTop: "0px", paddingBottom: "8px" }}>
            Labels are visible to users, while values are backend identifiers
            and hidden from them.
          </HelpText>
        </div>
        <LabelValueOptionsInput
          dropdownOptions={dropdownOptions}
          setDropdownOption={setDropdownOption}
          removeDropdownOption={removeDropdownOption}
          valueEditingDisabled={isModuleInput}
        />
        <div style={{ paddingTop: "8px" }}>
          <Button
            styleVariant="outlineLight"
            size="small"
            leftIconName="plus"
            onClick={addDropdownOption}
            disabled={isModuleInput}
          >
            Add option
          </Button>
        </div>
      </div>
    </WidgetSettingsContainer>
  );
};

NumberWithUnitsFieldSettings.displayName = "NumberWithUnitsFieldSettings";

export default NumberWithUnitsFieldSettings;
