import React from "react";
import { isNil } from "lodash";

import { AuthContext } from "../../Authorization/AuthContext";
import {
  ActivityCreatedByType,
  ActivityTargetType,
  ActivityType,
  GetActivitiesQuery,
  SubmissionCategory,
} from "../../../generated/graphql";
import Comment from "./Comment";
import Bullet from "./Bullet";
import CommentOptions from "./CommentOptions";
import Message, { FeedSource } from "./Message";
import { canDeleteComment } from "common/authorization/customPolicies";
import Icon, { ICON_COLORS, Icons } from "../../Common/Icons";
import {
  Activity as ActivityComponent,
  Container,
  Content,
  DeleteActivity,
  Line,
  Parent,
  TextContainer,
} from "./__styles__/ListItem";
import { useModal } from "react-modal-hook";
import Modal from "../../Common/Modal";
import { DeleteConfirmation } from "./DeleteConfirmation";
import { captureMessage } from "@sentry/browser";

type Activity = GetActivitiesQuery["activities"][number];

type CommentActivity = Activity & {
  targetInfo: { id: string };
  target: Maybe<{ content: string }>;
};

const isCommentActivity = (activity: Activity): activity is CommentActivity => {
  return activity.targetType === ActivityTargetType.COMMENT;
};

interface ListItemProps {
  activity: Activity;
  last: boolean;
  onUpdate: (deletedId?: string) => void;
  latitude?: Maybe<number>;
  longitude?: Maybe<number>;
  propertyId?: Maybe<string>;
  fullAddress?: string | null;
  linkToProperty?: boolean;
  truncateComments: boolean;
  allowEdits: boolean;
  feedSource: FeedSource;
}

const isGuest = (
  activity: Activity
): activity is Activity & { createdBy?: null } => {
  return activity.createdByType === ActivityCreatedByType.GUEST;
};

const isAdmin = (
  activity: Activity
): activity is Activity & {
  createdBy?: Extract<Activity["createdBy"], { __typename?: "Admin" }>;
} => {
  return (
    !isGuest(activity) &&
    (isNil(activity.createdBy) ||
      activity.createdByType === ActivityCreatedByType.ADMIN)
  );
};

const isUser = (
  activity: Activity
): activity is Activity & {
  createdBy?: Extract<Activity["createdBy"], { __typename?: "User" }>;
} => {
  return activity.createdByType === ActivityCreatedByType.USER;
};

const isAPI = (
  activity: Activity
): activity is Activity & {
  createdBy?: Extract<Activity["createdBy"], { __typename?: "APIKey" }>;
} => {
  return activity.createdByType === ActivityCreatedByType.API_KEY;
};

const ListItem = ({
  activity,
  last,
  onUpdate,
  latitude,
  longitude,
  propertyId,
  fullAddress,
  linkToProperty,
  feedSource,
  truncateComments,
  allowEdits,
}: ListItemProps) => {
  const { user, admin, account } = React.useContext(AuthContext);
  const [showModal, hideModal] = useModal(() => (
    <Modal onRequestClose={hideModal}>
      <DeleteConfirmation
        activityId={activity.id}
        closeModal={hideModal}
        onUpdate={(deletedId?: string) => onUpdate(deletedId)}
      />
    </Modal>
  ));

  const currentUser = user || admin;
  const { targetType, type, targetInfo } = activity;
  let creator: string;

  if (
    isAdmin(activity) ||
    (targetType === ActivityTargetType.CERTIFICATE &&
      activity.type === ActivityType.CREATED)
  ) {
    creator = "Forerunner";
  } else if (isGuest(activity)) {
    creator = "Guest";
  } else if (isAPI(activity)) {
    creator = activity.createdBy?.name ?? "API";
  } else if (isUser(activity) && activity.createdBy) {
    const isCreator = activity.createdBy.id === currentUser?.id;
    creator = isCreator
      ? "You"
      : `${activity.createdBy.firstName} ${activity.createdBy.lastName}`;
  } else {
    captureMessage(`Unknown activity creator type for activity ${activity.id}`);
    creator = "Forerunner";
  }

  let category: Maybe<SubmissionCategory> | undefined;
  if (targetType === ActivityTargetType.SUBMISSION) {
    category = targetInfo.category ?? SubmissionCategory.INSPECTIONS;
  }

  return (
    <Parent>
      <Line last={last} />
      <Container>
        <ActivityComponent>
          <Content>
            <Bullet
              targetType={targetType}
              activityType={type}
              category={category}
            />
            <TextContainer>
              <span>{creator} </span>
              <Message
                activity={activity}
                fullAddress={fullAddress}
                latitude={latitude}
                longitude={longitude}
                propertyId={propertyId}
                linkToProperty={linkToProperty}
                feedSource={feedSource}
              />
            </TextContainer>
            {currentUser?.role.name === "admin" &&
              !(isCommentActivity(activity) && feedSource === "Property") && (
                <DeleteActivity data-testid="deleteActivity">
                  <Icon
                    icon={Icons.CLOSE}
                    color={ICON_COLORS.DARK_GREY}
                    onClick={showModal}
                  ></Icon>
                </DeleteActivity>
              )}
          </Content>
          {canDeleteComment({
            user: {
              id: currentUser!.id,
              role: currentUser!.role.name,
              accountId: account!.id,
            },
            comment: {
              createdBy: activity!.createdBy!,
              property: { account: { id: account!.id } },
            },
          }) &&
            allowEdits &&
            isCommentActivity(activity) && (
              <CommentOptions
                commentId={activity.targetInfo.id}
                onUpdate={onUpdate}
              />
            )}
        </ActivityComponent>
        {isCommentActivity(activity) && activity.target && (
          <Comment
            content={activity.target.content}
            truncate={truncateComments}
          />
        )}
      </Container>
    </Parent>
  );
};

export default ListItem;
