import React, { useEffect } from "react";
import { max } from "lodash";

import useViewport from "../Maps/utils/viewportHook";
import LayeredMap, { Parcel, Point, Property } from "../Maps/LayeredMap";
import { MIN_PARCEL_ZOOM } from "../Maps/layers/parcels";
import { LayerContextProvider, Account } from "../Maps/layers";
import { Bounds } from "viewport-mercator-project";
import { SearchResultProps } from "../Search";
import { Parcel as ParcelInput } from "./PropertyForm";
import { useGetParcelsFromPointLazyQuery } from "../../generated/graphql";

// form renders parcel number as option label in <Select />
export type ParcelOption = Pick<Parcel, "parcelNumber" | "id">;
type ParcelOptions = Array<ParcelOption>;

const MIN_VIEWPORT_ZOOM = 0;

interface MapProps {
  account: Account;
  marker: Maybe<Point>;
  setParcels: (parcels: ParcelOptions) => void;
  setMarker: (marker: Maybe<Point>) => void;
  longitude: number;
  latitude: number;
  zoom: number;
  handleSearchResult?: (result: SearchResultProps) => void;
  parcel: Maybe<ParcelInput>;
  setProperty: Maybe<(property?: Property) => void>;
  disabled?: boolean;
  addressOnly?: boolean;
}

export default ({
  parcel,
  marker,
  setParcels,
  setMarker,
  zoom: startingZoom,
  latitude,
  longitude,
  account,
  addressOnly,
  handleSearchResult,
  setProperty,
}: MapProps) => {
  const zoom = max([MIN_PARCEL_ZOOM, startingZoom]) as number;

  const [getParcelsFromPointLazyQuery] = useGetParcelsFromPointLazyQuery({
    fetchPolicy: "network-only",
  });

  const { viewport, setViewport, setViewportWithTransition } = useViewport({
    latitude,
    longitude,
    zoom,
    bounds: account.bounds as Bounds,
  });

  const getParcelsFromPoint = async (point: Point): Promise<ParcelOptions> => {
    const { data } = await getParcelsFromPointLazyQuery({
      variables: { ...point },
    });

    return (
      data?.parcels.filter<ParcelOption>((parcel): parcel is ParcelOption => {
        return !!parcel.parcelNumber;
      }) ?? []
    );
  };

  const layers = {
    marker: {
      value: marker,
    },
    parcels: {
      value: { id: parcel?.id },
      interactive: {
        click: true,
        hover: true,
      },
      onClick: (parcels: Array<Parcel>) => {
        setProperty?.();
        setParcels(parcels);

        const parcel = parcels[0];
        if (parcel) {
          setMarker(parcel.point);
        }
      },
    },
    accountBoundary: {
      interactive: {
        click: true,
      },
      onClick: async (point: Point) => {
        const pointParcels = await getParcelsFromPoint(point);
        setParcels(pointParcels);
        setMarker(point);
        setProperty?.();
      },
    },
    properties: {
      interactive: {
        hover: true,
        click: !!setProperty,
      },
      ...(setProperty && {
        onClick: async (property: Property) => {
          const pointParcels = await getParcelsFromPoint(property.point);
          setParcels(pointParcels);
          setMarker(property.point);
          setProperty(property);
        },
      }),
    },
  };

  const zoomToPoint = async (point: Point) => {
    const zoom = MIN_PARCEL_ZOOM;
    setViewportWithTransition({
      latitude: Number(point.latitude),
      longitude: Number(point.longitude),
      zoom,
    });
  };

  useEffect(() => {
    if (layers.marker.value) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      zoomToPoint(layers.marker.value);
    }
  }, [layers.marker.value]);

  return (
    <LayerContextProvider
      account={account}
      // we want the property dots to always be visible when creating
      // a property so we know what *other* properties are available
      defaultState={{ buildings: { properties: true } }}
    >
      <LayeredMap
        width={"100%"}
        height={"100%"}
        viewport={viewport}
        setViewport={setViewport}
        baseMapStyle={account.defaultBaseMap.mapboxStyle}
        account={account}
        layers={layers}
        minZoom={MIN_VIEWPORT_ZOOM}
        onSearchResult={handleSearchResult}
        addressOnly={addressOnly}
        halfMap={true}
      />
    </LayerContextProvider>
  );
};
