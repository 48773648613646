import React, { useContext, useState } from "react";
import { useHistory, useParams } from "react-router";

import ArcGISParcelImportFormManager from "./ArcGISParcelImportFormManager";
import {
  DEFAULT_PARCEL_IMPORT_CONTEXT_DATA,
  FieldOptionsDispatcher,
  ParcelImportWizardContext,
  ParcelImportWizardContextData,
} from "./context";
import { PARCEL_IMPORT_CONFIG_WORKFLOW } from "./types";
import GpkgParcelImportFormManager from "./GpkgParcelImportFormManager";
import { buildLink } from "common/routing";
import { ParcelImportTaskType } from "../../../../../generated/graphql";
import { AuthContext } from "../../../../Authorization/AuthContext";

interface ParcelImportConfigUrlParams {
  importTaskType: ParcelImportTaskType;
  importConfigurationId?: string;
}

const ParcelImportConfigWizard = () => {
  const history = useHistory();

  const { account } = useContext(AuthContext);

  const { importTaskType, importConfigurationId } =
    useParams<ParcelImportConfigUrlParams>();

  const workflow = !importConfigurationId
    ? PARCEL_IMPORT_CONFIG_WORKFLOW.CREATE
    : importTaskType === ParcelImportTaskType.GPKG
    ? PARCEL_IMPORT_CONFIG_WORKFLOW.DISPLAY_SETTINGS
    : PARCEL_IMPORT_CONFIG_WORKFLOW.EDIT_SETTINGS;

  const canEditSourceFields =
    workflow !== PARCEL_IMPORT_CONFIG_WORKFLOW.DISPLAY_SETTINGS;

  const [contextData, setContextData] = useState<ParcelImportWizardContextData>(
    {
      ...DEFAULT_PARCEL_IMPORT_CONTEXT_DATA,
      canEditSourceFields,
      hasPublicPortal: !!account?.publicPortal.enabled,
      importTaskType,
      importConfigurationId,
      workflow,
    }
  );

  const setFieldOptions: FieldOptionsDispatcher = ({
    availableFields,
    parcelModelFields,
  }) => {
    setContextData(prev => ({
      ...prev,
      availableFields,
      parcelModelFields,
    }));
  };

  const initialContextDataValue = {
    ...contextData,
    setFieldOptions,
  };

  if (
    !importTaskType ||
    (importTaskType !== ParcelImportTaskType.ARCGIS &&
      importTaskType !== ParcelImportTaskType.GPKG)
  ) {
    history.push(buildLink("settings"));
    return null;
  }

  return (
    <ParcelImportWizardContext.Provider value={initialContextDataValue}>
      {importTaskType === ParcelImportTaskType.ARCGIS ? (
        <ArcGISParcelImportFormManager />
      ) : (
        <GpkgParcelImportFormManager />
      )}
    </ParcelImportWizardContext.Provider>
  );
};

export default ParcelImportConfigWizard;
