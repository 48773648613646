import React from "react";

import { RouteComponentProps } from "react-router-dom";
import LoginPage from "./LoginPage";
import { ADMIN_LOGIN } from "./__queries__";

const AdminLoginPage = ({ location }: RouteComponentProps) => (
  <LoginPage
    loginMutation={ADMIN_LOGIN}
    landingPage="/admin"
    event="Admin logged in"
    initialTitle="Admin log in"
    location={location}
  />
);

export default AdminLoginPage;
