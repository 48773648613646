import React from "react";

import { Box } from "../../Common/__styles__/Layout";

import { Header, HeaderTitle, Section } from "../__styles__/Content";
import { Button } from "../../Common/Button";
import Divider from "../../Common/Divider";
import Table from "../../Common/Tables/Table";
import { ColumnDef } from "@tanstack/react-table";
import { AuthContext } from "../../Authorization/AuthContext";
import { formatDate } from "common/utils/strings";
import { FEATURE_FLAG_NAME } from "common/constants";
import {
  FeatureFlagName,
  useUpdateAccountFeatureFlagsMutation,
} from "../../../generated/graphql";
import { useStatusToasts } from "../../../hooks/useStatusToasts";
import { useForm } from "react-hook-form";
import { ReactHookFormCheckbox } from "../../Inputs/Checkbox";
import { keys } from "lodash";
import { CURRENT_ADMIN } from "../../Authorization/__queries__";
import { DocumentNode } from "graphql";
import {
  DescriptionCell,
  LastModifiedCell,
  NameCell,
} from "./__styles__/FeatureFlag";

interface FeatureFlag {
  id: string;
  feature: string;
  isEnabled: boolean;
  updatedAt?: string;
}

type FeatureFlagsState = {
  [key in FEATURE_FLAG_NAME]: boolean;
};

const buildFormState = (featureFlags: Array<FeatureFlag>) => {
  return featureFlags.reduce((convertedFlags, flag) => {
    return {
      ...convertedFlags,
      [flag.feature as FEATURE_FLAG_NAME]: flag.isEnabled,
    };
  }, {} as FeatureFlagsState);
};

type TableStructure = {
  feature: FEATURE_FLAG_NAME;
  name?: Maybe<string>;
  updatedAt?: Maybe<string>;
  isEnabled: boolean;
  description?: Maybe<string>;
};
const FeatureFlags = ({
  refetchQueries = [{ query: CURRENT_ADMIN }],
}: {
  refetchQueries?: Array<{ query: DocumentNode }>;
}) => {
  const { account } = React.useContext(AuthContext);
  const { addSuccessToast, addErrorToast } = useStatusToasts();

  const {
    register,
    formState: { isDirty, dirtyFields },
    reset,
    getValues,
  } = useForm<FeatureFlagsState>({
    defaultValues: buildFormState(account?.featureFlags || []),
  });

  const [updateAccountFeatureFlags, { loading }] =
    useUpdateAccountFeatureFlagsMutation({
      onCompleted: data => {
        addSuccessToast("Feature flags were successfully updated.");
        reset(buildFormState(data.updateAccountFeatureFlags.featureFlags));
      },
      onError: () => {
        addErrorToast("There was a problem updating the feature flags.");
      },
      refetchQueries,
    });

  const columns: Array<ColumnDef<TableStructure>> = [
    {
      id: "feature",
      header: "Feature name",
      enableSorting: false,
      maxSize: 200,
      cell: ({ row }) => {
        return <NameCell>{row.original.name}</NameCell>;
      },
    },
    {
      id: "description",
      header: "Description",
      enableSorting: false,
      maxSize: 500,
      cell: ({ row }) => {
        const text = row.original.description || "No description available";
        return <DescriptionCell title={text}>{text}</DescriptionCell>;
      },
    },
    {
      id: "updatedAt",
      header: "Last modified",
      enableSorting: false,
      maxSize: 200,
      cell: ({ row }) => {
        const text = row.original.updatedAt
          ? formatDate(row.original.updatedAt)
          : "N/A";
        return <LastModifiedCell>{text}</LastModifiedCell>;
      },
    },
    {
      id: "isEnabled",
      header: "Enabled",
      enableSorting: false,
      cell: ({ row }) => {
        return <ReactHookFormCheckbox {...register(row.original.feature)} />;
      },
    },
  ];

  return (
    <Box>
      <Header>
        <HeaderTitle>Feature flags</HeaderTitle>
      </Header>
      <Section css={{ paddingTop: 0 }} data-testid="feature-flags-table">
        <Table<TableStructure>
          columns={columns}
          currentData={account?.featureFlags || []}
          loadingDetails={{
            loading,
            loadingText: "Loading account feature flags...",
            noDataText: "No feature flags found",
          }}
          tableStyleDetails={{
            hasRowActions: true,
            isCompact: true,
            hasFlushedSides: true,
          }}
          excludePaginationNav
        />
      </Section>
      <Divider margins="16px 0px" />
      <Section css={{ marginBottom: "25px" }}>
        <Button
          styleVariant="primary"
          size="medium"
          disabled={loading || account?.featureFlags.length === 0 || !isDirty}
          data-testid="update-feature-flags-button"
          onClick={async () => {
            const filteredArray = keys(dirtyFields).map(feature => {
              return {
                feature: feature as FeatureFlagName,
                isEnabled: getValues(feature as FeatureFlagName),
              };
            });

            await updateAccountFeatureFlags({
              variables: {
                data: filteredArray,
              },
            });
          }}
        >
          Update feature flags
        </Button>
      </Section>
    </Box>
  );
};

export default FeatureFlags;
