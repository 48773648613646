import React, { useContext } from "react";

import {
  RowWrapper,
  ShowToPublicWrapper,
  RowLabel,
  DisplayNameInputWrapper,
  SourceInputWrapper,
} from "../__styles__/ParcelImportWizard";
import {
  ImportFieldDisplayNameInput as DisplayNameInput,
  ImportFieldIsPublicToggle as IsPublicToggle,
  ImportFieldSourceSelect as SourceSelect,
  ImportFieldSourceSwapper as SourceSwapper,
} from "../ImportFieldInputs";
import { canOverride, canTogglePublic } from "../utils";
import { AppParcelImportFieldConfig } from "../types";
import { ParcelImportWizardContext } from "../context";

const getSourceInput = ({ type }: AppParcelImportFieldConfig) => {
  if (type === "HARDCODED") {
    return SourceSwapper;
  }

  return SourceSelect;
};

const ImportFieldRow = ({
  fieldConfig,
}: {
  fieldConfig: AppParcelImportFieldConfig;
}) => {
  const { canEditSourceFields, hasPublicPortal } = useContext(
    ParcelImportWizardContext
  );

  const fieldPath = `mapping.${fieldConfig.name}` as const;
  const ImportFieldInput = getSourceInput(fieldConfig);

  return (
    <RowWrapper>
      <RowLabel
        text={fieldConfig.label}
        required={fieldConfig.required}
        tooltip={fieldConfig.tooltip}
        htmlFor={`${fieldPath}.source`}
      />
      <SourceInputWrapper>
        <ImportFieldInput
          disabled={!canEditSourceFields}
          fieldConfig={fieldConfig}
          fieldName={`${fieldPath}.source`}
          fieldPath={fieldPath}
        />
      </SourceInputWrapper>
      {canOverride(fieldConfig) && (
        <DisplayNameInputWrapper>
          <DisplayNameInput fieldConfig={fieldConfig} fieldPath={fieldPath} />
        </DisplayNameInputWrapper>
      )}
      {hasPublicPortal && canTogglePublic(fieldConfig) && (
        <ShowToPublicWrapper>
          <IsPublicToggle fieldConfig={fieldConfig} fieldPath={fieldPath} />
        </ShowToPublicWrapper>
      )}
    </RowWrapper>
  );
};

export default ImportFieldRow;
