import React from "react";
import { useParams } from "react-router";
import { useUserInviteQuery } from "../../generated/graphql";
import { UserInvite } from "./UserInvite";
import { UserInviteAccepted } from "./UserInviteAccepted";
import { UserInviteNotFound } from "./UserInviteNotFound";

const InvitePage = () => {
  const { inviteId } = useParams<{ inviteId: string }>();
  const { data, loading, error } = useUserInviteQuery({
    variables: { inviteId },
  });

  if (error || loading) return null;

  if (!data?.userInvite) {
    return <UserInviteNotFound />;
  }

  if (data.userInvite.acceptedAt) {
    return <UserInviteAccepted />;
  }

  return <UserInvite userInvite={data.userInvite} />;
};

export default InvitePage;
