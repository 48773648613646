import React, { useState } from "react";
import { NODE_ENV } from "common/env";
import { getPublicAccountPortalBaseURL } from "common/utils/url";

import { Checkbox, Label, Text } from "../../Inputs";
import { Button } from "../../Common/Button";
import { AccountsQuery, useAccountsQuery } from "../../../generated/graphql";

import {
  AccountName,
  AccountsGrid,
  Account,
  AccountLinks,
  AccountLogo,
  SearchContainer,
  SearchBar,
  FilterContainer,
  FilterOption,
  SearchTitle,
} from "./__styles__/AccountSelector";

type Account = AccountsQuery["accounts"][number];

export default function AccountSelector() {
  const isProduction = NODE_ENV === "production";
  const { error, data, loading } = useAccountsQuery({
    variables: { fetchFIRMs: !isProduction, fetchParcels: !isProduction },
  });
  const [filters, setFilters] = useState({
    searchTerm: "",
    hasParcels: false,
    hasFloodzones: false,
  });

  if (loading || !data)
    return (
      <SearchContainer>
        <AccountsGrid>Loading...</AccountsGrid>
      </SearchContainer>
    );

  if (error)
    return (
      <SearchContainer>
        <AccountsGrid>Error fetching accounts</AccountsGrid>
      </SearchContainer>
    );

  let accounts = data.accounts
    .filter(account =>
      account.name.toLowerCase().includes(filters.searchTerm.toLowerCase())
    )
    .filter(({ hasParcels }) => !filters.hasParcels || hasParcels)
    .filter(({ firms }) => {
      const hasFloodzones = !!firms?.filter(
        firm => firm.components.floodzones.hasGeometries
      ).length;
      return hasFloodzones || !filters.hasFloodzones;
    });

  return (
    <SearchContainer>
      <SearchBar>
        <SearchTitle>Select an account</SearchTitle>
        <Text
          name="Search"
          value={filters.searchTerm}
          onChange={searchTerm => setFilters({ ...filters, searchTerm })}
          placeholder="Search for an account"
          size="medium"
        />
        {!isProduction && (
          <FilterContainer>
            <FilterOption>
              <Checkbox
                name="hasParcels"
                value={filters.hasParcels}
                onChange={hasParcels => setFilters({ ...filters, hasParcels })}
              />
              <Label text="has parcels" />
            </FilterOption>
            <FilterOption>
              <Checkbox
                name="hasFloodzones"
                value={filters.hasFloodzones}
                onChange={hasFloodzones =>
                  setFilters({ ...filters, hasFloodzones })
                }
              />
              <Label text="has floodzones" />
            </FilterOption>
          </FilterContainer>
        )}
      </SearchBar>
      <AccountsGrid>
        {accounts.map(account => {
          const portalUrl = getPublicAccountPortalBaseURL({
            subdomain: account.publicPortal.subdomain,
            protocol: window.location.protocol,
            appDomain: window.env.APPLICATION_DOMAIN,
          });

          const clientAppUrl = `/admin/accounts/${account.id}`;

          return (
            <Account
              key={account.id}
              style={{ backgroundColor: account.publicPortal.color }}
            >
              {account.logoUrl && <AccountLogo src={account.logoUrl} />}
              <AccountName>{account.name}</AccountName>
              <AccountLinks className="links">
                {account.publicPortal.enabled && (
                  <Button
                    styleVariant="ghostWhite"
                    size="small"
                    onClick={() => window.open(portalUrl, "_blank")}
                    style={{ textDecoration: "underline" }}
                  >
                    Public portal
                  </Button>
                )}

                <Button
                  styleVariant="outlineLight"
                  size="small"
                  onClick={() => {
                    window.location.href = clientAppUrl;
                  }}
                >
                  Client app
                </Button>
              </AccountLinks>
            </Account>
          );
        })}
      </AccountsGrid>
    </SearchContainer>
  );
}
