import {
  FieldError,
  FieldPath,
  useFormContext,
  useFormState,
  useWatch,
} from "react-hook-form";
import {
  NonSourceFieldName,
  ParcelImportFieldConfig,
  ParcelImportWizardFormData,
  SourceFieldName,
} from "../types";
import { get, isEmpty } from "lodash";
import { useEffect, useMemo } from "react";

export const useFieldError = ({
  fieldName,
}: {
  fieldName: FieldPath<ParcelImportWizardFormData>;
}) => {
  const { control } = useFormContext<ParcelImportWizardFormData>();
  const { errors } = useFormState<ParcelImportWizardFormData>({
    control,
    name: fieldName,
  });

  const fieldError: FieldError = get(errors, fieldName, null);

  return fieldError && fieldError.message;
};

interface UseSourceFieldDependencyProps {
  fieldConfig?: ParcelImportFieldConfig;
  fieldName: NonSourceFieldName;
  sourceFieldName: SourceFieldName;
}

const getInitialFieldValue = ({
  fieldConfig,
  fieldName,
  getValues,
  sourceValue,
}: {
  fieldConfig?: ParcelImportFieldConfig;
  fieldName: NonSourceFieldName;
  getValues: (fieldName: NonSourceFieldName) => string | boolean;
  sourceValue: string | string[];
}) => {
  const currentValue = getValues(fieldName);

  if (currentValue === "" || currentValue === undefined) {
    if (fieldName.endsWith("displayName") || fieldName.endsWith("label")) {
      if (fieldConfig?.label) {
        return fieldConfig.label;
      }

      // sourceValue would only be an array if the field config isMulti = true
      return Array.isArray(sourceValue) ? sourceValue.join(", ") : sourceValue;
    }

    return fieldConfig?.defaultIsPublic ?? false;
  }

  return currentValue;
};

export const useSourceFieldDependency = ({
  fieldConfig,
  fieldName,
  sourceFieldName,
}: UseSourceFieldDependencyProps) => {
  const { control, resetField, getValues, setValue } =
    useFormContext<ParcelImportWizardFormData>();

  const sourceValue = useWatch<
    ParcelImportWizardFormData,
    typeof sourceFieldName
  >({
    control,
    name: sourceFieldName,
  });

  const hasNoSourceValue = useMemo(() => isEmpty(sourceValue), [sourceValue]);

  useEffect(() => {
    if (hasNoSourceValue) {
      resetField(fieldName);
    } else {
      // If there is a source value we need an initial value to populate
      // the dependent field with.
      const initialFieldValue = getInitialFieldValue({
        fieldConfig,
        fieldName,
        getValues,
        sourceValue,
      });

      setValue(fieldName, initialFieldValue, { shouldValidate: true });
    }
  }, [hasNoSourceValue]);

  return hasNoSourceValue;
};
