import React from "react";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";

import Banner from "../../Common/Banner";
import { TableBanner } from "../../Common/FullWidthTable/__styles__/FullWidthTable";

export const HAS_PROCESSING = gql`
  query HasProcessing {
    hasProcessingUploads
  }
`;
const ProcessingNotice = () => {
  const { data } = useQuery(HAS_PROCESSING);
  if (!data || !data.hasProcessingUploads) return null;
  return (
    <TableBanner>
      <Banner compact={true}>
        Your Elevation Certificates are currently processing. We will email you
        once they are complete.
      </Banner>
    </TableBanner>
  );
};

export default ProcessingNotice;
