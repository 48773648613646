import React from "react";
import EditPermitInfo, { PermitNotFound } from "./EditPermitInfo";
import { RouteComponentProps, useLocation } from "react-router";
import { useGetCertificateQuery } from "../../../generated/graphql";

export default ({
  match,
}: Pick<RouteComponentProps<{ certificateId: string }>, "match">) => {
  const location = useLocation<{ prevLocation: string }>();

  const prevLocation = location.state?.prevLocation || "/certificates";
  const { data, loading, error } = useGetCertificateQuery({
    variables: { id: match.params.certificateId },
  });

  if (loading || error || !data?.certificate) {
    return <PermitNotFound prevLocation={prevLocation} loading={loading} />;
  }

  return (
    <EditPermitInfo
      certificate={data.certificate}
      prevLocation={prevLocation}
    />
  );
};
