import React from "react";
import { FullScreenPermissionsError } from "../Common/InsufficientPermissions";
import { AuthContext } from "./AuthContext";
import { AuthContextType } from "./types";

export type PermissionOptions = Parameters<AuthContextType["authorized"]>[0];

export const withAuthorization = <P,>(
  WrappedComponent: React.FC<P>,
  permissionOptions: PermissionOptions
) => {
  const { authorized } = React.useContext(AuthContext);

  return authorized(permissionOptions)
    ? WrappedComponent
    : FullScreenPermissionsError;
};
