import React from "react";
import { AuthContext } from "../../../../Authorization/AuthContext";
import { useFormContext, UseFormRegister } from "react-hook-form";
import { UpdateOrCreatePropertyAttributesFormStructure } from "./updateOrCreateSectionAttributeModal";
import { Checkbox } from "../../../../Inputs/react-hook-form";
import { Label } from "../../../../Inputs";
import { InputRow } from "../../../../Common/__styles__/Modal";
import { DisplaySettingLabel } from "./__styles__/updateOrCreateSectionAttribute";
import { spacing } from "../../../../../stitches.config";
import { arrayHasExactlyOneItem } from "common/utils/arrays";

export const DisplaySettings = ({
  isGreatestInclusionCustomMapAttribute,
  isBFEAttribute,
}: {
  isGreatestInclusionCustomMapAttribute: boolean;
  isBFEAttribute: boolean;
}) => {
  const { account } = React.useContext(AuthContext);
  const { register } =
    useFormContext<UpdateOrCreatePropertyAttributesFormStructure>();

  const inputs: {
    id: Parameters<
      UseFormRegister<UpdateOrCreatePropertyAttributesFormStructure>
    >["0"];
    labelId: string;
    text: string;
  }[] = [];

  if (account?.publicPortal.enabled) {
    inputs.push({
      id: "isPublic",
      labelId: "displaySettingLabel",
      text: "Display on public website",
    });
  }

  if (isBFEAttribute) {
    inputs.push({
      id: "displayConfig.showApproximateBfeTool",
      labelId: "showApproximateBfeToolLabel",
      text: "Enable approximate BFE calculation",
    });
  }

  if (isGreatestInclusionCustomMapAttribute) {
    inputs.push({
      id: "displayValueAsLink",
      labelId: "displayValueAsLinkLabel",
      text: "Display value as link",
    });
  }

  if (!inputs.length) {
    return null;
  }

  const label = arrayHasExactlyOneItem(inputs)
    ? "Display setting"
    : "Display settings";

  return (
    <div>
      <Label text={label} css={{ marginBottom: spacing.s }} required />
      {inputs.map(input => {
        const { id, labelId, text } = input;
        return (
          <InputRow key={id} css={{ marginBottom: spacing.s }}>
            <Checkbox id={id} {...register(id)} aria-labelledby={labelId} />
            <DisplaySettingLabel id={labelId} htmlFor={id}>
              {text}
            </DisplaySettingLabel>
          </InputRow>
        );
      })}
    </div>
  );
};
