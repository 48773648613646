import React from "react";
import { useModal } from "react-modal-hook";

import { useDeletePropertyMutation } from "../../../generated/graphql";
import { track } from "../../../utils/tracking";
import { Button } from "../../Common/Button";
import { Checkbox } from "../../Inputs/react-hook-form";

import Modal from "../../Common/Modal";

import {
  ButtonSection,
  ContentSection,
  FormSection,
  HeaderSection,
  PrimaryButtons,
} from "../../Common/__styles__/Modal";
import { AuthContext } from "../../Authorization/AuthContext";
import { useStatusToasts } from "../../../hooks/useStatusToasts";
import { useForm } from "react-hook-form";
import {
  DeleteModalFieldset,
  DeleteModalWrapper,
} from "./__styles__/DeletePropertyModal";
import { formatCoordinates } from "common/utils/coordinates";

const PUBLIC_WEBSITE_DISCLAIMER =
  "The property will no longer accessible from your public website.";
export interface UseDeletePropertyModalProps {
  property: {
    id: string;
    latitude: number;
    longitude: number;
    fullAddress?: Maybe<string>;
    streetAddress?: Maybe<string>;
    city?: Maybe<string>;
  };
  updateMap: () => void;
  closeModal: () => void;
  onDelete: () => void;
}

export const useDeletePropertyModal = ({
  property,
  updateMap,
  onDelete,
}: UseDeletePropertyModalProps) => {
  const [showDeletePropertyModal, hideDeletePropertyModal] = useModal(
    () => (
      <Modal
        onRequestClose={() => {
          hideDeletePropertyModal();
        }}
      >
        <DeletePropertyConfirmation
          updateMap={updateMap}
          closeModal={hideDeletePropertyModal}
          property={property}
          onDelete={onDelete}
        />
      </Modal>
    ),
    [property]
  );

  return [showDeletePropertyModal, hideDeletePropertyModal] as const;
};

interface DeletePropertyConfirmationForm {
  confirmFileDelete: boolean;
  confirmDashboardRemoval: boolean;
  confirmPublicWebsiteRemoval?: boolean;
}

export const DeletePropertyConfirmation = ({
  updateMap,
  closeModal,
  property,
  onDelete,
}: UseDeletePropertyModalProps) => {
  const { account } = React.useContext(AuthContext);
  const {
    formState: { isValid },
    register,
  } = useForm<DeletePropertyConfirmationForm>({
    defaultValues: {
      confirmFileDelete: false,
      confirmDashboardRemoval: false,
      confirmPublicWebsiteRemoval: account?.publicPortal.enabled
        ? false
        : undefined,
    },
  });
  const { addSuccessToast, addErrorToast } = useStatusToasts();

  const [deleteProperty, { loading }] = useDeletePropertyMutation({
    onCompleted: () => {
      track("Deleted Property", {
        streetAddress: property.streetAddress,
        latitude: property.latitude,
        longitude: property.longitude,
        city: property.city,
      });

      updateMap();

      addSuccessToast("The property was successfully deleted");

      onDelete();
    },
    onError: () => {
      addErrorToast(
        "There was an error deleting this property. Please try again. If the problem persists, please email support@withforerunner.com"
      );
    },
  });

  const handleDelete = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    await deleteProperty({
      variables: { propertyId: property.id },
    });
  };

  return (
    <DeleteModalWrapper>
      <HeaderSection>
        <h1>Delete property</h1>
        <h2>
          {property.fullAddress ??
            formatCoordinates({
              latitude: property.latitude!,
              longitude: property.longitude!,
            })}
        </h2>
      </HeaderSection>
      <FormSection>
        <ContentSection>
          <p>By deleting this property, you confirm that:</p>
          <DeleteModalFieldset>
            <div>
              <Checkbox
                {...register("confirmFileDelete", { required: true })}
                id="confirmFileDelete"
              />
              <label htmlFor="confirmFileDelete">
                All data and uploaded files associated with this property will
                be deleted
              </label>
            </div>

            <div>
              <Checkbox
                {...register("confirmDashboardRemoval", { required: true })}
                id="confirmDashboardRemoval"
              />
              <label htmlFor="confirmDashboardRemoval">
                The property will be removed from the dashboard for all users
              </label>
            </div>

            {account?.publicPortal.enabled && (
              <div>
                <Checkbox
                  {...register("confirmPublicWebsiteRemoval", {
                    required: true,
                  })}
                  id="confirmPublicWebsiteRemoval"
                />
                <label htmlFor="confirmPublicWebsiteRemoval">
                  {PUBLIC_WEBSITE_DISCLAIMER}
                </label>
              </div>
            )}
          </DeleteModalFieldset>
          <p>
            This action <strong>cannot</strong> be undone.
          </p>
        </ContentSection>
        <ButtonSection>
          <PrimaryButtons>
            <Button
              styleVariant="secondary"
              size="medium"
              onClick={() => closeModal()}
            >
              Cancel
            </Button>
            <Button
              styleVariant="alert"
              disabled={!isValid}
              loading={loading}
              onClick={handleDelete}
              size="medium"
            >
              Delete property
            </Button>
          </PrimaryButtons>
        </ButtonSection>
      </FormSection>
    </DeleteModalWrapper>
  );
};
