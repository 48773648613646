import React from "react";
import {
  ButtonSection,
  Container,
  ContentSection,
  FormSection,
  HeaderSection,
  PrimaryButtons,
} from "../../../../Common/__styles__/Modal";
import { RequiredMessage } from "../../PropertyData/__styles__/upsertDfeRuleModal";
import { Select, Text } from "../../../../Inputs/react-hook-form";
import { Required } from "../../../../Inputs/__styles__/Label";
import { InputGrid } from "../../PropertyData/__styles__/floodInfoModals";
import { Button } from "../../../../Common/Button";
import { useModal } from "react-modal-hook";
import Modal from "../../../../Common/Modal";
import { useHistory } from "react-router";
import { useForm } from "react-hook-form";
import { SubmissionsBuilderFormDataStructure } from "./types";
import { Label } from "../../../../Inputs";
import { categoryOptions } from "./constants";

export type CreateSubmissionTypeFormStructure = Pick<
  SubmissionsBuilderFormDataStructure,
  "name" | "category"
>;

type CreateSubmissionTypeModalProps = {
  onCancel: () => void;
  onSubmit: ({}: CreateSubmissionTypeFormStructure) => void;
};

export const useCreateSubmissionTypeModal = ({
  onSubmit,
}: Pick<CreateSubmissionTypeModalProps, "onSubmit">) => {
  const history = useHistory();
  const [show, hide] = useModal(() => (
    <Modal>
      <CreateSubmissionTypeModal
        onCancel={() => history.push("/settings/account/records")}
        onSubmit={(values: CreateSubmissionTypeFormStructure) => {
          onSubmit(values);
          hide();
        }}
      />
    </Modal>
  ));

  return [show, hide] as const;
};

export const CreateSubmissionTypeModal = ({
  onCancel,
  onSubmit,
}: CreateSubmissionTypeModalProps) => {
  // We're using a new form here, because we want a subset of the fields and to have the nicety of things like errors and submit button disabling
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<CreateSubmissionTypeFormStructure>();

  const handleSubmitCallback = (data: CreateSubmissionTypeFormStructure) =>
    onSubmit(data);

  return (
    <Container overflows>
      <HeaderSection>
        <h1>Add submission type</h1>
      </HeaderSection>
      <FormSection overflows>
        <ContentSection overflows>
          <RequiredMessage>
            All fields are required<Required>*</Required>
          </RequiredMessage>

          <InputGrid style={{ marginTop: "24px" }}>
            <Text
              label="Form title"
              error={errors.name?.message}
              {...register("name", { required: "Form title is required" })}
            />

            <div>
              <Label text="Record type" htmlFor="category" />
              <Select
                control={control}
                name="category"
                options={categoryOptions}
                size="medium"
                required={true}
                error={errors.category?.message}
                rules={{ required: "Record type is required" }}
              />
            </div>
          </InputGrid>
        </ContentSection>
      </FormSection>
      <ButtonSection>
        <PrimaryButtons>
          <Button size="medium" styleVariant="secondary" onClick={onCancel}>
            Cancel
          </Button>

          <Button
            size="medium"
            styleVariant="primary"
            onClick={handleSubmit(handleSubmitCallback)}
          >
            Add
          </Button>
        </PrimaryButtons>
      </ButtonSection>
    </Container>
  );
};
