import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import { ParcelImportFieldConfig, ParcelImportWizardFormData } from "../types";
import {
  IsPublicToggleWrapper,
  ShowToPublicLabel,
} from "../__styles__/ParcelImportWizard";
import { Switch } from "../../../../../Inputs";
import { useSourceFieldDependency } from "./hooks";

const ImportFieldIsPublicToggle = ({
  fieldConfig,
  fieldPath,
}: {
  fieldConfig?: ParcelImportFieldConfig;
  fieldPath: `mapping.${string}` | `additionalFields.${number}`;
}) => {
  const fieldName = `${fieldPath}.isPublic` as const;

  const { control } = useFormContext<ParcelImportWizardFormData>();
  const hasNoSourceValue = useSourceFieldDependency({
    fieldConfig,
    fieldName,
    sourceFieldName: `${fieldPath}.source`,
  });

  return (
    <Controller
      control={control}
      name={fieldName}
      render={({ field: { onChange, value, name } }) => (
        <IsPublicToggleWrapper>
          <ShowToPublicLabel text={value ? "Displayed" : "Hidden"} />
          <Switch
            name={name}
            disabled={hasNoSourceValue}
            onChange={onChange}
            value={value!}
            size="medium"
          />
        </IsPublicToggleWrapper>
      )}
    />
  );
};

export default ImportFieldIsPublicToggle;
