import React from "react";

import { CustomTextarea } from "../../../../../../Submissions/Widgets";

import BaseWidgetPreview from "./BaseWidgetPreview";
import { WidgetProps } from "@rjsf/utils";

const TextareaWidgetPreview = (props: WidgetProps) => {
  const widgetPreview = <CustomTextarea {...props} />;

  return (
    <BaseWidgetPreview
      widgetPreview={widgetPreview}
      {...props}
    ></BaseWidgetPreview>
  );
};

export default TextareaWidgetPreview;
