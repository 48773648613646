import { gql } from "@apollo/client";
import React from "react";
import { useStatusToasts } from "../../../hooks/useStatusToasts";
import { useDeleteCommentMutation } from "../../../generated/graphql";

import DropdownMenu, { ActionsProps } from "../../Inputs/DropdownMenu";
export interface CommentOptionsProps {
  commentId: string;
  onUpdate: () => void;
}

export const DELETE_COMMENT = gql`
  mutation DeleteComment($id: ID!) {
    deleteComment(id: $id)
  }
`;

const CommentOptions = ({ commentId, onUpdate }: CommentOptionsProps) => {
  const { addSuccessToast, addErrorToast } = useStatusToasts();

  const [deleteComment, { loading }] = useDeleteCommentMutation({
    onCompleted: () => {
      onUpdate();
      addSuccessToast("The comment was deleted");
    },
    onError: () => {
      addErrorToast(
        `There was an error deleting this comment. Please try again. If the problem persists, please email us at support@withforerunner.com`
      );
    },
  });

  const handleDelete = async () => {
    if (loading) return;
    await deleteComment({ variables: { id: commentId } });
  };

  const actions: Array<ActionsProps> = [
    {
      label: "Delete Comment",
      variant: "red",
      onClick: handleDelete,
    },
  ];

  return <DropdownMenu actions={actions} />;
};

export default CommentOptions;
