import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { ModalProvider } from "react-modal-hook";
import { RESOURCE_NAME } from "common/authorization";
import { getPath } from "common/routing";

import TranscriptionApp from "./TranscriptionApp";
import AdminLandingPage from "./AdminLandingPage";
import AccountDashboardShim from "./AccountsApp/AccountDashboardShim";
import CreateAccount from "./CreateAccount";
import DownloadExport from "../Exports/DownloadExport";
import AttioImports from "./AttioImports";
import { withAuthorization } from "../Authorization/withAuthorization";

export default function AdminApp() {
  return (
    <div>
      <ModalProvider>
        <Switch>
          <Redirect exact from="/" to="/admin" />
          <Route path="/admin/tasks" component={TranscriptionApp} />
          <Route path="/admin/accounts/create" component={CreateAccount} />
          <Route path={getPath("downloadExport")} component={DownloadExport} />
          <Route
            path="/admin/accounts/:accountId"
            component={AccountDashboardShim}
          />
          <Route
            path="/admin/attio-imports"
            component={withAuthorization(AttioImports, {
              resource: RESOURCE_NAME.SCRIPT_JOB,
              permission: "dispatch",
            })}
          />
          <Route path="/admin" component={AdminLandingPage} />
        </Switch>
      </ModalProvider>
    </div>
  );
}
