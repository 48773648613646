import React, { useContext } from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  RouteComponentProps,
} from "react-router-dom";
import App from "../../App";
import BackOffice from "./BackofficeApp";
import { AuthContext } from "../../Authorization/AuthContext";
import FullPageLoadingScreen from "../../Common/FullPageLoadingScreen";
import { useGetAccountQuery } from "../../../generated/graphql";

interface URLParams {
  accountId: string;
}

interface AccountDashboardShimProps extends RouteComponentProps<URLParams> {}

export default function AccountDashboardShim({
  match,
}: AccountDashboardShimProps) {
  const { admin, setAdmin } = useContext(AuthContext);
  const { data, loading } = useGetAccountQuery({
    variables: { id: match.params.accountId },
    onCompleted: data => {
      if (admin && data.account) {
        setAdmin({ ...admin, account: data.account });
      }
    },
  });

  if (loading) return <FullPageLoadingScreen />;
  const account = data?.account;
  if (!account) return <div>Invalid account</div>;
  if (!admin?.allowedToViewDashboard) return <div>Not allowed</div>;

  return (
    <BrowserRouter basename={match.url}>
      <Switch>
        <Route path="/backoffice" component={BackOffice} />
        <Route render={() => <App account={account} admin={admin} />} />
      </Switch>
    </BrowserRouter>
  );
}
