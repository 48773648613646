import React, { useContext } from "react";
import { useModal } from "react-modal-hook";

import Modal from "../Common/Modal";
import { Button } from "../Common/Button";
import Table from "./Table";
import ExportLogs from "./ExportLogs";
import { useLogsQuery } from "../../generated/graphql";
import { AuthContext } from "../Authorization/AuthContext";
import { RESOURCE_NAME } from "common/authorization";
import { WatchQueryFetchPolicy } from "@apollo/client";
import { FlexRow } from "../Common/__styles__/Layout";

export default (
  { fetchPolicy }: { fetchPolicy: WatchQueryFetchPolicy } = {
    fetchPolicy: "cache-and-network",
  }
) => {
  const { data, loading, error, refetch } = useLogsQuery({
    fetchPolicy,
  });

  const { authorized } = useContext(AuthContext);

  const [showModal, hideModal] = useModal(() => (
    <Modal onRequestClose={hideModal}>
      <ExportLogs closeModal={hideModal} />
    </Modal>
  ));

  if (error) return null;

  const canExportLogs = authorized({
    resource: RESOURCE_NAME.INFORMATION_REQUEST,
    permission: "export",
  });

  const actions = (
    <FlexRow style={{ gap: "8px", justifyContent: "flex-end", width: "100%" }}>
      <Button
        onClick={showModal}
        size="small"
        styleVariant="outlineLight"
        disabled={!canExportLogs}
        aria-label="Export Logs"
        leftIconName="download"
      >
        Export
      </Button>
    </FlexRow>
  );

  return (
    <Table
      tableActions={actions}
      data={data?.informationRequests ?? []}
      loading={loading}
      onUpdate={refetch}
    />
  );
};
