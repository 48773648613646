import React from "react";
import {
  NavLink,
  Redirect,
  Route,
  RouteProps,
  Switch,
  useRouteMatch,
} from "react-router-dom";

import {
  Container,
  NavContainer,
  Content,
  NavItemsContainer,
  NavItem,
} from "./__styles__/SubTabMenu";

export interface MenuItemProps {
  name: string;
  path: string;
  hide?: boolean; // removes menu item from sidebar, direct access allowed
  restrict?: boolean; // removes menu item from sidebar, prevents direct access to the path
  component: RouteProps["component"];
}

type SubTabMenuItemProps = Pick<MenuItemProps, "name" | "path">;

export const SubTabMenuItem = ({ name, path }: SubTabMenuItemProps) => {
  return (
    <NavItem>
      <NavLink
        to={{ pathname: path, state: { scrollToTop: false } }}
        key={path}
        activeClassName="selected"
      >
        {name}
      </NavLink>
    </NavItem>
  );
};

type SubTabMenuContentProps = Pick<MenuItemProps, "path" | "component">;

export const SubTabMenuContent = ({
  path,
  component,
}: SubTabMenuContentProps) => {
  return <Route path={path} component={component} />;
};

export type MenuItems = ArrayWithAtLeastOneItem<MenuItemProps>;

export const SubTabMenu = ({ menuItems }: { menuItems: MenuItems }) => {
  const { path: basePath } = useRouteMatch();

  const filteredMenuItems = menuItems.filter(
    menuItem => !(menuItem.restrict || menuItem.hide)
  );
  const filteredMenuContent = menuItems.filter(menuItem => !menuItem.restrict);

  const navItems = filteredMenuItems.map(props => (
    <SubTabMenuItem
      name={props.name}
      path={`${basePath}${props.path}`}
      key={props.name}
    />
  ));

  const content = filteredMenuContent.map(menuItem => (
    <SubTabMenuContent
      path={`${basePath}${menuItem.path}`}
      component={menuItem.component}
      key={menuItem.name}
    />
  ));

  return (
    <Container>
      <NavContainer>
        <NavItemsContainer>{navItems}</NavItemsContainer>
      </NavContainer>
      <Content>
        <Switch>
          {content}
          <Route path={basePath} exact>
            <Redirect to={`${basePath}${filteredMenuItems[0]!.path}`} />
          </Route>
        </Switch>
      </Content>
    </Container>
  );
};
