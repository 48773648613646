import React from "react";
import { Title } from "../Common/__styles__/FormWithFullPageBackground";

export const RequestMFAResetConfirmation = () => {
  return (
    <>
      <Title>MFA Reset Requested</Title>
      <p>
        Your reset request has been sent! You will receive an email in your
        inbox once the request has been approved with instructions on how to
        complete the reset process.
      </p>
    </>
  );
};
