import React from "react";
import { toLower } from "lodash";

import {
  SectionTitle,
  Fieldset,
  TextInput,
  RadioInput,
  InputRow,
  Note,
  Callout,
  Attachments,
  NullableRadioInput,
  DateInput,
} from "./Common";

export default function FullDataExtractionGroup6({ get, set, errors }) {
  return (
    <>
      <SectionTitle>Section A — Property Information</SectionTitle>

      <TextInput
        name="buildingOwnerName"
        value={get("buildingOwnerName")}
        onChange={set("buildingOwnerName")}
        error={errors.buildingOwnerName}
        label="A1. Building Owner's Name"
      />

      <TextInput
        name="streetAddress"
        value={get("streetAddress")}
        onChange={set("streetAddress")}
        error={errors.address}
        label="A2. Building Street Address"
      />

      <InputRow>
        <TextInput
          name="city"
          value={get("city")}
          onChange={set("city")}
          error={errors.address}
          label="City"
        />

        <TextInput
          name="state"
          value={get("state")}
          onChange={set("state")}
          error={errors.address}
          label="State"
        />

        <TextInput
          name="zipcode"
          value={get("zipcode")}
          onChange={set("zipcode")}
          error={errors.address}
          label="Zipcode"
        />
      </InputRow>

      <Note>If Zipcode is not present, please leave blank.</Note>

      <TextInput
        name="propertyDescription"
        value={get("propertyDescription")}
        onChange={set("propertyDescription")}
        error={errors.propertyDescription}
        label="A3. Property Description"
      />

      <Fieldset legend="A4. Building Use">
        <RadioInput
          name="buildingUse"
          value={get("buildingUse")}
          valueFormatter={toLower}
          onChange={set("buildingUse")}
          error={errors.buildingUse}
          options={[
            { value: "residential", text: "Residential" },
            { value: "non_residential", text: "Non-Residential" },
            { value: "addition", text: "Addition" },
            { value: "accessory", text: "Accessory" },
            { value: "other", text: "Other" },
          ]}
        />
      </Fieldset>

      <Fieldset legend="A5. Latitude/Longitude">
        <InputRow>
          <TextInput
            name="latitude"
            value={get("latitude")}
            onChange={set("latitude")}
            error={errors.latitude}
            label="Latitude"
          />

          <TextInput
            name="longitude"
            value={get("longitude")}
            onChange={set("longitude")}
            error={errors.longitude}
            label="Longitude"
          />
        </InputRow>
      </Fieldset>

      <TextInput
        name="buildingDiagramNumber"
        value={get("buildingDiagramNumber")}
        onChange={set("buildingDiagramNumber")}
        error={errors.buildingDiagramNumber}
        label="A7. Building Diagram Number"
      />

      <Fieldset legend="A8. For a building with a crawlspace or enclosure(s):">
        <TextInput
          name="crawlspaceSqft"
          value={get("crawlspaceSqft")}
          onChange={set("crawlspaceSqft")}
          error={errors.crawlspaceInfo?.sqft}
          label="A8. a) Square footage of crawlspace or enclosure(s)"
        />

        <TextInput
          name="numCrawlspaceFloodOpenings"
          value={get("numCrawlspaceFloodOpenings")}
          onChange={set("numCrawlspaceFloodOpenings")}
          error={errors.crawlspaceInfo?.numFloodOpenings}
          label="A8. b) Number of permanent flood openings in the crawlspace or enclosure(s)"
        />

        <TextInput
          name="netAreaOfCrawlspaceFloodOpenings"
          value={get("netAreaOfCrawlspaceFloodOpenings")}
          onChange={set("netAreaOfCrawlspaceFloodOpenings")}
          error={errors.crawlspaceInfo?.netAreaOfFloodOpenings}
          label="A8. c) Total net area of flood openings in A8. b"
        />

        <Fieldset legend="A8. d) Engineered flood openings?">
          <NullableRadioInput
            name="engineeredCrawlspaceFloodOpenings"
            value={get("engineeredCrawlspaceFloodOpenings")}
            onChange={set("engineeredCrawlspaceFloodOpenings")}
            error={errors.crawlspaceInfo?.engineeredFloodOpenings}
          />
          <Note>
            If this question does not appear on the document, please leave
            blank.
          </Note>
        </Fieldset>
      </Fieldset>

      <Fieldset legend="A9. For a building with an attached garage:">
        <TextInput
          name="garageSqft"
          value={get("garageSqft")}
          onChange={set("garageSqft")}
          error={errors.attachedGarageInfo?.sqft}
          label="A9. a) Square footage of attached garage"
        />

        <TextInput
          name="numGarageFloodOpenings"
          value={get("numGarageFloodOpenings")}
          onChange={set("numGarageFloodOpenings")}
          error={errors.attachedGarageInfo?.numFloodOpenings}
          label="A9. b) Number of permanent flood openings in the attached garage"
        />

        <TextInput
          name="netAreaOfGarageFloodOpenings"
          value={get("netAreaOfGarageFloodOpenings")}
          onChange={set("netAreaOfGarageFloodOpenings")}
          error={errors.attachedGarageInfo?.netAreaOfFloodOpenings}
          label="A9. c) Total net area of flood openings in A9. b"
        />

        <Fieldset legend="A9. d) Engineered flood openings?">
          <NullableRadioInput
            name="engineeredGarageFloodOpenings"
            value={get("engineeredGarageFloodOpenings")}
            onChange={set("engineeredGarageFloodOpenings")}
            error={errors.attachedGarageInfo?.engineeredFloodOpenings}
          />
          <Note>
            If this question does not appear on the document, please leave
            blank.
          </Note>
        </Fieldset>
      </Fieldset>

      <SectionTitle>
        Section B - Flood Insurance Rate Map (FIRM) Information
      </SectionTitle>

      <TextInput
        name="nfipCommunityName"
        value={get("nfipCommunityName")}
        onChange={set("nfipCommunityName")}
        error={errors.nfipCommunityName}
        label="B1. NFIP Community Name"
      />

      <TextInput
        name="nfipCommunityNumber"
        value={get("nfipCommunityNumber")}
        onChange={set("nfipCommunityNumber")}
        error={errors.nfipCommunityNumber}
        label="B1. NFIP Community Number"
      />

      <TextInput
        name="nfipCountyName"
        value={get("nfipCountyName")}
        onChange={set("nfipCountyName")}
        error={errors.nfipCountyName}
        label="B2. County Name"
      />

      <TextInput
        name="nfipState"
        value={get("nfipState")}
        onChange={set("nfipState")}
        error={errors.nfipState}
        label="B3. State"
      />

      <TextInput
        name="firmMapNumber"
        value={get("firmMapNumber")}
        onChange={set("firmMapNumber")}
        error={errors.firmMapNumber}
        label="B4. Map/Panel Number"
      />
      <Note>
        Note: If there are two separate numbers, please transcribe the entire
        field as-is.
      </Note>

      <TextInput
        name="firmSuffix"
        value={get("firmSuffix")}
        onChange={set("firmSuffix")}
        error={errors.firmSuffix}
        label="B5. Suffix"
      />

      <DateInput
        name="firmIndexDate"
        value={get("firmIndexDate")}
        onChange={set("firmIndexDate")}
        error={errors.firmIndexDate}
        label="B6. FIRM Index Date"
      />

      <DateInput
        name="firmPanelEffectiveDate"
        value={get("firmPanelEffectiveDate")}
        onChange={set("firmPanelEffectiveDate")}
        error={errors.firmPanelEffectiveDate}
        label="B7. FIRM Panel Effective/Revised Date"
      />

      <TextInput
        name="floodZone"
        value={get("floodZone")}
        onChange={set("floodZone")}
        error={errors.floodZone}
        label="B8. Flood Zone(s)"
      />
      <Note>
        Note: If multiple Flood Zones are present, please enter them separated
        by commas.
      </Note>

      <TextInput
        name="baseFloodElevation"
        value={get("baseFloodElevation")}
        onChange={set("baseFloodElevation")}
        error={errors.baseFloodElevation}
        label="B9. Base Flood Elevation(s)"
      />
      <Note>
        Note: if there is a unit of measurement (example: feet) please do not
        include it. However, do include any N/A values. If multiple elevations
        are present, please enter them separated by commas.
      </Note>

      <Fieldset legend="B10. Indicate the source of the Base Flood Elevation (BFE) data">
        <RadioInput
          name="baseFloodElevationSource"
          value={get("baseFloodElevationSource")}
          valueFormatter={toLower}
          onChange={set("baseFloodElevationSource")}
          error={errors.baseFloodElevationSource}
          options={[
            { value: "fis_profile", text: "FIS Profile" },
            { value: "firm", text: "FIRM" },
            { value: "community_determined", text: "Community Determined" },
            { value: "other", text: "Other" },
          ]}
        />
      </Fieldset>

      <Fieldset legend="B11. Indicate the elevation datum used for the BFE">
        <RadioInput
          name="baseFloodElevationDatum"
          value={get("baseFloodElevationDatum")}
          valueFormatter={toLower}
          onChange={set("baseFloodElevationDatum")}
          error={errors.baseFloodElevationDatum}
          options={[
            { value: "ngvd_1929", text: "NGVD / NGVD 1929" },
            { value: "navd_1988", text: "NAVD / NAVD 1988" },
            { value: "other", text: "Other" },
          ]}
        />
      </Fieldset>

      <SectionTitle>Section C - Building Elevation Information</SectionTitle>

      <Fieldset legend="C1. Building Elevations are based on">
        <RadioInput
          name="buildingElevationSource"
          value={get("buildingElevationSource")}
          valueFormatter={toLower}
          onChange={set("buildingElevationSource")}
          error={errors.buildingElevationSource}
          options={[
            { value: "construction_drawings", text: "Construction Drawings" },
            {
              value: "building_under_construction",
              text: "Building Under Construction",
            },
            { value: "finished_construction", text: "Finished Construction" },
          ]}
        />
      </Fieldset>

      <TextInput
        name="benchmarkUtilized"
        value={get("benchmarkUtilized")}
        onChange={set("benchmarkUtilized")}
        error={errors.benchmarkUtilized}
        label="C2. Benchmark utilized"
      />

      <Fieldset legend="Vertical Datum">
        <RadioInput
          name="verticalDatum"
          value={get("verticalDatum")}
          valueFormatter={toLower}
          onChange={set("verticalDatum")}
          error={errors.verticalDatum}
          options={[
            { value: "ngvd_1929", text: "NGVD / NGVD 1929 / NGVD 29 / 1929" },
            { value: "navd_1988", text: "NAVD / NAVD 1988 / NAVD 88 / 1988" },
            { value: "other", text: "Other" },
          ]}
        />
        <Note>If blank, please skip.</Note>
      </Fieldset>

      <TextInput
        name="elevationDatumComments"
        value={get("elevationDatumComments")}
        onChange={set("elevationDatumComments")}
        error={errors.elevationDatumComments}
        label="C2. Conversion/Comments"
      />
      <Note>
        If this question does not appear on the document, please leave blank.
      </Note>

      <Callout>
        For the following questions, please provide the answer to the tenth of a
        foot (example: 10.2). <br /> Also, if there is a unit of measurement
        (example: feet) please do not include it. However, do include any N/A
        values.
      </Callout>
      <TextInput
        name="topOfBottomFloor"
        value={get("topOfBottomFloor")}
        onChange={set("topOfBottomFloor")}
        error={errors.topOfBottomFloor}
        label="C2. a) Top of bottom floor"
      />

      <TextInput
        name="topOfNextHigherFloor"
        value={get("topOfNextHigherFloor")}
        onChange={set("topOfNextHigherFloor")}
        error={errors.topOfNextHigherFloor}
        label="C2. b) Top of next higher floor"
      />

      <TextInput
        name="bottomOfLowestHorizontalStructuralMember"
        value={get("bottomOfLowestHorizontalStructuralMember")}
        onChange={set("bottomOfLowestHorizontalStructuralMember")}
        error={errors.bottomOfLowestHorizontalStructuralMember}
        label="C2. c) Bottom of the lowest horizontal structural member"
      />

      <TextInput
        name="attachedGarage"
        value={get("attachedGarage")}
        onChange={set("attachedGarage")}
        error={errors.attachedGarage}
        label="C2. d) Attached garage"
      />

      <TextInput
        name="lowestElevationOfMachineryOrEquipment"
        value={get("lowestElevationOfMachineryOrEquipment")}
        onChange={set("lowestElevationOfMachineryOrEquipment")}
        error={errors.lowestElevationOfMachineryOrEquipment}
        label="C2. e) Lowest elevation of machinery or equipment"
      />

      <TextInput
        name="lowestAdjacentGrade"
        value={get("lowestAdjacentGrade")}
        onChange={set("lowestAdjacentGrade")}
        error={errors.lowestAdjacentGrade}
        label="C2. f) Lowest adjacent grade"
      />

      <TextInput
        name="highestAdjacentGrade"
        value={get("highestAdjacentGrade")}
        onChange={set("highestAdjacentGrade")}
        error={errors.highestAdjacentGrade}
        label="C2. g) Highest adjacent grade"
      />

      <TextInput
        name="lowestAdjacentGradeAtLowestElevationOfDeckOrStairs"
        value={get("lowestAdjacentGradeAtLowestElevationOfDeckOrStairs")}
        onChange={set("lowestAdjacentGradeAtLowestElevationOfDeckOrStairs")}
        error={errors.lowestAdjacentGradeAtLowestElevationOfDeckOrStairs}
        label="C2. h) Lowest adjacent grade at lowest elevation of deck or stairs"
      />

      <Note>
        Note: If this question does not appear on the document, please leave
        blank.
      </Note>

      <SectionTitle>
        Section D - Surveyor, Engineer, or Architect Certification
      </SectionTitle>

      <TextInput
        name="certifierName"
        value={get("certifierName")}
        onChange={set("certifierName")}
        error={errors.certifierName}
        label="Certifier's Name"
      />

      <TextInput
        name="certifierLicenseNumber"
        value={get("certifierLicenseNumber")}
        onChange={set("certifierLicenseNumber")}
        error={errors.certifierLicenseNumber}
        label="License Number"
      />

      <DateInput
        label="Date"
        name="issuedAt"
        value={get("issuedAt")}
        onChange={set("issuedAt")}
        error={errors.issuedAt}
      />

      <TextInput
        name="comments"
        value={get("comments")}
        onChange={set("comments")}
        error={errors.elevationDatumComments}
        label="Comments"
      />
      <Note>
        If this question does not appear on the document, please leave blank.
      </Note>

      <Attachments set={set} get={get} />
    </>
  );
}
