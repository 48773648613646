import React from "react";
import { useFormContext } from "react-hook-form";

import { Text } from "../../../../../Inputs/react-hook-form";
import { ParcelImportWizardFormData, ParcelImportFieldConfig } from "../types";
import { useFieldError, useSourceFieldDependency } from "./hooks";

const ImportFieldDisplayNameInput = ({
  fieldConfig,
  fieldPath,
}: {
  fieldConfig?: ParcelImportFieldConfig;
  fieldPath: `mapping.${string}` | `additionalFields.${number}`;
}) => {
  const fieldName = `${fieldPath}.displayName` as const;
  const { register } = useFormContext<ParcelImportWizardFormData>();

  const hasNoSourceValue = useSourceFieldDependency({
    fieldConfig,
    fieldName,
    sourceFieldName: `${fieldPath}.source`,
  });

  const fieldError = useFieldError({ fieldName });

  return (
    <Text
      {...register(`${fieldPath}.displayName`, {
        validate: value => {
          if (hasNoSourceValue) return;

          if (!value) {
            return "This field is required.";
          }

          return;
        },
      })}
      disabled={hasNoSourceValue}
      error={fieldError}
      placeholder="Field Display Name"
      size="small"
      aria-label={`${fieldConfig?.label} Display Name`}
    />
  );
};

export default ImportFieldDisplayNameInput;
