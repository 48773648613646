import { isEqual } from "lodash";
import { getPath } from "common/routing";
import { useFormContext } from "react-hook-form";
import { RESOURCE_NAME } from "common/authorization";
import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AuthContext } from "../../../Authorization/AuthContext";
import { CreateDocumentTemplateForm } from "./CreateOrUpdateForm";
import {
  GetAccountDocumentTemplateQuery,
  useUpdateAccountDocumentTemplateMutation,
} from "../../../../generated/graphql";

import MappingForm from "./MappingForm";

const MappingUpdateStep = ({
  documentTemplate,
}: {
  documentTemplate: GetAccountDocumentTemplateQuery["getAccountDocumentTemplate"];
}) => {
  const history = useHistory();
  const { authorized } = useContext(AuthContext);
  const { watch } = useFormContext<CreateDocumentTemplateForm>();
  const location = useLocation<undefined | { prevLocation?: string }>();

  const canUpdateDocumentTemplate = authorized({
    resource: RESOURCE_NAME.DOCUMENT_TEMPLATE,
    permission: "update",
  });

  const [updateAccountDocumentTemplate, { loading }] =
    useUpdateAccountDocumentTemplateMutation();

  const prevLocation =
    location.state?.prevLocation ?? getPath("documentTemplates");

  const fieldMappings = watch("mapping");
  const actionIsDisabled = isEqual(
    fieldMappings,
    documentTemplate.fieldMappings
  );

  return (
    <MappingForm
      subtitle="Update document template"
      backButtonText="Cancel"
      actionButtonText="Update"
      loadingAction={loading}
      canDoAction={canUpdateDocumentTemplate && !actionIsDisabled}
      onBack={() => history.push(prevLocation)}
      documentTemplate={documentTemplate}
      onSubmit={updateAccountDocumentTemplate}
      type="update"
    />
  );
};

export default MappingUpdateStep;
