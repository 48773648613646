import React, { useContext } from "react";
import { FilterTarget, FilterTargetSelectGroup } from "./types";
import { isEmpty, isNil } from "lodash";
import Select from "../../../../Inputs/Select";
import { FilterTargetDropdownWrapper } from "./__styles__/FilterTargetDropdown";
import { FilterContext } from "../FilterContext";
import { RULE_FILTER_TYPE } from "common/constants";

const FilterTargetDropdown = ({
  currentFilterTarget,
  changeFilterTarget,
  clearFilterTarget,
  selectedFilterTargets,
}: {
  currentFilterTarget: Maybe<FilterTarget>;
  changeFilterTarget: (
    value: FilterTarget,
    filterType: RULE_FILTER_TYPE
  ) => void;
  clearFilterTarget: () => void;
  selectedFilterTargets: Set<FilterTarget>;
}) => {
  const filterContext = useContext(FilterContext);
  const options = Object.entries(filterContext.filterTargetOptions).reduce(
    (optionGroupsAcc, [groupLabel, availableGroupOptions]) => {
      const remainingGroupOptions =
        selectedFilterTargets.size === 0 ||
        (currentFilterTarget && selectedFilterTargets.has(currentFilterTarget))
          ? availableGroupOptions
          : availableGroupOptions.filter(
              option => !selectedFilterTargets.has(option.value)
            );

      if (!isEmpty(remainingGroupOptions)) {
        optionGroupsAcc.push({
          label: groupLabel,
          options: remainingGroupOptions,
        });
      }

      return optionGroupsAcc;
    },
    [] as Array<FilterTargetSelectGroup>
  );

  return (
    <FilterTargetDropdownWrapper>
      <Select
        label="Select filter"
        defaultValue={null}
        disabled={options.length === 0}
        name={"filterTarget"}
        onChange={value => {
          if (isNil(value)) {
            clearFilterTarget();
          } else {
            const filterType =
              filterContext.filterTargetMetadata[value]!.filterType;
            changeFilterTarget(value, filterType);
          }
        }}
        options={options}
        value={currentFilterTarget}
      />
    </FilterTargetDropdownWrapper>
  );
};

export default FilterTargetDropdown;
