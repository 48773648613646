import React from "react";
import { Switch, Route } from "react-router-dom";

import { Layout } from "../Common/FullWidthLayout";
import { PropertiesTable } from "./PropertiesTable";

export default () => (
  <Layout title="Properties">
    <Switch>
      <Route path="/properties" component={PropertiesTable} />
    </Switch>
  </Layout>
);
