import React from "react";

import Divider from "../../../Common/Divider";
import { Box } from "../../../Common/__styles__/Layout";

import { Header, Section, HeaderTitle, Empty } from "../../__styles__/Content";
import { EmptyState } from "../../../Common/EmptyState";
import { Button } from "../../../Common/Button";
import { useAddBaseMapModal } from "./AddBaseMapModal";
import Table from "./Table";
import { AuthContext } from "../../../Authorization/AuthContext";
import { useGetAccountBaseMapsQuery } from "../../../../generated/graphql";

export const BaseMaps = () => {
  const { account } = React.useContext(AuthContext);
  const [showAddBaseMapModal] = useAddBaseMapModal();
  const { data, loading } = useGetAccountBaseMapsQuery({
    variables: {
      accountId: account!.id,
    },
  });

  const baseMapData = data?.account?.baseMaps || [];
  const hasBaseMaps = !loading && baseMapData.length > 0;

  return (
    <Box>
      <Header>
        <HeaderTitle>Base map settings</HeaderTitle>
        <Button
          styleVariant="outlineLight"
          onClick={showAddBaseMapModal}
          size="small"
          leftIconName="plus"
        >
          Add Base map
        </Button>
      </Header>
      <Divider></Divider>
      <Section>
        {hasBaseMaps ? (
          <Table baseMapData={baseMapData} />
        ) : (
          <Empty>
            <EmptyState message="Your account has no base maps" />
          </Empty>
        )}
      </Section>
    </Box>
  );
};

export default BaseMaps;
