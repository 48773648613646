import React from "react";
import { groupBy } from "lodash";

import { formatDate } from "./AddressPanel/Activity/dateHelper";
import ListItem from "./AddressPanel/Activity/ListItem";
import { GetActivitiesQuery } from "../generated/graphql";
import { FeedSource } from "./AddressPanel/Activity/Message";

import { DateLabel, List } from "./__styles__/Feed";

export interface PropertyForFeed {
  fullAddress?: string | null;
  id: Maybe<string>;
  latitude?: Maybe<number>;
  longitude?: Maybe<number>;
}

export type Activity = GetActivitiesQuery["activities"][number] & {
  property?: Maybe<PropertyForFeed>;
};
export interface ComponentProps {
  onUpdate: (deletedId?: string) => void;
  activities: Activity[];
  linkToProperty: boolean;
  truncateComments: boolean;
  allowEdits: boolean;
  feedSource: FeedSource;
}

export default ({
  onUpdate,
  activities,
  linkToProperty,
  truncateComments,
  allowEdits,
  feedSource,
}: ComponentProps) => {
  const groupedActivities = groupBy(activities, activity => {
    return formatDate({ dateString: activity.occurredAt });
  });

  const lastActivity = (
    index: number,
    activities: GetActivitiesQuery["activities"]
  ) => {
    return index === activities.length - 1;
  };

  return (
    <>
      {Object.entries(groupedActivities).map(([dateString, activities]) => (
        <React.Fragment key={dateString}>
          <DateLabel>{dateString}</DateLabel>
          <List>
            {activities.map((activity, index) => (
              <ListItem
                key={activity.id}
                activity={activity}
                last={lastActivity(index, activities)}
                feedSource={feedSource}
                onUpdate={onUpdate}
                latitude={activity.property?.latitude}
                longitude={activity.property?.longitude}
                fullAddress={activity.property?.fullAddress ?? null}
                propertyId={activity.property?.id}
                linkToProperty={linkToProperty}
                truncateComments={truncateComments}
                allowEdits={allowEdits}
              />
            ))}
          </List>
        </React.Fragment>
      ))}
    </>
  );
};
