import React, { useMemo } from "react";
import { formatDateString } from "common/utils/dates";
import {
  DocumentTemplatesPagination,
  DocumentTemplate as AccountDocumentTemplate,
} from "./__queries__/documentTemplates";

import FileDisplay from "../../../Inputs/FileDisplay";
import Icon, { Icons, ICON_COLORS } from "../../../Common/Icons";
import { ActionButton } from "./ActionButton";

import { CreatedDate } from "../__styles__/ImportsTable";
import { ColumnDef } from "@tanstack/react-table";
import Table from "../../../Common/Tables/Table";
import { ManualPaginationConfig } from "../../../Common/Tables/hooks";

export type DocumentTemplate = AccountDocumentTemplate & {
  actionButton?: ({}) => JSX.Element;
};

type Props = {
  manualPaginationConfig: ManualPaginationConfig;
  previousDocumentTemplates: DocumentTemplate[];
  documentTemplates: DocumentTemplate[];
  loading: boolean;
  onUpdate: () => void;
  pageInfo?: DocumentTemplatesPagination;
};

export default ({
  previousDocumentTemplates,
  documentTemplates,
  loading,
  onUpdate,
  manualPaginationConfig,
}: Props) => {
  const columns: Array<ColumnDef<DocumentTemplate>> = useMemo(
    () => [
      {
        accessorKey: "task",
        id: "task",
        size: 100,
        cell: ({ row }) => {
          return (
            <FileDisplay titleText={row.original.name}>
              <Icon icon={Icons.CALENDAR} color={ICON_COLORS.GREY_4} />
              <CreatedDate>
                {`Created on ${formatDateString({
                  format: "MM D, YYYY at HH:MM ZZ",
                  dateString: row.original.createdAt.toString(),
                }).toLocaleString()}`}
              </CreatedDate>
            </FileDisplay>
          );
        },
      },
      {
        id: "actions",
        size: 150,
        maxSize: 150,
        cell: ({ row }) => (
          <ActionButton documentTemplate={row.original} onUpdate={onUpdate} />
        ),
        meta: { className: "actions-cell" },
      },
    ],
    [onUpdate]
  );

  return (
    <Table<DocumentTemplate>
      css={{
        "& .actions-cell": {
          textAlign: "right",
        },
      }}
      currentData={documentTemplates}
      previousData={previousDocumentTemplates}
      columns={columns}
      loadingDetails={{
        loading,
        loadingText: "Loading document templates",
        noDataText: "No document templates found",
      }}
      minRows={documentTemplates.length}
      manualPaginationConfig={manualPaginationConfig}
      tableStyleDetails={{
        hasFlushedSides: true,
      }}
      excludeTableHeader
    />
  );
};
