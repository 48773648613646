import React from "react";

import Divider from "../../../Common/Divider";
import { Box } from "../../../Common/__styles__/Layout";

import { Header, Section, HeaderTitle, Empty } from "../../__styles__/Content";
import { EmptyState } from "../../../Common/EmptyState";
import { Button } from "../../../Common/Button";
import { useAddRasterModal } from "./AddRasterModal";
import Table from "./Table";
import { AuthContext } from "../../../Authorization/AuthContext";
import { useGetAccountRastersQuery } from "../../../../generated/graphql";

export const Rasters = () => {
  const { account } = React.useContext(AuthContext);
  const [showAddBaseMapModal] = useAddRasterModal();
  const { data, loading } = useGetAccountRastersQuery({
    variables: {
      id: account!.id,
    },
  });

  const rasterData = data?.account?.rasters || [];
  const hasBaseMaps = !loading && rasterData.length > 0;

  return (
    <Box>
      <Header>
        <HeaderTitle>Raster settings</HeaderTitle>
        <Button
          styleVariant="outlineLight"
          onClick={showAddBaseMapModal}
          size="small"
          leftIconName="plus"
        >
          Add Raster
        </Button>
      </Header>
      <Divider></Divider>
      <Section>
        {hasBaseMaps ? (
          <Table rasterData={rasterData} />
        ) : (
          <Empty>
            <EmptyState message="Your account has no rasters" />
          </Empty>
        )}
      </Section>
    </Box>
  );
};

export default Rasters;
