import React from "react";
import { useModal } from "react-modal-hook";
import Modal from "../../../Common/Modal";
import { BaseMapForm, RequiredFormData } from "./Form";
import { AccountBaseMapSettings } from "./__queries__/getAccountBaseMaps";
import { useUpdateBaseMapMutation } from "../../../../generated/graphql";
import { useStatusToasts } from "../../../../hooks/useStatusToasts";

export const useEditBaseMapModal = ({
  baseMap,
}: {
  baseMap: AccountBaseMapSettings;
}) => {
  const [showEditBaseMapModal, hideEditBaseMapModal] = useModal(
    () => (
      <Modal
        onRequestClose={() => {
          hideEditBaseMapModal();
        }}
      >
        <EditBaseMapModal
          onSave={hideEditBaseMapModal}
          onCancel={hideEditBaseMapModal}
          baseMap={baseMap}
        />
      </Modal>
    ),
    [baseMap]
  );
  return [showEditBaseMapModal, hideEditBaseMapModal] as const;
};

export const EditBaseMapModal = ({
  baseMap,
  onSave,
  onCancel,
  refetchQueries = ["GetAccountBaseMaps"],
}: {
  onSave: () => void;
  onCancel: () => void;
  baseMap: AccountBaseMapSettings;
  refetchQueries?: Array<string>;
}) => {
  const [updateBaseMap, { loading }] = useUpdateBaseMapMutation();
  const { addSuccessToast, addErrorToast } = useStatusToasts();

  const onUpdate = async (data: RequiredFormData) => {
    await updateBaseMap({
      variables: {
        data: { id: baseMap.id, ...data },
      },
      refetchQueries,
      onCompleted: () => {
        addSuccessToast("Base map successfully updated!");
      },
      onError: error => {
        addErrorToast(error.message);
      },
    });

    onSave();
  };

  return (
    <BaseMapForm
      onCancel={onCancel}
      onSave={onUpdate}
      submitText="Update"
      baseMap={baseMap}
      loading={loading}
    />
  );
};
