import React, { ComponentProps, useState } from "react";

import { PropertyCorrections } from "../../../TranscriptionApp/Forms/PropertyCorrection";
import { Label, Radio, Select } from "../../../../Inputs";
import { Button } from "../../../../Common/Button";

import TabGroup from "../../../../Common/TabGroup";
import { Certificate, Account } from "./__queries__";
import { SUPPORTED_FORM_YEARS } from "common/constants";
import { AdminSettingsData } from ".";

import {
  CertificateEditForm,
  WorkArea,
  FileViewer,
  Centered,
  AdminSettingsContainer,
  RadioContainer,
  Note,
} from "./__styles__/EditCertificate";
import useNavigationTabs from "../../../../../hooks/useNavigationTabs";
import { map } from "lodash";

export enum TAB_NAMES {
  ADMIN_SETTINGS = "adminSettings",
  PROPERTY = "property",
}

const TABS = [
  { value: TAB_NAMES.ADMIN_SETTINGS, label: "Admin Settings" },
  { value: TAB_NAMES.PROPERTY, label: "Property" },
];

interface Props {
  certificate: Certificate;
  processing: boolean;
  saveCertificate: () => void;
  account: Account;
  setGeocodeData: ComponentProps<typeof PropertyCorrections>["set"];
  getGeocodeData: ComponentProps<typeof PropertyCorrections>["get"];
  setCertificateData: (
    key: keyof AdminSettingsData
  ) => (arg: Maybe<string>) => void;
  getCertificateData: (key: keyof AdminSettingsData) => Maybe<string>;
}
export default ({
  certificate,
  setGeocodeData,
  getGeocodeData,
  setCertificateData,
  getCertificateData,
  account,
  processing,
  saveCertificate,
}: Props) => {
  const [tab, setTab] = useNavigationTabs<TAB_NAMES>({
    defaultTab: TAB_NAMES.ADMIN_SETTINGS,
    allowedTabs: map(TABS, "value"),
  });
  const [loading, setLoading] = useState(false);

  return (
    <CertificateEditForm>
      <WorkArea>
        <Centered>
          <TabGroup currentTab={tab} setTab={setTab} tabs={TABS} />
        </Centered>
        {tab === "adminSettings" && (
          <AdminSettingsContainer>
            <Select
              name="formYear"
              value={getCertificateData("formYear")}
              onChange={setCertificateData("formYear")}
              label="Form Expiration Year"
              options={SUPPORTED_FORM_YEARS.map(year => {
                return { value: year.toString(), label: year.toString() };
              })}
            />

            <RadioContainer>
              <Label text="Has V-Zone design certificate?" />
              <Radio
                name="breakawayWalls"
                onChange={setCertificateData("breakawayWalls")}
                value={getCertificateData("breakawayWalls") ?? "blank"}
                options={[
                  { value: "false", text: "No" },
                  { value: "true", text: "Yes" },
                  { value: "blank", text: "Blank" },
                ]}
              />
              <Note>
                Note: This document would appear after the Elevation
                Certificate. If no document with this title appears on the
                document, please leave blank.
              </Note>
            </RadioContainer>
          </AdminSettingsContainer>
        )}
        {tab === "property" && (
          <PropertyCorrections
            set={setGeocodeData}
            get={getGeocodeData}
            account={account}
            setLoadingLocation={setLoading}
            errors={null}
          />
        )}
        <Centered>
          <Button
            size="medium"
            styleVariant="primary"
            onClick={saveCertificate}
            disabled={processing || loading}
          >
            Save Certificate
          </Button>
        </Centered>
      </WorkArea>
      <FileViewer>
        <iframe src={certificate.documentUpload.url} />
      </FileViewer>
    </CertificateEditForm>
  );
};
