import { useMutation } from "@apollo/client";
import React from "react";
import { useFormContext } from "react-hook-form";
import {
  AdminLoginMutation,
  UserLoginMutation,
  AdminLoginMutationVariables,
  UserLoginMutationVariables,
  UserLoginErrorCode,
} from "../../generated/graphql";
import { useStatusToasts } from "../../hooks/useStatusToasts";
import { Row } from "../Common/__styles__/Layout";
import {
  ButtonRow,
  Title,
} from "../Common/__styles__/FormWithFullPageBackground";
import { Button } from "../Common/Button";
import { Text } from "../Inputs/react-hook-form";
import { ErrorMessage } from "./ErrorMessage";
import { LoginFormStructure, LoginPageProps } from "./LoginPage";
import { MFAFlowAction } from "common-client/utils/useMFAFlow";
import { PasswordResetLink } from "./__styles__/LoginPage";
import { useHistory } from "react-router-dom";
import { buildLink } from "common/routing";

export const ProvideCredentials = ({
  dispatch,
  error,
  loginMutation,
  onSuccess,
  passwordResetPath,
  title,
}: {
  dispatch: React.Dispatch<MFAFlowAction>;
  error: Maybe<{ message: string; code?: UserLoginErrorCode }>;
  loginMutation: LoginPageProps["loginMutation"];
  onSuccess: () => void;
  passwordResetPath?: string;
  title: string;
}) => {
  const { addErrorToast } = useStatusToasts();
  const { register, handleSubmit, watch } = useFormContext();
  const history = useHistory();

  const [email, password] = watch(["email", "password"]);
  const disabled = !email || !password;

  const [login] = useMutation<
    AdminLoginMutation | UserLoginMutation,
    AdminLoginMutationVariables & UserLoginMutationVariables
  >(loginMutation, {
    onCompleted: data => {
      if (data.login.ok) {
        onSuccess();
      } else if (
        data.login.error?.code === UserLoginErrorCode.PASSWORD_RESET_REQUIRED
      ) {
        history.push(buildLink("passwordReset", {}, { resetRequired: "true" }));
      } else {
        dispatch({ type: "login", data: data.login });
      }
    },
    onError: () => {
      addErrorToast(
        `There was an issue logging in. Please try again. If the problem persists, please email us at support@withforerunner.com`
      );
    },
  });

  const handleLogin = async (loginData: LoginFormStructure) => {
    await login({
      variables: { email: loginData.email!, password: loginData.password! },
    });
  };

  return (
    <form onSubmit={handleSubmit(handleLogin)}>
      <Title>{title}</Title>
      {error && (
        <ErrorMessage error={error} passwordResetPath={passwordResetPath!} />
      )}
      <div>
        <Row>
          <Text label="Email" {...register("email")} />
        </Row>
        <Row>
          <Text label="Password" {...register("password")} type="password" />
        </Row>
        {passwordResetPath && (
          <PasswordResetLink to={passwordResetPath}>
            Forgot your password?
          </PasswordResetLink>
        )}
      </div>
      <ButtonRow>
        <Button
          onClick={handleSubmit(handleLogin)}
          size="medium"
          styleVariant="primary"
          disabled={disabled}
        >
          Log in
        </Button>
      </ButtonRow>
    </form>
  );
};
