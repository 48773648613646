import React, { ComponentProps, ReactNode } from "react";
import MapGL, { MapController, LinearInterpolator } from "react-map-gl";
import * as d3 from "d3-ease";
import * as Sentry from "@sentry/browser";

import { getLoginPageURL } from "../../utils/session";

class CustomMapController extends MapController {
  setOptions(options: any) {
    try {
      super.setOptions(options);
    } catch (error) {
      // for reasons beyond my meager powers of
      // comprehension, sometimes calling `setOptions`
      // will result in some weird exceptions like so:
      // https://sentry.io/organizations/forerunner/issues/2378812990/?project=1518444&referrer=slack
      // it is unclear how or why these happen, but they occur with
      // some frequency and so I'd like to make sure it *doesn't* entirely
      // break the UI. It *might* break interactions with the map, but
      // I'd like to figure that out.
      Sentry.withScope(scope => {
        scope.setExtras({ options });
        Sentry.captureException(error);
      });
    }
  }

  _onWheel(event: Event) {
    if ((event.target as Element).className !== "overlays") return;

    // @ts-ignore
    super._onWheel(event);
  }

  _onPan(event: Event) {
    if ((event.target as Element).className !== "overlays") return;

    // @ts-ignore
    super._onPan(event);
  }
}

export type ViewportConfig = {
  zoom: number;
  latitude: number;
  longitude: number;
  transitionDuration?: number;
  transitionEasing?: (v: number) => number;
  transitionInterpolator?: LinearInterpolator;
};

export type Cursor = "grab" | "crosshair" | "pointer" | "unset";

type Props = {
  setViewport: (config: any) => void;
  children: ReactNode;
  cursor?: Cursor;
  baseMapStyle: string;
} & ComponentProps<typeof MapGL>;

const SimpleMap = React.forwardRef((props: Props, ref: any) => {
  const controller = new CustomMapController();

  return (
    <MapGL
      getCursor={() => props.cursor ?? "unset"}
      ref={ref}
      {...props}
      mapboxApiAccessToken={window.env.MAPBOX_ACCESS_TOKEN}
      attributionControl={false}
      reuseMaps={false}
      controller={controller}
      mapStyle={props.baseMapStyle}
      onError={(e: any) => {
        if (e.error.status === 401) {
          window.location.href = getLoginPageURL(location);
        }
      }}
      onViewportChange={(viewport: ViewportConfig, trigger: any) => {
        const { zoom, latitude, longitude } = viewport;
        const config = { zoom, latitude, longitude } as ViewportConfig;
        if (!trigger) {
          config.transitionDuration = 400;
          config.transitionEasing = d3.easeQuadOut;
          config.transitionInterpolator = new LinearInterpolator();
        }
        try {
          props.setViewport(config);
        } catch (error) {
          Sentry.withScope(scope => {
            scope.setExtras({ config });
            Sentry.captureException(error);
          });
        }
      }}
    />
  );
});

export default SimpleMap;
