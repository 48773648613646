import React from "react";
import { useHistory } from "react-router";
import { getPath } from "common/routing";
import { Button } from "../Common/Button";
import {
  Container,
  Footer,
  Title,
  UserInviteProblemText,
  Wrapper,
} from "./__styles__/UserInvite";
import { FlexColumn } from "../Common/__styles__/Layout";

export const UserInviteAccepted = () => {
  const history = useHistory();
  return (
    <Wrapper>
      <Container>
        <Title>This invitation has been used</Title>
        <UserInviteProblemText>
          Unfortunately, this account invitation has already been accepted. If
          you have a Forerunner account, sign in using the link below. If you're
          looking to create an account or need help, please reach out to us at{" "}
          <a href="mailto:support@withforerunner.com">
            support@withforerunner.com
          </a>
        </UserInviteProblemText>
        <Footer>
          <FlexColumn style={{ alignItems: "center" }}>
            <Button
              styleVariant="primary"
              size="medium"
              onClick={() => history.push(getPath("login"))}
            >
              Log in
            </Button>
          </FlexColumn>
        </Footer>
      </Container>
    </Wrapper>
  );
};
