import React from "react";
import { UserLoginErrorCode } from "../../generated/graphql";
import { BannerMessage } from "./__styles__/LoginBannerMessage";
import { PasswordResetLink } from "./__styles__/LoginPage";

export const ErrorMessage = ({
  error,
  passwordResetPath,
}: {
  error: { message: string; code?: UserLoginErrorCode };
  passwordResetPath?: string;
}) => {
  switch (error.code) {
    case UserLoginErrorCode.PASSWORD_EXPIRED: {
      return (
        <BannerMessage>
          Your password has expired. Please{" "}
          <PasswordResetLink to={passwordResetPath!}>
            reset your password
          </PasswordResetLink>{" "}
          or contact support@withforerunner.com for assistance.
        </BannerMessage>
      );
    }
    default: {
      return <BannerMessage>{error.message}</BannerMessage>;
    }
  }
};
