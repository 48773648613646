import React, { useContext } from "react";
import { useStatusToasts } from "../../../hooks/useStatusToasts";

import { Box } from "../../Common/__styles__/Layout";
import Divider from "../../Common/Divider";
import {
  useGetAccountContactInformationQuery,
  useUpdateAccountContactInformationMutation,
} from "../../../generated/graphql";
import { AuthContext } from "../../Authorization/AuthContext";
import { InsufficientPermissionsBanner } from "./InsufficientPermissionsBanner";
import { RESOURCE_NAME } from "common/authorization";
import { ContactInformationForm } from "./ContactInformationForm";

import { Header, HeaderTitle, Footer } from "../__styles__/Content";

export const PublicWebsite = () => {
  const { authorized } = useContext(AuthContext);

  const { addSuccessToast, addErrorToast } = useStatusToasts();

  const { data, loading, error } = useGetAccountContactInformationQuery();

  const [updateAccountContactInformation] =
    useUpdateAccountContactInformationMutation({
      onCompleted: () => {
        addSuccessToast(
          "Your public website settings were successfully updated."
        );
      },
      onError: () => {
        addErrorToast(
          "There was a problem updating your public website settings. Please try again or contact support@withforerunner.com"
        );
      },
    });

  const contactInformation = data?.account?.contactInformation;

  if (error || (!loading && !contactInformation)) {
    throw new Error("Error loading public website information");
  }

  const canUpdateAccountInformation = authorized({
    resource: RESOURCE_NAME.ACCOUNT,
    permission: "update",
  });

  return (
    <>
      {!canUpdateAccountInformation && <InsufficientPermissionsBanner />}
      <Box>
        <Header>
          <HeaderTitle>Public Website settings</HeaderTitle>
        </Header>
        <Divider />
        {loading ? (
          "loading..."
        ) : (
          <ContactInformationForm
            canUpdateAccountInformation={canUpdateAccountInformation}
            contactInformation={contactInformation!}
            onUpdate={updateAccountContactInformation}
          />
        )}
        <Footer />
      </Box>
    </>
  );
};

export default PublicWebsite;
