import React from "react";
import { Control, FieldPath, FieldValues, Validate } from "react-hook-form";
import Divider from "../../../Common/Divider";
import { Label } from "../../../Inputs";
import { RadioProps } from "../../../Inputs/Radio";
import { Radio, Datepicker } from "../../../Inputs/react-hook-form";
import { HelpText } from "./__styles__/upsertDfeRuleModal";

type RevalidateEcsFormSectionProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = {
  name: TName;
  control: Control<TFieldValues, TName>;
  ecRevalidationOptions: RadioProps["options"];
  disabled: boolean;
};

export const RevalidateEcsFormSection = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  ecRevalidationOptions,
  disabled,
}: RevalidateEcsFormSectionProps<TFieldValues, TName>) => {
  return (
    <>
      <Divider margins="14px" />

      <div style={{ marginBottom: "12px" }}>
        <Label
          text="Do DFE-related EC errors and property warnings need to be re-computed if property DFE values are modified by this rule?"
          compact={true}
          htmlFor="revalidateEcs"
        />
        <HelpText style={{ margin: "0" }}>
          If errors are recomputed, all existing ignored errors will be removed.
        </HelpText>
        <Radio
          control={control}
          rules={{
            required: "Please indicate whether revalidation is required",
          }}
          name={name}
          options={ecRevalidationOptions}
          required={true}
          disabled={disabled}
        />
      </div>
    </>
  );
};

type RevalidateEcsDatepickerProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = {
  name: TName;
  control: Control<TFieldValues, TName>;
  validate: Validate<any, FieldValues>;
  trigger: () => Promise<boolean>;
};

export const RevalidateEcsDatepicker = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  validate,
  trigger,
}: RevalidateEcsDatepickerProps<TFieldValues, TName>) => {
  return (
    <div style={{ margin: "0 0 8px 48px" }}>
      <Datepicker<TFieldValues, TName>
        control={control}
        name={name}
        rules={{
          validate,
        }}
        onChange={_ => trigger}
        label={"Start date"}
        compactLabel={true}
        required={true}
      />
    </div>
  );
};
