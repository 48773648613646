import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDropzone as useReactDropzone } from "react-dropzone";
import {
  GetAccountDocumentTemplateQuery,
  useGetAccountDocumentTemplateQuery,
} from "../../../../generated/graphql";

import UploadStep from "./UploadStep";
import MappingStepPlaceholder from "./MappingStepPlaceholder";
import MappingUpdateStep from "./MappingUpdateStep";
import MappingCreateStep from "./MappingCreateStep";
import { PDFFieldMapping } from "common/utils/documentTemplates";

type DocumentTemplateUpload = {
  blob: any;
  id?: string;
  originalFilename: string;
};

export type PDFFieldToForerunnerKey = {
  label: string;
  value: Maybe<string>;
};

export interface CreateDocumentTemplateForm {
  name: string;
  associatedDocumentTypeId: string;
  documentTemplateUpload: DocumentTemplateUpload;
  s3Key: string;
  mapping: Array<
    NullableFields<PDFFieldMapping, "forerunnerFieldType" | "value">
  >;
}

export enum FORM_STAGES {
  LOADING,
  UPLOAD,
  MAPPING,
}

const CreateOrUpdateForm = ({
  useDropzone = undefined,
}: {
  useDropzone?: (
    args: Pick<NonNullable<Parameters<typeof useReactDropzone>[0]>, "onDrop">
  ) => ReturnType<typeof useReactDropzone>;
}) => {
  const url = new URL(window.location.href);
  const documentTemplateId = url.searchParams.get("id");

  const formMethods = useForm<CreateDocumentTemplateForm>({
    defaultValues: { mapping: [] },
    mode: "onChange",
  });
  const [formStage, setFormStage] = useState<FORM_STAGES>(FORM_STAGES.LOADING);
  const [documentTemplate, setDocumentTemplate] =
    useState<GetAccountDocumentTemplateQuery["getAccountDocumentTemplate"]>();

  const { loading } = useGetAccountDocumentTemplateQuery({
    skip: !documentTemplateId,
    variables: {
      id: documentTemplateId!,
    },
    onCompleted: data => {
      formMethods.setValue(
        "mapping",
        data.getAccountDocumentTemplate.fieldMappings
      );
      setDocumentTemplate(data.getAccountDocumentTemplate);
    },
  });

  useEffect(() => {
    if (documentTemplateId) {
      setFormStage(FORM_STAGES.MAPPING);
    } else {
      setFormStage(FORM_STAGES.UPLOAD);
    }
  }, [loading]);

  const mappingUpdateStepLoading =
    formStage === FORM_STAGES.MAPPING &&
    documentTemplateId &&
    !documentTemplate;

  return (
    <FormProvider {...formMethods}>
      {(formStage === FORM_STAGES.LOADING ||
        loading ||
        mappingUpdateStepLoading) && <MappingStepPlaceholder />}
      {formStage === FORM_STAGES.UPLOAD && (
        <UploadStep useDropzone={useDropzone} setFormStage={setFormStage} />
      )}
      {formStage === FORM_STAGES.MAPPING &&
        (documentTemplate ? (
          <MappingUpdateStep documentTemplate={documentTemplate} />
        ) : (
          <MappingCreateStep setFormStage={setFormStage} />
        ))}
    </FormProvider>
  );
};

export default CreateOrUpdateForm;
