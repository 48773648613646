import React from "react";
import { Button } from "../Common/Button";
import {
  ButtonSection,
  Container,
  ContentSection,
  HeaderSection,
  PrimaryButtons,
} from "../Common/__styles__/Modal";
import { Icon, Subtitle, Text } from "./__styles__/ExportSuccess";

export default ({ closeModal }: { closeModal: () => void }) => (
  <Container width={"narrow"}>
    <HeaderSection>
      <h1>Export Logs</h1>
    </HeaderSection>
    <ContentSection>
      <Icon />
      <Subtitle>Success!</Subtitle>
      <Text>
        Your export is being prepared. Expect it in your email inbox within 5 -
        10 minutes.
      </Text>
    </ContentSection>
    <ButtonSection>
      <PrimaryButtons>
        <Button styleVariant="primary" size="medium" onClick={closeModal}>
          Got it
        </Button>
      </PrimaryButtons>
    </ButtonSection>
  </Container>
);
